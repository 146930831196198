





















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import axios from 'axios'

@Component({
  components: {}
})

export default class UserShowDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'users', show: 'userShow', edit: 'userEdit' },
    routeParamKey: 'userId',
    functionNames: { setCurrent: 'setCurrentUser', save: 'saveUser' },
    getterNames: { current: 'currentUser', loading: 'loadingUser', errors: 'userErrors' }
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
