






















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist }
})

export default class ProjectShowDocumentsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  directories:{ name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean }[] = [
    {
      name: 'Verkoopdocumentatie',
      position: 'verkoopdocumentatie',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Contract',
      position: 'contract',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Gebruikershandleiding',
      position: 'gebruikershandleiding',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Revisiedocumenten',
      position: 'revisiedocumenten',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Opleverdossier',
      position: 'opleverdossier',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Kwaliteitdossier',
      position: 'kwaliteitdossier',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Servicemeldingen',
      position: 'servicemeldingen',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Overig',
      position: 'overig',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    }
  ]

  activePanel:string = new SecureLS({ isCompression: false }).get('projectShowDocumentsPanel') || null

  setActivePanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('projectShowDocumentsPanel', panel)
    this.activePanel = panel
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
