























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import Room from '@/types/room'

@Component({
  components: {}
})

export default class BuildingEditNewsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Titel',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Gepubliceerd / Gepland op',
      key: 'publish_at',
      dataIndex: 'publish_at',
      scopedSlots: { customRender: 'publish_at' }
    },
    {
      title: 'Status',
      key: 'status_label',
      dataIndex: 'status_label'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  navigateTo (id:number) : void {
    this.$router.push({ name: 'newsShow', params: { newsItemId: id.toString() } })
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          const route = this.$router.resolve({ name: 'newsShow', params: { newsItemId: rowData.id.toString() } })
          window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
        }
      }
    }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
