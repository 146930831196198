







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import NotificationCenter from '@/components/base/NotificationCenter.vue'
import SearchWidget from '@/components/dashboard/SearchWidget.vue'
import axios from 'axios'

@Component({
  components: { SearchWidget, NotificationCenter }
})

export default class HeaderBar extends Vue {
  @Prop({ required: false, default: false, type: Boolean }) subscribed!:boolean
  @Prop({ required: true }) model!:string
  @Prop({ required: true }) id!:number
  @Prop({ required: false, default: false, type: Boolean }) border!:boolean

  @Watch('subscribed')
  onSubscribedChanged ():void {
    this.isSubscribed = this.subscribed
  }

  mounted (): void {
    this.isSubscribed = this.subscribed
  }

  isSubscribed = false

  onClick ():void {
    axios.get(`${process.env.VUE_APP_API_URL}/subscriptions/${this.model}/${this.id}`)
    this.isSubscribed = !this.isSubscribed
  }
}

