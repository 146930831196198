










































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Company from '@/types/company'
import User from '@/types/user'
import Filter from '@/types/filter'
import Column from '@/types/column'
import TableSettings from '@/types/tableSettings'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Pagination from '@/types/pagination'
import axios from 'axios'
import PageHeader from '@/components/base/PageHeader.vue'

@Component({
  components: { PageHeader, ActiveFilters }
})

export default class Companies extends Vue {
  settings = {
    routeNames: { create: 'companyCreate', show: 'companyShow', edit: 'companyEdit' },
    routeParamKey: 'companyId',
    permissionNames: { show: 'companies.view', edit: 'companies.edit', delete: 'companies.delete' },
    functionNames: { getList: 'getCompanyList' },
    getterNames: { list: 'companyList', loading: 'loadingCompany' },
    localStorageName: 'companyTableSettings'
  }

  columns:Column[] = [
    {
      title: 'Referentie',
      key: 'reference',
      dataIndex: 'reference',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Adres',
      key: 'address',
      dataIndex: 'address'
    },
    {
      title: 'Postcode',
      key: 'zipcode',
      dataIndex: 'zipcode'
    },
    {
      title: 'Plaats',
      key: 'city',
      dataIndex: 'city',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actief',
      key: 'active',
      filters: [],
      sorter: true,
      align: 'center',
      width: 100,
      sortDirections: ['ascend', 'descend'],
      scopedSlots: { customRender: 'active' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  tableSettings:TableSettings = new SecureLS({ isCompression: false }).get(this.settings.localStorageName) || {
    pagination: { current: 1, pageSize: 25 },
    showSizeChanger: true,
    pageSizeOptions: ['25', '50', '100'],
    activeFilters: null,
    sort: null,
    search: null
  }

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    this.onTableSettingsChange()
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  removedFilter (activeFilters:{[key: string]: number[]|string[]}) : void {
    // Set updated filters.
    this.tableSettings.activeFilters = activeFilters

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.edit, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.show, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get itemList () : { data: Company[], meta: { per_page:string, total:number } } {
    return this.$store.getters[this.settings.getterNames.list]
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: parseInt(this.itemList.meta.per_page),
      total: this.itemList.meta.total,
      showTotal: (total, range) => `Bedrijf ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].list
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set(this.settings.localStorageName, this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: this.settings.localStorageName,
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
