











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: { }
})

export default class PageHeader extends Vue {
  @Prop() headerTitle!:string
}
