


































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import OldWoononOrder from '@/types/oldWoonon/oldWoononOrder'

@Component({
  components: {}
})

export default class OldWoononOrderShowDetailsCard extends Vue {
  settings = {
    routeNames: { list: 'oldWoononOrders', show: 'oldWoononOrderShow', edit: 'oldWoononOrderEdit' },
    routeParamKey: 'oldWoononOrderId',
    functionNames: { setCurrent: 'setCurrentOldWoononOrder', save: 'saveOldWoononOrder' },
    getterNames: { current: 'currentOldWoononOrder', loading: 'loadingOldWoononOrder' }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : OldWoononOrder {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
