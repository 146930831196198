









































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Tenant from '@/types/tenant'
import TenantEditDetailsTab from '@/views/tenant/tabs/edit/TenantEditDetailsTab.vue'
import TenantEditContactsTab from '@/views/tenant/tabs/edit/TenantEditContactsTab.vue'

@Component({
  components: { TenantEditContactsTab, TenantEditDetailsTab }
})

export default class TenantCreate extends Vue {
  settings = {
    routeNames: { list: 'tenants', show: 'tenantShow', edit: 'tenantEdit' },
    routeParamKey: 'tenantId',
    functionNames: { setCurrent: 'setCurrentTenant', clearCurrent: 'clearCurrentTenant', save: 'saveTenant' },
    getterNames: { current: 'currentTenant', loading: 'loadingTenant' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('tenantCreateActiveTab') || 'details'

  tenants:Tenant[] = []

  userIndexToCopy = null
  showModal = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  showCopyModal () : void {
    this.showModal = true
  }

  hideCopyModal () : void {
    this.showModal = false
    this.userIndexToCopy = null
  }

  copyUserDetails () : void {
    this.$store.dispatch('copyUserDetailsToTenant', this.userIndexToCopy)
    this.hideCopyModal()
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('tenantCreateActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Tenant {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
