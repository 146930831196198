import { render, staticRenderFns } from "./ServiceStatusChart.vue?vue&type=template&id=d9827940&scoped=true&"
import script from "./ServiceStatusChart.vue?vue&type=script&lang=ts&"
export * from "./ServiceStatusChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9827940",
  null
  
)

export default component.exports