












































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import ConstructionWorker from '@/types/constructionWorker'
import User from '@/types/user'

@Component({
  components: {}
})

export default class ConstructionWorkerEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'constructionWorkers', show: 'constructionWorkerShow', edit: 'constructionWorkerEdit' },
    routeParamKey: 'constructionWorkerId',
    functionNames: { setCurrent: 'setCurrentConstructionWorker', save: 'saveConstructionWorker' },
    getterNames: { current: 'currentConstructionWorker', loading: 'loadingConstructionWorker', errors: 'constructionWorkerErrors' }
  }

  get currentItem () : ConstructionWorker {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
