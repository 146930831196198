import MediaItem from '@/types/mediaItem'

interface Tag {
  position: { x:number, y:number }
  surveyId?:number|null
  surveyAnswerId?:number|null
  issueId:number|null
}

export default class Media {
  public name!: string
  public type!: string
  public url!: string
  public image!: File|string
  constructor (name:string, type:string, url:string, image:File|string) {
    this.name = name
    this.type = type
    this.url = url
    this.image = image
  }
}

export class MediaFile {
  public name!:string
  public extension!:string
  public important!:boolean
  public file!:File
  constructor (file:File, name: string, ext: string, important = false) {
    this.name = name
    this.extension = ext
    this.important = important
    this.file = file
  }
}

export class Base64MediaFile implements MediaItem {
  public name!:string
  public originalName!:string
  public type!:string
  public size!:string
  public url!:string
  public order!: number | null
  public tags!:Tag[]
  public created_at!:string
  public extension!: string
  constructor (file:File, name?:string, type?:string) {
    this.name = name ?? file.name
    this.originalName = file.name
    this.type = type ?? file.type
    this.size = Base64MediaFile.bytesToSize(file.size)
    this.tags = []
    this.created_at = new Date().toISOString()
  }

  public base64!:string
  public protected!: boolean

  private static bytesToSize (bytes:number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  public file_name!: string
  public id!: number
  public concept!: boolean
  public important_media!: {
    id: number;
    media_id: number;
    customer_id: number;
    read_at: string | null;
    customer: { id: number; name: string }
  }[]

  public important_media_details!: { important: boolean; read_by: string[]; unread_by: string[] }
}

export function getBase64Async (file: File):Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
