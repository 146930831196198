import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import ProjectWebsiteModel from '@/models/ProjectWebsiteModel'
import { notification } from 'ant-design-vue'
import router from '@/router'

Vue.use(Vuex)

export const projectWebsiteStore: Module<any, any> = {
  state: {
    projectWebsiteList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentProjectWebsite: {
      project: {
        name: ''
      },
      images: {
        logo: [],
        favicon: []
      },
      docs: {
        terms: [],
        privacy: []
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentProjectWebsite (state, { currentProjectWebsite }) {
      state.currentProjectWebsite = currentProjectWebsite
    },
    clearCurrentProjectWebsite (state) {
      state.currentProjectWebsite = {
        project: {
          name: ''
        },
        images: {
          logo: [],
          favicon: []
        },
        docs: {
          terms: [],
          privacy: []
        }
      }
    },
    setProjectWebsiteList (state, { projectWebsiteList }) {
      state.projectWebsiteList = projectWebsiteList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getProjectWebsiteList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let projectWebsiteModel = new ProjectWebsiteModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            projectWebsiteModel = projectWebsiteModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        projectWebsiteModel = projectWebsiteModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        projectWebsiteModel = projectWebsiteModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        projectWebsiteModel = projectWebsiteModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      projectWebsiteModel.get().then((projectWebsiteList) => {
        commit('setProjectWebsiteList', { projectWebsiteList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de projectwebsites!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentProjectWebsite ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        ProjectWebsiteModel.$find(id).then((currentProjectWebsite) => {
          commit('setCurrentProjectWebsite', { currentProjectWebsite })
          resolve(currentProjectWebsite)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze projectwebsite!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentProjectWebsite ({ commit }) {
      commit('clearCurrentProjectWebsite')
    },
    saveProjectWebsite ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const projectWebsiteModel = new ProjectWebsiteModel(data)

      // Save element.
      projectWebsiteModel.save().then((currentProjectWebsite) => {
        commit('setErrors', {})
        commit('setCurrentProjectWebsite', { currentProjectWebsite })
        if (data.id) {
          notification.success({ message: 'Projectwebsite opgeslagen.', description: 'De projectwebsite is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Projectwebsite toegevoegd.', description: 'De projectwebsite is succesvol toegevoegd.', duration: 3 })
          if (currentProjectWebsite.id) {
            router.push({ name: 'projectWebsiteEdit', params: { projectWebsiteId: currentProjectWebsite.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van de projectwebsite!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van de projectwebsite!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    projectWebsiteList: state => state.projectWebsiteList,
    currentProjectWebsite: state => state.currentProjectWebsite,
    loadingProjectWebsite: state => state.loading,
    projectWebsiteErrors: state => state.errors
  }
}
