














































import { Vue, Component } from 'vue-property-decorator'
import Building from '@/types/building'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class ProjectGoodNewsLetter extends Vue {
  loading = false
  sending = false
  showGoodNewsModal = false
  buildings:Building[] = []
  goodNewsLetterData = {
    buildingIds: [],
    onBehalfOf: null,
    notary: 'Jager Stuijt & Einarson Notarissen',
    notaryCity: 'Heemstede',
    broker: 'Hofzicht Makelaars'
  }

  toggleGoodNewsModal () : void {
    this.showGoodNewsModal = !this.showGoodNewsModal

    if (this.showGoodNewsModal) {
      this.getBuildings()
    } else {
      this.buildings = []
      this.goodNewsLetterData = {
        buildingIds: [],
        onBehalfOf: null,
        notary: 'Jager Stuijt & Einarson Notarissen',
        notaryCity: 'Heemstede',
        broker: 'Hofzicht Makelaars'
      }
    }
  }

  getBuildings () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project.id]=' + this.$route.params.projectId + '&filter[availableForGoodNews]=1&limit=1000').then((r) => {
      this.buildings = r.data.data
      this.goodNewsLetterData.buildingIds = r.data.data.map((b:Building) => {
        return b.id
      })
    }).finally(() => {
      this.loading = false
    })
  }

  sendGoodNewsLetters () : void {
    this.sending = true
    axios.post(process.env.VUE_APP_API_URL + '/buildings/goodNewsLetters/generate', this.goodNewsLetterData).then(() => {
      this.showGoodNewsModal = false
      notification.success({ message: 'Goednieuwsbrieven verstuurd.', description: 'De goednieuwsbrieven zijn succesvol verstuurd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Goednieuwsbrieven NIET verstuurd.', description: '1 of meerdere goednieuwsbrieven zijn niet verstuurd. Probeer het nogmaals.', duration: 0 })
    }).finally(() => {
      this.sending = false
    })
  }

  get minRequiredFieldsCompleted () : boolean {
    return !!(this.goodNewsLetterData.buildingIds.length && this.goodNewsLetterData.notary.length >= 3 && this.goodNewsLetterData.notaryCity.length >= 3 && this.goodNewsLetterData.broker.length >= 3)
  }
}
