









import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import UnityViewer from '@/components/media/UnityViewer.vue'
import axios from 'axios'

@Component({
  components: { UnityViewer }
})

export default class BuildingEditUnityViewerTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Klant',
      key: 'customer.reference',
      dataIndex: 'customer.reference'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  buildingConfigs = []
  buildingHasModel = false

  checkBuildingHasModel (): void {
    axios.get(`${process.env.VUE_APP_API_URL}/buildings/${this.building.id}/info`)
      .then(res => {
        this.buildingHasModel = res.data.has_model
      })
  }

  mounted (): void {
    this.checkBuildingHasModel()
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get building () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
