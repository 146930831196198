











import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Subelement from '@/types/subelement'
import Column from '@/types/column'
import User from '@/types/user'

@Component({
  components: {}
})

export default class SubelementShowElementsTab extends Vue {
  settings = {
    routeNames: { list: 'subelements', show: 'subelementShow', edit: 'subelementEdit' },
    routeParamKey: 'subelementId',
    functionNames: { setCurrent: 'setCurrentSubelement', save: 'saveSubelement' },
    getterNames: { current: 'currentSubelement', loading: 'loadingSubelement', errors: 'subelementErrors' }
  }

  columns:Column[] = [
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Commerciële naam',
      key: 'name_sales',
      dataIndex: 'name_sales',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    }
  ]

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('elements.view')) {
            const route = this.$router.resolve({ name: 'elementShow', params: { elementId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Subelement {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
