










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})

export default class AfasField extends Vue {
  @Prop() text!:string|number|null
}
