






































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import Option from '@/types/option'
import { notification } from 'ant-design-vue'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class BuildingShowOptionSettingsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      scopedSlots: { customRender: 'external_ref' }
    },
    {
      title: 'Optie',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Standaard prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: 'Project prijs',
      key: 'project_options.price',
      scopedSlots: { customRender: 'project_price' }
    },
    {
      title: 'Bouwnummer prijs',
      key: 'building_options.price',
      scopedSlots: { customRender: 'building_price' }
    },
    {
      title: 'BTW',
      key: 'vat_rate',
      scopedSlots: { customRender: 'vat_rate' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  modalVisible = false
  buildingOptions = {
    modular: [],
    carcass: [],
    completion2: [],
    completion3: []
  }

  defaultOptionValues:{ [key: string]: string|number|null } = {
    building_id: null,
    subelement_id: null,
    subelement_position: null,
    option_id: null,
    external_ref: null,
    name: null,
    base_price: null,
    base_purchase_price: null,
    project_price: null,
    project_purchase_price: null,
    building_price: null,
    building_purchase_price: null,
    vat_rate: null,
    building_text_sales: null,
    building_default: 0,
    phase: 0
  }

  currentOption = this.defaultOptionValues

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingLayouts()
    }
  }

  getBuildingLayouts () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options').then((res) => {
      this.buildingOptions = res.data
    }).finally(() => {
      this.loading = false
    })
  }

  showModal (item:Option, subelement:{id:number, position:number}|undefined) : void {
    this.modalVisible = true
    this.currentOption = {
      building_id: this.currentItem.id,
      subelement_id: subelement ? subelement.id : null,
      subelement_position: subelement ? subelement.position : null,
      option_id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      base_price: item.price,
      base_purchase_price: item.purchase_price,
      project_price: item.project_options && item.project_options.price !== null ? item.project_options.price : item.price,
      project_purchase_price: item.project_options && item.project_options.purchase_price !== null ? item.project_options.purchase_price : item.purchase_price,
      building_price: item.building_options && item.building_options.price !== null ? item.building_options.price : (item.project_options && item.project_options.price !== null ? item.project_options.price : item.price),
      building_purchase_price: item.building_options && item.building_options.purchase_price !== null ? item.building_options.purchase_price : (item.project_options && item.project_options.purchase_price !== null ? item.project_options.purchase_price : item.purchase_price),
      building_text_sales: item.building_options && item.building_options.text_sales !== null ? item.building_options.text_sales : (item.project_options && item.project_options.text_sales !== null ? item.project_options.text_sales : item.text_sales),
      building_default: item.building_options && item.building_options.default !== null ? item.building_options.default : (item.project_options && item.project_options.default !== null ? item.project_options.default : 0),
      vat_rate: item.building_options.vat_rate ?? item.project_options.vat_rate ?? item.vat_rate,
      phase: item.phase
    }
  }

  hideModal () : void {
    this.modalVisible = false
    this.currentOption = this.defaultOptionValues
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
