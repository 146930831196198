















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import SurveyForm from '@/types/surveyForm'
import FormChapter from '@/components/forms/FormChapter.vue'
import FormFooter from '@/components/forms/FormFooter.vue'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import User from '@/types/user'
import SecureLS from 'secure-ls'

@Component({
  components: { FormFooter, FormChapter }
})

export default class FormDetails extends Vue {
  @Prop() form!:SurveyForm

  @Watch('form')
  onChapterUpdate () : void {
    this.currentForm = this.form
  }

  currentForm:SurveyForm = new SurveyForm()
  roomTypes:{ id:number, name: string }[] = []
  constructionTypes:{ id:number, name: string }[] = []

  mounted () : void {
    this.currentForm = this.form
    this.getRoomTypes()
    this.getConstructionTypes()
  }

  updateForm () : void {
    this.$store.dispatch('updateForm', this.currentForm)
  }

  copyForm () : void {
    axios.post(process.env.VUE_APP_API_URL + '/surveys/forms/' + this.currentForm.id + '/copy').then((res) => {
      notification.success({ message: 'Formulier gekopieerd!', description: 'Het formulier is succesvol gekopieerd. Je kijkt hem nu.', duration: 5 })
      this.$router.push({
        name: 'editForm',
        params: { formId: res.data }
      })
    }).catch(() => {
      notification.error({ message: 'Formulier NIET gekopieerd!', description: 'Er is een onverwachte fout opgetreden. Probeer het later nogmaals.', duration: 5 })
    })
  }

  saveForm () : void {
    if (this.$route.name === 'surveyFormCreate') {
      this.$store.dispatch('addForm', this.form)
    } else {
      this.$store.dispatch('saveForm', this.form)
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  getRoomTypes () : void {
    axios.get(process.env.VUE_APP_API_URL + '/surveys/forms/roomTypes').then((res) => {
      this.roomTypes = res.data
    })
  }

  getConstructionTypes () : void {
    axios.get(process.env.VUE_APP_API_URL + '/surveys/forms/constructionTypes').then((res) => {
      this.constructionTypes = res.data
    })
  }

  filterItems (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
