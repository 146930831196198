import serviceItem from './serviceItem'

export default class Objection extends serviceItem {
  media_id!: number
  document?: {
    file_name:string,
    url:string
  }

  constructor () {
    super()
    this.title = ''
    this.description = ''
    this.status = 0
    this.comments = []
    this.media_id = 0
    this.document = { file_name: '', url: '' }
  }
}
