















































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import axios from 'axios'

@Component({
  components: {}
})

export default class UserEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'users', show: 'userShow', edit: 'userEdit' },
    routeParamKey: 'userId',
    functionNames: { setCurrent: 'setCurrentUser', save: 'saveUser' },
    getterNames: { current: 'currentUser', loading: 'loadingUser', errors: 'userErrors' }
  }

  companies:Company[] = []
  newPassword = false

  mounted () : void {
    this.getCompanies()
  }

  getCompanies () : void {
    axios.get(process.env.VUE_APP_API_URL + '/companies?page=1&limit=2500&sort=name').then((res) => {
      this.companies = res.data.data
    })
  }

  filterCompanies (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
