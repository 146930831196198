import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import axios from 'axios'
import SecureLS from 'secure-ls'
import router from '@/router'
import { notification } from 'ant-design-vue'

Vue.use(Vuex)

const apiUrl = process.env.VUE_APP_API_URL
const ls = new SecureLS({ isCompression: false })

export const loginStore: Module<any, any> = {
  state: {
    status: '',
    token: ls.get('token') || '',
    authUser: ls.get('authUser') || {}
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, { token, authUser }) {
      state.status = 'success'
      state.token = token
      state.authUser = authUser
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
      state.authUser = {}
    }
  },
  actions: {
    login ({ commit }, authUser) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: apiUrl + '/login', data: authUser, method: 'POST' })
          .then(resp => {
            const token = 'Bearer ' + resp.data.access_token
            const authUser = resp.data.user
            ls.set('token', token)
            ls.set('authUser', authUser)
            axios.defaults.headers.common.Authorization = token
            commit('auth_success', { token, authUser })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            ls.clear()
            reject(err)
          })
      })
    },
    getDetailsForO365User ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        const token = 'Bearer ' + data.token
        ls.set('token', token)
        axios.defaults.headers.common.Authorization = token
        axios({ url: apiUrl + '/users/o365', method: 'GET' })
          .then(resp => {
            const authUser = resp.data.user
            ls.set('authUser', authUser)
            commit('auth_success', { token, authUser })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            ls.clear()
            reject(err)
          })
      })
    },
    logout ({ commit }, forcedLogout) {
      commit('logout')
      axios({ url: apiUrl + '/logout', method: 'POST' })
      ls.clear()
      delete axios.defaults.headers.common.Authorization
      router.push('/')

      if (forcedLogout) {
        notification.warning({ message: 'Sessie verlopen!', description: 'Uw sessie is verlopen. Log opnieuw in.', duration: 0 })
      }
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    authUser: state => state.authUser,
    can: state => (permission: string) => {
      return state.authUser.permissions.includes(permission)
    }
  }
}
