













































import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'

@Component({
  components: {}
})

export default class Uploader extends Vue {
  @Prop({ type: Array, default: () => ['image/jpeg', 'image/png'] }) allowedMimeTypes!:string[]
  @Prop({ default: 5 }) maxFileSize!:number
  @Prop({ type: Boolean, default: true }) multiple!:boolean
  @Prop({ default: 0 }) currentCount!:number
  @Prop({ type: Boolean, default: true }) allowFileRename!:boolean
  @Prop({ type: Boolean, default: false }) canMarkAsImportant!:boolean
  @Prop({ type: Boolean, default: false }) canMarkAsConcept!:boolean

  showFileSettingsModal = false
  fileList:{ name:string|undefined, extension:string|undefined, important:boolean, concept:boolean, file:File }[] = []

  handleUpload (file: File): boolean {
    if (!this.allowedMimeTypes.includes(file.type)) {
      this.$message.error('Je kan alleen ' + this.allowedExtensions.join(', ') + '-bestanden uploaden!', 5)
      return false
    }

    if (file.size / 1024 / 1024 > this.maxFileSize) {
      this.$message.error('De maximale bestandsgrootte is ' + this.maxFileSize + 'MB!', 5)
      return false
    }

    if (this.allowFileRename || this.canMarkAsImportant || this.canMarkAsConcept) {
      this.fileList.push({
        name: file.name.substring(0, file.name.lastIndexOf('.')) || file.name, // filename without extension (.jpg)
        extension: file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length), // extension without '.' (jpg)
        important: false,
        concept: false,
        file: file
      })
      this.showFileSettingsModal = true
    } else {
      this.$emit('newFileUploaded', file)
    }

    return false
  }

  uploadFiles () : void {
    this.fileList.forEach((fileItem:{ name:string|undefined, extension:string|undefined, important:boolean, concept:boolean, file:File }) => {
      const file = new File([fileItem.file], fileItem.name + '.' + fileItem.extension, { type: fileItem.file.type })

      if (fileItem.important || fileItem.concept) {
        this.$emit('newCustomFileUploaded', { file: file, important: fileItem.important, concept: fileItem.concept })
      } else {
        this.$emit('newFileUploaded', file)
      }
    })
    this.closeFileSettingsModal()
  }

  closeFileSettingsModal () : void {
    this.fileList = []
    this.showFileSettingsModal = false
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get allowedExtensions () : string[] {
    const allowedExtensions:string[] = []

    this.allowedMimeTypes.forEach((mimeType:string) => {
      const segments = mimeType.split('/')
      if (segments[segments.length - 1] === 'svg+xml') {
        allowedExtensions.push('svg')
      } else if (segments[segments.length - 1] === 'jpeg') {
        allowedExtensions.push('jpg')
        allowedExtensions.push('jpeg')
      } else if (segments[segments.length - 1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        allowedExtensions.push('xls')
        allowedExtensions.push('xlsx')
      } else {
        allowedExtensions.push(segments[segments.length - 1])
      }
    })

    return allowedExtensions
  }
}
