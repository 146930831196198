


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import Company from '@/types/company'

@Component({
  components: {}
})

export default class ObjectionBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]

  employees:User[] = []
  bulkUpdate:Record<string, (string|number|null)> = {
    manager_id: null,
    status: null
  }

  mounted () : void {
    this.getEmployees()
  }

  getEmployees () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.display_name]=Medewerker&limit=2500').then((res) => {
      this.employees = res.data.data
    })
  }

  update () : void {
    const data:Record<string, (string|number|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null) {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/objections/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        notification.success({ message: 'Bezwaren bijgewerkt.', description: 'De bezwaren zijn succesvol opgeslagen.', duration: 3 })
        this.bulkUpdate = {
          manager_id: null,
          status: null
        }
      })
    }
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
