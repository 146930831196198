









































import { Vue, Component, Prop } from 'vue-property-decorator'
import Tenant from '@/types/tenant'
import axios from 'axios'

@Component({})
export default class ConnectTenant extends Vue {
  @Prop() tenant!:Tenant
  @Prop({ default: false, type: Boolean }) editable!:boolean

  modalVisible = false
  selectedId = 0
  selectedTenant?:Tenant
  tenants:Tenant[] = []
  timer = 0

  showModal () : void {
    this.selectedId = 0
    this.modalVisible = true
  }

  closeModal () : void {
    this.modalVisible = false
  }

  update () : void {
    this.$store.dispatch('tenantChanged', this.selectedTenant)
    this.closeModal()

    this.$emit('updated', this.selectedTenant)
  }

  reset () : void {
    this.$store.dispatch('resetTenant')
  }

  search (val:string) : void {
    if (val.length >= 3) {
      if (val.length >= 3) {
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
          axios.get(process.env.VUE_APP_API_URL + '/tenants?filter[search]=' + val).then((res) => {
            this.tenants = res.data.data
          })
        }, 500)
      }
    }
  }

  onChange (id: number) : void {
    this.selectedId = id
    this.selectedTenant = this.tenants.find((tenant:Tenant) => {
      return tenant.id === id
    })
  }
}
