

















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Buildingblock from '@/types/buildingblock'
import axios from 'axios'
import ProjectBlockDrawingsTab from '@/views/project/tabs/ProjectBlockDrawingsTab.vue'

@Component({
  components: { ProjectBlockDrawingsTab }
})

export default class ProjectShowBlocksTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  loadingBlocks = false
  buildingBlocks:Buildingblock[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingBlocks()
    }
  }

  getBuildingBlocks () : void {
    this.loadingBlocks = true

    axios.get(process.env.VUE_APP_API_URL + '/buildingblocks?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.buildingBlocks = res.data.data
    }).finally(() => {
      this.loadingBlocks = false
    })
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
