








































import { Vue, Component, Prop } from 'vue-property-decorator'
import Customer from '@/types/customer'
import axios from 'axios'

@Component({})
export default class ConnectCustomer extends Vue {
  @Prop() customer!:Customer
  @Prop({ default: false, type: Boolean }) editable!:boolean

  connectCustomerModalVisible = false
  selectedCustomerId = 0
  selectedCustomer?:Customer

  customers:Customer[] = []
  customerTimer = 0

  searchCustomer (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.customerTimer)

      this.customerTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/customers?filter[search]=' + val).then((res) => {
          this.customers = res.data.data
        })
      }, 500)
    }
  }

  showCustomerModal () : void {
    this.selectedCustomerId = 0
    this.connectCustomerModalVisible = true
  }

  closeCustomerModal () : void {
    this.connectCustomerModalVisible = false
  }

  updateCustomer () : void {
    this.$store.dispatch('customerChanged', this.selectedCustomer)
    this.closeCustomerModal()
  }

  onCustomerChange (id: number) : void {
    this.selectedCustomerId = id
    this.selectedCustomer = this.customers.find((customer:Customer) => {
      return customer.id === id
    })
  }

  resetCustomer () : void {
    this.$store.dispatch('resetCustomer')
  }
}
