





















































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ProjectWebsite from '@/types/projectWebsite'
import axios from 'axios'
import moment from 'moment'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import ProjectWebsitePartner from '@/types/projectWebsitePartner'
import { notification } from 'ant-design-vue'

@Component({
  components: { Filelist, Uploader, TextEditor }
})

export default class ProjectWebsiteEditPartnersTab extends Vue {
  settings = {
    routeNames: { list: 'projectWebsites', show: 'projectWebsiteShow', edit: 'projectWebsiteEdit' },
    routeParamKey: 'projectWebsiteId',
    functionNames: { setCurrent: 'setCurrentProjectWebsite', clearCurrent: 'clearCurrentProjectWebsite', save: 'saveProjectWebsite' },
    getterNames: { current: 'currentProjectWebsite', loading: 'loadingProjectWebsite', errors: 'projectWebsiteErrors' }
  }

  loading = false

  columns = [
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Adres',
      key: 'address',
      scopedSlots: { customRender: 'address' }
    },
    {
      title: 'Telefoon',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Website',
      key: 'url',
      dataIndex: 'url'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  partners:ProjectWebsitePartner[] = []

  showAddModal = false
  showEditModal = false

  partner:ProjectWebsitePartner|null = null

  mounted () : void {
    this.getPartners()
  }

  getPartners () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/partners').then((res) => {
      this.partners = res.data

      if (this.partner && this.partner.id) {
        this.partners.forEach((partner:ProjectWebsitePartner) => {
          if (this.partner && partner.id === this.partner.id) {
            this.partner.logo = partner.logo
            this.partner.photo = partner.photo
          }
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  toggleAddModal () : void {
    this.showAddModal = !this.showAddModal
    this.partner = {
      id: null,
      name: null,
      address: null,
      zipcode: null,
      city: null,
      phone: null,
      email: null,
      url: null,
      type: null,
      footer: false,
      position: 1,
      project_site_setting_id: this.currentItem.id
    }
  }

  toggleEditModal (partner:ProjectWebsitePartner|null) : void {
    if (partner) {
      this.showEditModal = true
      this.partner = partner
    } else {
      this.showEditModal = false
      this.partner = {
        id: null,
        name: null,
        address: null,
        zipcode: null,
        city: null,
        phone: null,
        email: null,
        url: null,
        type: null,
        footer: false,
        position: 1,
        project_site_setting_id: this.currentItem.id
      }
    }
  }

  storePartner () : void {
    axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/partners', this.partner).then((res) => {
      this.getPartners()
      this.toggleAddModal()
    })
  }

  updatePartner () : void {
    if (this.partner) {
      axios.put(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/partners/' + this.partner.id, this.partner).then(() => {
        this.getPartners()
        this.toggleEditModal(null)
        notification.success({ message: 'Partner opgeslagen.', description: 'De partner is succesvol opgeslagen.', duration: 3 })
      }).catch(() => {
        notification.error({ message: 'Partner NIET opgeslagen.', description: 'De partner is NIET opgeslagen. Probeer het later nogmaals.', duration: 0 })
      })
    }
  }

  newFileUploaded (file:File, key:string) : void {
    if (this.partner) {
      const formData = new FormData()
      formData.append('position', key)
      formData.append('file', file)

      axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/partners/' + this.partner.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
        this.getPartners()
      })
    }
  }

  deletePartner (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/partners/' + id).then(() => {
      this.getPartners()
      notification.success({ message: 'Partner verwijderd.', description: 'De partner is succesvol verwijderd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Partner NIET verwijderd.', description: 'De partner is NIET verwijderd. Probeer het later nogmaals.', duration: 0 })
    })
  }

  moment () : moment.Moment {
    return moment()
  }

  get currentItem () : ProjectWebsite {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
