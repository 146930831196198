import { Base64MediaFile } from '@/types/media'

export default class NewsItem {
  public id?:number
  public title = ''
  public body = ''
  public excerpt = ''
  public mainImage!: Base64MediaFile|null
  public extraImages!: Base64MediaFile[]
  public publish_at!:string|null
  public created_by!:number
  public status = 0
  public notify_customers!: boolean
  public project_id = 0
  public buildings: number[] = []
  constructor () {
    this.notify_customers = false
    this.mainImage = null
    this.extraImages = []
    this.publish_at = null
  }
}

export const statusClasses = {
  0: {
    id: 0,
    name: 'Concept',
    class: 'wo-blue',
    description: 'Is nog niet ingepland of gepubliceerd'
  },
  1: {
    id: 1,
    name: 'Gepubliceerd',
    class: 'wo-green',
    description: 'Bericht is gepubliceerd en te lezen op het portaal'
  },
  2: {
    id: 2,
    name: 'Ingepland',
    class: 'wo-green-ol',
    description: 'Bericht staat ingepland verzonden te worden'
  }
}

export const newStates = Object.freeze({
  Concept: 0,
  Gepubliceerd: 1,
  Ingepland: 2
})
