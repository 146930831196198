








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Project from '@/types/project'
import Building from '@/types/building'
import ProjectModel from '@/models/ProjectModel'
import { notification } from 'ant-design-vue'
import BuildingModel from '@/models/BuildingModel'

@Component({
})

export default class BuildingSelector extends Vue {
  @Prop({ required: true, default: 0 }) currentProjectId!: number
  @Prop({ required: true, default: () => [] }) currentBuildingIds!: number[]
  @Prop({ required: false }) loading!: boolean
  @Prop({ required: true }) editable!: boolean
  @Prop({ required: false, default: true }) multiple!: boolean

  projectList: Project[] = []
  buildingList: Building[] = []

  mounted () : void {
    this.getProjects()
    if (this.currentProjectId) {
      this.getBuildings()
    }
  }

  @Watch('currentProjectId')
  onProjectIdChanged () : void {
    this.getBuildings()
  }

  get selectedProjectId (): number {
    return this.currentProjectId
  }

  get selectedBuildingIds (): number[] {
    return this.currentBuildingIds || [0]
  }

  selectAllBuildings (): void {
    const buildingIds = this.buildingList.map(building => building.id)
    this.$emit('buildings', buildingIds)
  }

  clearSelectedBuildings (): void {
    this.$emit('buildings', [])
  }

  selectSaleProperties () : void {
    const buildingIds:number[] = []
    this.buildingList.forEach((b:Building) => {
      if (b.object_type === 1) {
        buildingIds.push(b.id)
      }
    })
    this.$emit('buildings', buildingIds)
  }

  selectTuvastRentalProperties () : void {
    const buildingIds:number[] = []
    this.buildingList.forEach((b:Building) => {
      if (b.object_type === 2) {
        buildingIds.push(b.id)
      }
    })
    this.$emit('buildings', buildingIds)
  }

  selectExternalRentalProperties () : void {
    const buildingIds:number[] = []
    this.buildingList.forEach((b:Building) => {
      if (b.object_type === 3) {
        buildingIds.push(b.id)
      }
    })
    this.$emit('buildings', buildingIds)
  }

  getProjects (): void {
    new ProjectModel()
      .limit(999)
      .get().then((projects:any) => {
        this.projectList = projects.data
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van projecten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      })
  }

  getBuildings (): void {
    new BuildingModel()
      .limit(999)
      .where('project_id', this.selectedProjectId)
      .get().then((buildings:any) => {
        this.buildingList = buildings.data
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van bouwnummers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      })
  }

  onProjectSelected (value: number): void {
    this.$emit('project', value)
    this.$emit('buildings', [])
  }

  onBuildingSelected (value: number[]): void {
    this.$emit('buildings', value)
  }

  get project (): Project {
    if (this.projectList.length === 0) return new Project()
    return this.projectList.filter(project => project.id === this.currentProjectId)[0]
  }

  get buildings (): Building[] {
    if (this.buildingList.length === 0) return []
    return this.buildingList.filter(building => this.currentBuildingIds.includes(building.id)) || []
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  navigateTo (path:string, id:number) : void {
    this.$router.push({ path: `/${path}/${id}` })
  }
}
