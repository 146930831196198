



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import Issue from '@/types/issue'

@Component({
  components: { }
})

export default class ServiceElementSelector extends Vue {
  @Prop({ required: false, default: false, type: Boolean }) loading!: boolean
  @Prop({ required: true }) currentIssue!: Issue

  selectedRoomId?:number = 0
  selectedElementId?:number = 0
  levelList: [number:{
    id:number,
    name:string,
    level_name:string
    level:number
    position:number
  }][] = []

  elementList: {id:number, name:string}[] = []

  @Watch('currentIssue.building_id')
  onBuildingChanged (): void {
    this.getRooms()
  }

  mounted (): void {
    this.getRooms()
  }

  async getRooms (): Promise<void> {
    if (this.currentIssue.building_id === 0 || this.currentIssue.building_id === undefined) return
    await axios.get(`${process.env.VUE_APP_API_URL}/buildings/${this.currentIssue.building_id}/rooms`)
      .then(res => {
        this.levelList = res.data
      })
  }

  async getElements (): Promise<void> {
    if (this.selectedRoomId === 0) return
    await axios.get(`${process.env.VUE_APP_API_URL}/buildings/${this.currentIssue.building_id}/rooms/${this.selectedRoomId}/elements`)
      .then(res => {
        this.elementList = res.data
      })
  }

  onRoomSelected (): void {
    this.$emit('room-selected', this.selectedRoomId)
    this.getElements()
  }

  onElementSelected (): void {
    this.$emit('element-selected', this.selectedElementId)
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
