import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import OptionModel from '@/models/OptionModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import router from '@/router'
import Subelement from '@/types/subelement'
import Option from '@/types/option'

Vue.use(Vuex)

export const optionStore: Module<any, any> = {
  state: {
    optionList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentOption: {
      external_code: null,
      external_ref: null,
      name: '',
      name_sales: '',
      vat_rate: 21,
      price: null,
      purchase_price: null,
      unit: 'piece',
      phase: 0,
      group: 10,
      excluded_from_warranty: false,
      text_sales: null,
      text_technical: null,
      text_warranty: null,
      text_maintenance: null,
      active: false,
      subelement_ids: [],
      subelements: [],
      enabled_if_option_ids: [],
      enabled_if_options: [],
      disabled_if_option_ids: [],
      disabled_if_options: [],
      selected_if_option_ids: [],
      selected_if_options: [],
      media: {
        mainImage: [],
        additionalImages: [],
        manuals: [],
        additionalDocuments: []
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentOption (state, { currentOption }) {
      state.currentOption = currentOption
    },
    clearCurrentOption (state) {
      state.currentOption = {
        external_code: null,
        external_ref: null,
        name: '',
        name_sales: '',
        vat_rate: 21,
        price: null,
        purchase_price: null,
        unit: 'piece',
        phase: 0,
        group: 10,
        excluded_from_warranty: false,
        text_sales: null,
        text_technical: null,
        text_warranty: null,
        text_maintenance: null,
        active: false,
        subelement_ids: [],
        subelements: [],
        enabled_if_option_ids: [],
        enabled_if_options: [],
        disabled_if_option_ids: [],
        disabled_if_options: [],
        selected_if_option_ids: [],
        selected_if_options: [],
        media: {
          mainImage: [],
          additionalImages: [],
          manuals: [],
          additionalDocuments: []
        }
      }
    },
    setOptionList (state, { optionList }) {
      state.optionList = optionList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    optionTextContentChanged (state, data) {
      state.currentOption[data.key] = data.value
    },
    subelementAddedToOption (state, { subelement }) {
      if (!state.currentOption.subelement_ids.includes(subelement.id)) {
        state.currentOption.subelement_ids.push(subelement.id)
        state.currentOption.subelements.push(subelement)
      }
    },
    subelementDeletedFromOption (state, { id }) {
      state.currentOption.subelement_ids.splice(state.currentOption.subelement_ids.indexOf(id), 1)

      let subelementIndex = null
      state.currentOption.subelements.forEach((subelement:Subelement, index:number) => {
        if (subelement.id === id) {
          subelementIndex = index
        }
      })

      if (subelementIndex !== null) {
        state.currentOption.subelements.splice(subelementIndex, 1)
      }
    },
    enabledIfOptionAddedToOption (state, { option }) {
      if (!state.currentOption.enabled_if_option_ids.includes(option.id)) {
        state.currentOption.enabled_if_option_ids.push(option.id)
        state.currentOption.enabled_if_options.push(option)
      }
    },
    enabledIfOptionDeletedFromOption (state, { id }) {
      state.currentOption.enabled_if_option_ids.splice(state.currentOption.enabled_if_option_ids.indexOf(id), 1)

      let optionIndex = null
      state.currentOption.enabled_if_options.forEach((option:Option, index:number) => {
        if (option.id === id) {
          optionIndex = index
        }
      })

      if (optionIndex !== null) {
        state.currentOption.enabled_if_options.splice(optionIndex, 1)
      }
    },
    disabledIfOptionAddedToOption (state, { option }) {
      if (!state.currentOption.disabled_if_option_ids.includes(option.id)) {
        state.currentOption.disabled_if_option_ids.push(option.id)
        state.currentOption.disabled_if_options.push(option)
      }
    },
    disabledIfOptionDeletedFromOption (state, { id }) {
      state.currentOption.disabled_if_option_ids.splice(state.currentOption.disabled_if_option_ids.indexOf(id), 1)

      let optionIndex = null
      state.currentOption.disabled_if_options.forEach((option:Option, index:number) => {
        if (option.id === id) {
          optionIndex = index
        }
      })

      if (optionIndex !== null) {
        state.currentOption.disabled_if_options.splice(optionIndex, 1)
      }
    },
    selectedIfOptionAddedToOption (state, { option }) {
      if (!state.currentOption.selected_if_option_ids.includes(option.id)) {
        state.currentOption.selected_if_option_ids.push(option.id)
        state.currentOption.selected_if_options.push(option)
      }
    },
    selectedIfOptionDeletedFromOption (state, { id }) {
      state.currentOption.selected_if_option_ids.splice(state.currentOption.selected_if_option_ids.indexOf(id), 1)

      let optionIndex = null
      state.currentOption.selected_if_options.forEach((option:Option, index:number) => {
        if (option.id === id) {
          optionIndex = index
        }
      })

      if (optionIndex !== null) {
        state.currentOption.selected_if_options.splice(optionIndex, 1)
      }
    }
  },
  actions: {
    getOptionList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let optionModel = new OptionModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            optionModel = optionModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        optionModel = optionModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        optionModel = optionModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        optionModel = optionModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      optionModel.get().then((optionList) => {
        commit('setOptionList', { optionList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de opties!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentOption ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        OptionModel.$find(id).then((currentOption) => {
          commit('setCurrentOption', { currentOption })
          resolve(currentOption)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze optie!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentOption ({ commit }) {
      commit('clearCurrentOption')
    },
    saveOption ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const optionModel = new OptionModel(data)

      // Save option.
      optionModel.save().then((currentOption) => {
        commit('setErrors', {})
        commit('setCurrentOption', { currentOption })
        if (data.id) {
          notification.success({ message: 'Optie opgeslagen.', description: 'De optie is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Optie toegevoegd.', description: 'De optie is succesvol toegevoegd.', duration: 3 })
          if (currentOption.id) {
            router.push({ name: 'optionEdit', params: { optionId: currentOption.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze optie!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze optie!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    optionTextContentChanged ({ commit }, data) : void {
      commit('optionTextContentChanged', data)
    },
    subelementAddedToOption ({ commit }, subelement) {
      commit('subelementAddedToOption', { subelement })
    },
    subelementDeletedFromOption ({ commit }, id) {
      commit('subelementDeletedFromOption', { id })
    },
    enabledIfOptionAddedToOption ({ commit }, option) {
      commit('enabledIfOptionAddedToOption', { option })
    },
    enabledIfOptionDeletedFromOption ({ commit }, id) {
      commit('enabledIfOptionDeletedFromOption', { id })
    },
    disabledIfOptionAddedToOption ({ commit }, option) {
      commit('disabledIfOptionAddedToOption', { option })
    },
    disabledIfOptionDeletedFromOption ({ commit }, id) {
      commit('disabledIfOptionDeletedFromOption', { id })
    },
    selectedIfOptionAddedToOption ({ commit }, option) {
      commit('selectedIfOptionAddedToOption', { option })
    },
    selectedIfOptionDeletedFromOption ({ commit }, id) {
      commit('selectedIfOptionDeletedFromOption', { id })
    }
  },
  getters: {
    optionList: state => state.optionList,
    currentOption: state => state.currentOption,
    loadingOption: state => state.loading,
    optionErrors: state => state.errors
  }
}
