
































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import SearchWidget from '@/components/dashboard/SearchWidget.vue'
import InfoHover from '@/components/dashboard/InfoHover.vue'
import PageHeader from '@/components/base/PageHeader.vue'

@Component({
  components: { PageHeader, InfoHover, SearchWidget }
})

export default class Dashboard extends Vue {
  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
