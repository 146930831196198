

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Base64MediaFile } from '@/types/media'
import axios from 'axios'
import pdf from 'vue-pdf-yzd'

@Component({
  components: { pdf }
})

export default class ServiceCommentMediaItem extends Vue {
  @Prop() file!:Base64MediaFile

  showPreview = false
  currentPage = 1
  pageCount = 1

  nextPage (): void {
    this.currentPage = this.currentPage > 1 ? this.currentPage-- : 1
  }

  previousPage (): void {
    this.currentPage = this.currentPage < this.pageCount ? this.currentPage++ : this.pageCount
  }

  get isPdfFile (): boolean {
    return this.file.type === 'application/pdf'
  }

  togglePreview () : void {
    if (this.isPdfFile) {
      this.downloadFile(this.file.id, this.file.url.split('/').pop())
    } else {
      this.showPreview = !this.showPreview
    }
  }

  downloadFile (id: number|null, name: string|undefined) : void {
    axios.get(process.env.VUE_APP_API_URL + '/media/' + id + '/download', { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        const filename = name
        link.href = window.URL.createObjectURL(blob)
        if (filename) {
          link.download = filename
          link.click()
        }
      })
  }
}
