

























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import CustomerUserCreateDetailsTab from '@/views/customerUser/tabs/create/CustomerUserCreateDetailsTab.vue'

@Component({
  components: { CustomerUserCreateDetailsTab }
})

export default class CustomerUserCreate extends Vue {
  settings = {
    routeNames: { list: 'customerUsers', show: 'customerUserShow', edit: 'customerUserEdit' },
    routeParamKey: 'customerUserId',
    permissionNames: { show: 'customers.users.view', edit: 'customers.users.edit', delete: 'customers.users.delete' },
    functionNames: { setCurrent: 'setCurrentCustomerUser', clearCurrent: 'clearCurrentCustomerUser', save: 'saveCustomerUser' },
    getterNames: { current: 'currentCustomerUser', loading: 'loadingCustomerUser' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('customerUserEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('customerUserEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
