



































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import NotificationCenter from '@/components/base/NotificationCenter.vue'
import SearchWidget from '@/components/dashboard/SearchWidget.vue'

@Component({
  components: { SearchWidget, NotificationCenter }
})

export default class HeaderBar extends Vue {
  showSearchModal = false

  toggleCollapsed () : void {
    this.$store.dispatch('toggleNavigation')
  }

  toggleSearchModal () : void {
    this.showSearchModal = !this.showSearchModal
  }

  navigateTo (a:{key:string}) : void {
    if (this.$route.name !== a.key && a.key !== 'logout') {
      this.$router.push({ name: a.key })
    }
  }

  logout () : void {
    this.$store.dispatch('logout')
  }

  get canSeeReleases () : boolean {
    return this.authUser.permissions.includes('releases.view') || this.authUser.permissions.includes('releases.edit')
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get showDropdown () : boolean {
    return this.authUser.permissions.includes('companies.view') || this.authUser.permissions.includes('users.view') || this.authUser.permissions.includes('roles.view')
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }
}
