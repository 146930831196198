

























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import ProjectEditDetailsTab from '@/views/project/tabs/edit/ProjectEditDetailsTab.vue'

@Component({
  components: { ProjectEditDetailsTab }
})

export default class ProjectCreate extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', clearCurrent: 'clearCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('projectCreateActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('projectCreateActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
