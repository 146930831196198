









































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Column from '@/types/column'
import axios from 'axios'
import ExtraOption from '@/types/extraOption'
import moment from 'moment'
import Building from '@/types/building'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class ProjectShowExtraOptionsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      dataIndex: 'external_ref',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Optie',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Sluitingsdatum',
      key: 'closing_date',
      scopedSlots: { customRender: 'closing_date' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Bouwnummer(s)',
      key: 'buildings',
      scopedSlots: { customRender: 'buildings' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loadingExtraOptions = false
  loadingBuildings = false
  showExtraOptionModal = false

  extraOptions:ExtraOption[] = []
  extraOption:{ [key: string]: string|number|string[]|number[]|moment.Moment|null } = {
    id: null,
    external_ref: null,
    name: null,
    name_sales: null,
    price: 0,
    text_sales: null,
    closing_date: null,
    building_ids: [],
    project_id: null
  }

  buildings:Building[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getExtraOptions()
    }
  }

  getExtraOptions () : void {
    this.loadingExtraOptions = true

    axios.get(process.env.VUE_APP_API_URL + '/extraOptions?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.extraOptions = res.data.data
    }).finally(() => {
      this.loadingExtraOptions = false
    })
  }

  showExtraOption (item:ExtraOption) : void {
    this.showExtraOptionModal = true
    this.getBuildings()
    this.extraOption = {
      id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      name_sales: item.name_sales,
      price: item.price,
      text_sales: item.text_sales,
      closing_date: item.closing_date ? moment(item.closing_date) : null,
      building_ids: item.building_ids,
      project_id: item.project_id
    }
  }

  hideModal () : void {
    this.showExtraOptionModal = false
    this.extraOption = {
      id: null,
      external_ref: null,
      name: null,
      name_sales: null,
      price: 0,
      text_sales: null,
      closing_date: null,
      building_ids: [],
      project_id: null
    }
  }

  getBuildings () : void {
    this.loadingBuildings = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.buildings = res.data.data
    }).finally(() => {
      this.loadingBuildings = false
    })
  }

  moment () : moment.Moment {
    return moment()
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
