


























































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Entity from '@/types/entity'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import axios from 'axios'
import UserSelector from '@/components/users/UserSelector.vue'

@Component({
  components: { UserSelector, Filelist, Uploader }
})

export default class EntityEdit extends Vue {
  settings = {
    routeNames: { list: 'entities', show: 'entityShow', edit: 'entityEdit' },
    routeParamKey: 'entityId',
    functionNames: { setCurrent: 'setCurrentEntity', clearCurrent: 'clearCurrentEntity', save: 'saveEntity' },
    getterNames: { current: 'currentEntity', loading: 'loadingEntity', errors: 'entityErrors' }
  }

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/entities/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  reloadEntity () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  updateUser (key:string, value:number) : void {
    console.log('Role changed', key, value)
    this.currentItem[key] = value
  }

  get currentItem () : Entity {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
