import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import ReleaseModel from '@/models/ReleaseModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
import SecureLS from 'secure-ls'
import Release from '@/types/release'
moment.locale('nl')

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: false })
const storeName = 'releases'
export const releaseStore: Module<any, any> = {
  state: {
    releaseList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentRelease: new Release(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {},
    inactiveReleaseColumns: ls.get(storeName + '-columns') || []
  },
  mutations: {
    setCurrentRelease (state, { currentRelease }) {
      currentRelease.when = currentRelease.when ? new Date(currentRelease.when).toLocaleString('nl-NL') : null
      currentRelease.created_at = currentRelease.created_at ? new Date(currentRelease.created_at).toLocaleString('nl-NL') : null
      state.currentRelease = currentRelease
    },
    clearCurrentRelease (state) {
      state.currentRelease = new Release()
    },
    setReleaseList (state, { releaseList }) {
      state.releaseList = releaseList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    setInactiveReleaseColumns (state, columns) {
      state.inactiveReleaseColumns = columns
    }
  },
  actions: {
    getReleaseList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let releaseModel = new ReleaseModel()
      const authUser = ls.get('authUser')

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            releaseModel = releaseModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      if (!authUser.roleNames.includes('developer') && !authUser.roleNames.includes('admin')) {
        releaseModel = releaseModel.where('reported_by_user_id', authUser.id)
      }

      // Add search if available.
      if (data.search) {
        releaseModel = releaseModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        releaseModel = releaseModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        releaseModel = releaseModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      releaseModel.get().then((releaseList) => {
        commit('setReleaseList', { releaseList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van releases!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentRelease ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        ReleaseModel.$find(id).then((currentRelease) => {
          commit('setCurrentRelease', { currentRelease })
          resolve(currentRelease)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze release!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentRelease ({ commit }) {
      commit('clearCurrentRelease')
    },
    saveRelease ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const releaseModel = new ReleaseModel(data)

      // Save release.
      releaseModel.save().then((currentRelease) => {
        commit('setErrors', {})
        commit('setCurrentRelease', { currentRelease })
        if (data.id) {
          notification.success({ message: 'Release opgeslagen.', description: 'Deze release is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Release toegevoegd.', description: 'De release is succesvol toegevoegd.', duration: 3 })
          if (currentRelease.id) {
            router.push({ name: 'releaseShow', params: { releaseId: currentRelease.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze release!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze release!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    setInactiveReleaseColumns ({ commit }, columns) {
      new SecureLS({ isCompression: false }).set(storeName + '-columns', columns)
      commit('setInactiveReleaseColumns', columns)
    }
  },
  getters: {
    releaseList: state => state.releaseList,
    currentRelease: state => state.currentRelease,
    loadingRelease: state => state.loading,
    releaseErrors: state => state.errors,
    InactiveReleaseColumns: state => state.inactiveReleaseColumns
  }
}
