import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import SubelementModel from '@/models/SubelementModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import router from '@/router'
import Element from '@/types/element'
import Option from '@/types/option'

Vue.use(Vuex)

export const subelementStore: Module<any, any> = {
  state: {
    subelementList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentSubelement: {
      reference: '',
      name: '',
      position: null,
      text_sales: null,
      text_technical: null,
      default_option_id: null,
      element_ids: [],
      elements: [],
      option_ids: [],
      options: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentSubelement (state, { currentSubelement }) {
      state.currentSubelement = currentSubelement
    },
    clearCurrentSubelement (state) {
      state.currentSubelement = {
        reference: '',
        name: '',
        position: null,
        text_sales: null,
        text_technical: null,
        default_option_id: null,
        element_ids: [],
        elements: [],
        option_ids: [],
        options: []
      }
    },
    setSubelementList (state, { subelementList }) {
      state.subelementList = subelementList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    elementAddedToSubelement (state, { element }) {
      if (!state.currentSubelement.element_ids.includes(element.id)) {
        state.currentSubelement.element_ids.push(element.id)
        state.currentSubelement.elements.push(element)
      }
    },
    elementDeletedFromSubelement (state, { id }) {
      state.currentSubelement.element_ids.splice(state.currentSubelement.element_ids.indexOf(id), 1)

      let elementIndex = null
      state.currentSubelement.elements.forEach((element:Element, index:number) => {
        if (element.id === id) {
          elementIndex = index
        }
      })

      if (elementIndex !== null) {
        state.currentSubelement.elements.splice(elementIndex, 1)
      }
    },
    optionAddedToSubelement (state, { option }) {
      if (!state.currentSubelement.option_ids.includes(option.id)) {
        state.currentSubelement.option_ids.push(option.id)
        state.currentSubelement.options.push(option)
      }
    },
    optionDeletedFromSubelement (state, { id }) {
      if (id === state.currentSubelement.default_option_id) {
        state.currentSubelement.default_option_id = null
      }

      state.currentSubelement.option_ids.splice(state.currentSubelement.option_ids.indexOf(id), 1)

      let optionIndex = null
      state.currentSubelement.options.forEach((option:Option, index:number) => {
        if (option.id === id) {
          optionIndex = index
        }
      })

      if (optionIndex !== null) {
        state.currentSubelement.options.splice(optionIndex, 1)
      }
    }
  },
  actions: {
    getSubelementList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let subelementModel = new SubelementModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            subelementModel = subelementModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        subelementModel = subelementModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        subelementModel = subelementModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        subelementModel = subelementModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      subelementModel.get().then((subelementList) => {
        commit('setSubelementList', { subelementList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de subelementen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentSubelement ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        SubelementModel.$find(id).then((currentSubelement) => {
          commit('setCurrentSubelement', { currentSubelement })
          resolve(currentSubelement)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit subelement!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentSubelement ({ commit }) {
      commit('clearCurrentSubelement')
    },
    saveSubelement ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const subelementModel = new SubelementModel(data)

      // Save element.
      subelementModel.save().then((currentSubelement) => {
        commit('setErrors', {})
        commit('setCurrentSubelement', { currentSubelement })
        if (data.id) {
          notification.success({ message: 'Subelement opgeslagen.', description: 'Het subelement is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Subelement toegevoegd.', description: 'Het subelement is succesvol toegevoegd.', duration: 3 })
          if (currentSubelement.id) {
            router.push({ name: 'subelementEdit', params: { subelementId: currentSubelement.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit subelement!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit subelement!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    elementAddedToSubelement ({ commit }, element) {
      commit('elementAddedToSubelement', { element })
    },
    elementDeletedFromSubelement ({ commit }, id) {
      commit('elementDeletedFromSubelement', { id })
    },
    optionAddedToSubelement ({ commit }, option) {
      commit('optionAddedToSubelement', { option })
    },
    optionDeletedFromSubelement ({ commit }, id) {
      commit('optionDeletedFromSubelement', { id })
    }
  },
  getters: {
    subelementList: state => state.subelementList,
    currentSubelement: state => state.currentSubelement,
    loadingSubelement: state => state.loading,
    subelementErrors: state => state.errors
  }
}
