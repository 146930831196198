





































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ProjectWebsite from '@/types/projectWebsite'
import ProjectWebsiteEditDetailsTab from '@/views/projectwebsite/tabs/ProjectWebsiteEditDetailsTab.vue'
import ProjectWebsiteEditRelationsTab from '@/views/projectwebsite/tabs/ProjectWebsiteEditRelationsTab.vue'
import ProjectWebsiteEditPagesTab from '@/views/projectwebsite/tabs/ProjectWebsiteEditPagesTab.vue'
import ProjectWebsiteEditPartnersTab from '@/views/projectwebsite/tabs/ProjectWebsiteEditPartnersTab.vue'
import ProjectWebsiteEditNewsTab from '@/views/projectwebsite/tabs/ProjectWebsiteEditNewsTab.vue'

@Component({
  components: { ProjectWebsiteEditNewsTab, ProjectWebsiteEditPartnersTab, ProjectWebsiteEditPagesTab, ProjectWebsiteEditRelationsTab, ProjectWebsiteEditDetailsTab }
})

export default class ProjectWebsiteEdit extends Vue {
  settings = {
    routeNames: { list: 'projectWebsites', show: 'projectWebsiteShow', edit: 'projectWebsiteEdit' },
    routeParamKey: 'projectWebsiteId',
    functionNames: { setCurrent: 'setCurrentProjectWebsite', clearCurrent: 'clearCurrentProjectWebsite', save: 'saveProjectWebsite' },
    getterNames: { current: 'currentProjectWebsite', loading: 'loadingProjectWebsite' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('projectWebsiteEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('projectWebsiteEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : ProjectWebsite {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
