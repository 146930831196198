import Model from './Model'

export default class EventMessageModel extends Model {
  public id: number|undefined

  resource () : string {
    return 'event-messages'
  }

  /**
   * add update function that accepts data and an id
   */
  update (id: number, data: any) : Promise<any> {
    return this.request({
      method: 'put',
      url: this.resource() + '/' + id,
      data
    })
  }
}
