















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment/moment'
import ServiceItem from '@/types/serviceItem'

@Component({
  components: { }
})

export default class ServiceObjectInfo extends Vue {
  @Prop({ required: true }) loading!: boolean
  @Prop({ required: true, default: () => new ServiceItem() }) currentItem!: ServiceItem

  columnWidth = { labels: 10, values: 14 }
  moment = moment

  formatDate (dateTimeZuluString: string|undefined): string {
    return dateTimeZuluString === undefined || dateTimeZuluString === null ? '-' : moment(dateTimeZuluString).format('DD-MM-YYYY')
  }
}
