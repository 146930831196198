


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import User from '@/types/user'

@Component({
  components: {}
})

export default class FeedbackBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]

  saving = false

  developers:User[] = []
  bulkUpdate:Record<string, (string|number|null)> = {
    status: null,
    assigned_to_user_id: null
  }

  mounted () : void {
    this.getDevelopers()
  }

  getDevelopers () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.display_name]=Developer,Admin&limit=2500').then((res) => {
      this.developers = res.data.data
    })
  }

  update () : void {
    this.saving = true
    const data:Record<string, (string|number|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null) {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/feedbacks/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        notification.success({ message: 'Feedbacks bijgewerkt.', description: 'De feedbacks zijn succesvol opgeslagen.', duration: 3 })
        this.bulkUpdate = {
          status: null,
          assigned_to_user_id: null
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
