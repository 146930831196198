import { render, staticRenderFns } from "./DeliveryEntityRow.vue?vue&type=template&id=4385c061&scoped=true&"
import script from "./DeliveryEntityRow.vue?vue&type=script&lang=ts&"
export * from "./DeliveryEntityRow.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryEntityRow.vue?vue&type=style&index=0&id=4385c061&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4385c061",
  null
  
)

export default component.exports