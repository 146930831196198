






















import { Vue, Component, Prop } from 'vue-property-decorator'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class ColumnOptions extends Vue {
  @Prop({ required: true }) columns!: Column[]
  @Prop({ required: true }) storeReference!: string

  modalVisible = false

  get inactiveColumns (): string[] {
    return this.$store.getters[this.storeReference]
  }

  get buttonTitle (): string {
    return this.inactiveColumns.length
      ? this.inactiveColumns.length + ' verborgen ' + (this.inactiveColumns.length > 1 ? 'kolommen' : 'kolom')
      : 'Tabel configuratie'
  }

  closeModal (): void {
    this.modalVisible = false
  }

  toggleColumn (e: any, column: string): void {
    const { inactiveColumns } = this
    if (!e.target.checked) {
      inactiveColumns.push(column)
    } else {
      const index = inactiveColumns.indexOf(column, 0)
      if (index > -1) {
        inactiveColumns.splice(index, 1)
      }
    }
    this.$store.dispatch('set' + this.storeReference, inactiveColumns)
  }
}
