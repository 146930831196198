




















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Tenant from '@/types/tenant'
import Column from '@/types/column'
import axios from 'axios'

@Component({
  components: {}
})

export default class TenantEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'tenants', show: 'tenantShow', edit: 'tenantEdit' },
    routeParamKey: 'tenantId',
    functionNames: { setCurrent: 'setCurrentTenant', save: 'saveTenant' },
    getterNames: { current: 'currentTenant', loading: 'loadingTenant', errors: 'tenantErrors' }
  }

  columns:Column[] = [
    {
      title: 'Naam',
      key: 'full_name',
      dataIndex: 'full_name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'E-mailadres',
      key: 'email',
      dataIndex: 'email',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Telefoon',
      key: 'phone',
      dataIndex: 'phone',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  user = null
  showAddModal = false
  searchTimer = 0
  users:User[] = []

  showAddContactModal () : void {
    this.showAddModal = true
  }

  hideAddContactModal () : void {
    this.showAddModal = false
    this.user = null
  }

  getUserDetails (id:number) : void {
    axios.get(process.env.VUE_APP_API_URL + '/users/' + id).then((res) => {
      this.user = res.data.data
    })
  }

  addContact () : void {
    this.$store.dispatch('userAddedToTenant', this.user)
    this.hideAddContactModal()
  }

  deleteContact (id:number) : void {
    this.$store.dispatch('userDeletedFromTenant', id)
  }

  searchUser (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/tenantUsers?filter[search]=' + val).then((res) => {
          this.users = res.data.data
        })
      }, 500)
    }
  }

  navigateTo (id:number, edit:boolean|null) : void {
    if (edit) {
      this.$router.push({ name: 'userEdit', params: { userId: id.toString() } })
    } else {
      this.$router.push({ name: 'userShow', params: { userId: id.toString() } })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('tenants.users.edit')) {
            const route = this.$router.resolve({ name: 'tenantUserEdit', params: { tenantUserId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('tenants.users.view')) {
            const route = this.$router.resolve({ name: 'tenantUserShow', params: { tenantUserId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Tenant {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
