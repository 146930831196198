

































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Uploader from '@/components/media/Uploader.vue'
import axios from 'axios'
import Filelist from '@/components/media/Filelist.vue'
import ConnectCustomer from '@/components/customers/ConnectCustomer.vue'
import ConnectTenant from '@/components/customers/ConnectTenant.vue'
import Project from '@/types/project'
import MediaItem from '@/types/mediaItem'

@Component({
  components: { ConnectTenant, ConnectCustomer, Filelist, Uploader }
})

export default class BuildingDocumentsTab extends Vue {
  @Prop({ type: Number }) blockId!:number
  @Prop({ type: Object }) mediaItems!:MediaItem[]
  @Prop({ type: Boolean, default: false }) editable!: boolean

  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  activeTab:string = new SecureLS({ isCompression: false }).get('buildingBlockDrawingsActiveTab') || 'floorplans'

  directories = [
    {
      name: 'Plattegronden',
      position: 'floorplans',
      subDirectories: [
        {
          name: 'Begane grond',
          position: 'floorplanLevel0'
        },
        {
          name: '1e verdieping',
          position: 'floorplanLevel1'
        },
        {
          name: '2e verdieping',
          position: 'floorplanLevel2'
        },
        {
          name: '3e verdieping',
          position: 'floorplanLevel3'
        }
      ]
    },
    {
      name: 'Gevelaanzichten',
      position: 'facades',
      subDirectories: [
        {
          name: 'Voorgevel',
          position: 'frontFacade'
        },
        {
          name: 'Achtergevel',
          position: 'rearFacade'
        },
        {
          name: 'Linker zijgevel',
          position: 'leftFacade'
        },
        {
          name: 'Rechter zijgevel',
          position: 'rightFacade'
        }
      ]
    }
  ]

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/buildingblocks/' + this.blockId + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  setActiveTab (panel:string) : void {
    new SecureLS({ isCompression: false }).set('buildingBlockDrawingsActiveTab', panel)
    this.activeTab = panel
  }

  reloadProject () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
