







































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import moment from 'moment/moment'
import ColumnOptions from '@/components/listview/ColumnOptions.vue'
import IssueBulkUpdater from '@/components/listview/IssueBulkUpdater.vue'
import SubscribeButton from '@/components/base/SubscribeButton.vue'
import TextEditor from '@/components/base/TextEditor.vue'
import { Modal, Page } from '@/views/manual/ManualIndex.vue'
import _ from 'lodash'
import axios from 'axios'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import SimpleFileList from '@/components/media/SimpleFileList.vue'
import SimplePreview from '@/components/media/SimplePreview.vue'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import { Base64MediaFile } from '@/types/media'
import UploadQueue from '@/components/media/UploadQueue.vue'
import PageHeader from '@/components/base/PageHeader.vue'

export class ContentBlock {
  page_id!: number
  position = -1
  type?: string
  content = ''
  media: Base64MediaFile|null = null
  style!:string
  constructor (pageId: number) {
    this.page_id = pageId
  }
}

@Component({
  components: { PageHeader, UploadQueue, Filelist, SimplePreview, SimpleFileList, Uploader, SimpleUploader, TextEditor, SubscribeButton, IssueBulkUpdater, ColumnOptions, ActiveFilters }
})

export default class ManualPage extends Vue {
  @Prop({ default: false }) editMode!:boolean
  @Prop({ default: false }) newPage!:boolean
  moment = moment
  ls = new SecureLS({ isCompression: false })

  get editable (): boolean {
    return this.editModeActive
  }

  editModeActive = false
  blockModal = new Modal('Block toevoegen')
  blocks: ContentBlock[] = []

  collectionId = -1
  originalData = new Page()
  page = new Page()
  saving = false

  allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
  maxFileSize = 16

  mounted (): void {
    this.collectionId = parseInt(this.$route.params.collectionId)
    if (!this.newPage) {
      this.fetchPageData()
    }

    if (this.editMode) {
      this.editModeActive = true
    }
  }

  get allowedToEdit (): boolean {
    return this.authUser.permissions.includes('manual.edit')
  }

  pageTitle (): string {
    return !this.editable ? `${this.page.title} ${this.page.published ? '' : '(Concept)'}` : ''
  }

  removeBlock (position: number): void {
    const block = this.getBlockByPosition(position)
    const index = this.page.blocks.indexOf(block)

    this.page.blocks.forEach(b => {
      if (b.position >= block.position) {
        b.position--
      }
    })

    this.clearMedia(block)
    this.page.blocks.splice(index, 1)
  }

  createBlock (position: number): void {
    this.blockModal.position = position + 1
    this.blockModal.show()
  }

  addBlock (type: string): void {
    const block = new ContentBlock(this.page.id)
    block.position = this.blockModal.position
    block.type = type

    this.page.blocks.forEach(b => {
      if (b.position >= block.position) {
        b.position++
      }
    })

    this.page.blocks.push(block)
    this.blockModal.close()
  }

  alignContent (block: ContentBlock, alignment:string): void {
    const index = this.page.blocks.indexOf(block)
    block.style = JSON.stringify({ 'text-align': alignment })
    Vue.set(this.page.blocks, index, block)
  }

  getBlockStyle (block: ContentBlock): Record<string, unknown> {
    return block.style !== undefined ? JSON.parse(block.style) : {}
  }

  getBlockByPosition (position: number): ContentBlock {
    return this.page.blocks.find(b => b.position === position) || this.page.blocks[0]
  }

  cancelEditing (): void {
    if (this.newPage) {
      this.$router.push({ name: 'manualIndex' })
    } else {
      this.editModeActive = false
      this.page = _.cloneDeep(this.originalData)
    }
  }

  save (): void {
    this.saving = true

    if (this.newPage) {
      this.create()
    } else {
      this.update()
    }
  }

  create (): void {
    axios.post(`${process.env.VUE_APP_API_URL}/pages/${this.collectionId}`, this.page)
      .then(res => {
        this.originalData = res.data as Page
        this.page = _.cloneDeep(this.originalData)
        this.saving = false
        this.$router.push({ name: 'manualPage', params: { collectionId: this.collectionId.toString(), slug: this.page.slug } })
      }).finally(() => {
        this.editModeActive = false
      })
  }

  update ():void {
    axios.put(`${process.env.VUE_APP_API_URL}/pages/${this.page.id}`, this.page)
      .then(res => {
        this.originalData = res.data as Page
        this.page = _.cloneDeep(this.originalData)
        this.saving = false
      }).finally(() => {
        this.editModeActive = false
      })
  }

  publish (): void {
    this.page.published = moment().format('YYYY-MM-DD HH:mm:ss')
    this.update()
  }

  unPublish (): void {
    this.page.published = null
    this.update()
  }

  deletePage (): void {
    axios.delete(`${process.env.VUE_APP_API_URL}/pages/${this.page.id}`)
      .finally(() => this.$router.push({ name: 'manualIndex' }))
  }

  fetchPageData (): void {
    axios.get(process.env.VUE_APP_API_URL + '/pages/' + this.$route.params.slug)
      .then(res => {
        this.originalData = res.data as Page
        this.page = _.cloneDeep(this.originalData)
        this.blocks = this.page.blocks
      })
  }

  get orderedBlocks (): ContentBlock[] {
    return _.orderBy(this.page.blocks, 'position')
  }

  clearMedia (block: ContentBlock): void {
    block.media = null
  }

  deleteAllowed (): boolean {
    return this.authUser.permissions.includes('projects.view')
  }

  async addBlockMedia (file: File, block: ContentBlock): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await this.getBase64Async(file) as string
    block.media = previewFile
  }

  getBase64Async (file: File):Promise<string|ArrayBuffer|null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
