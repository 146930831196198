import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import { notification } from 'ant-design-vue'
import moment from 'moment/moment'
import 'moment/locale/nl'
import router from '@/router'
import ObjectionModel from '@/models/ObjectionModel'
import SecureLS from 'secure-ls'
import { ServiceStates } from '@/types/serviceItem'
import Objection from '@/types/objection'
import axios from 'axios'
moment.locale('nl')

Vue.use(Vuex)
const storeName = 'objections'
const storage = new SecureLS({ isCompression: false })

export const objectionStore: Module<any, any> = {
  state: {
    objectionList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    InactiveObjectionColumns: storage.get(storeName + '-columns') || [],
    currentObjection: new Objection(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {},
    objectionManagers: []
  },
  mutations: {
    setCurrentObjection (state, { currentObjection }) {
      currentObjection.start_date = currentObjection.start_date ? moment(currentObjection.start_date, 'DD-MM-YYYY') : currentObjection.start_date
      currentObjection.end_date = currentObjection.end_date ? moment(currentObjection.end_date, 'DD-MM-YYYY') : currentObjection.end_date
      state.currentObjection = currentObjection
    },
    clearCurrentObjection (state) {
      state.currentObjection = new Objection()
    },
    setObjectionList (state, { objectionList }) {
      state.objectionList = objectionList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    enabledObjectionOptionsChanged (state, { enabledOptions }) {
      state.currentObjection.enabled_options = enabledOptions
    },
    setInactiveObjectionColumns (state, columns) {
      state.InactiveObjectionColumns = columns
    },
    setObjectionEstimate (state, date) {
      state.currentObjection.estimate = date
    },
    setObjectionProject (state, id) {
      state.currentObjection.project_id = id
    },
    setObjectionBuildingId (state, id) {
      state.currentObjection.building_id = id
    },
    setObjectionBuilding (state, id) {
      state.currentObjection.building = id
    },
    setObjectionManager (state, id) {
      state.currentObjection.manager_id = id
    },
    setObjectionManagers (state, managers) {
      state.objectionManagers = managers
    },
    setObjectionStatus (state, statusId) {
      state.currentObjection.status = statusId
    }
  },
  actions: {
    getObjectionList ({ commit }, settings) {
      commit('setLoading', { type: 'list', state: true })
      let objectionModel = new ObjectionModel()

      if (settings.activeFilters) {
        // Check if we want the archived items
        Object.keys(settings.archiveConditions).forEach(key => {
          if (settings.archiveConditions[key].length) {
            objectionModel = objectionModel.whereIn(key,
              Object.values(ServiceStates).filter(value => settings.archived
                ? settings.archiveConditions[key].includes(value)
                : !settings.archiveConditions[key].includes(value)))
          }
        })

        // Then check if they need to be filtered.
        Object.keys(settings.activeFilters).forEach(key => {
          if (settings.activeFilters[key].length) {
            objectionModel = objectionModel.whereIn(key, settings.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (settings.search) {
        objectionModel = objectionModel.where('search', settings.search)
      }

      // Add orderBy if sort is set.
      if (settings.sort) {
        objectionModel = objectionModel.orderBy(settings.sort.order === 'ascend' ? settings.sort.columnKey : '-' + settings.sort.columnKey)
      }

      if (settings.pagination) {
        objectionModel = objectionModel.limit(settings.pagination.pageSize).page(settings.pagination.current)
      }

      objectionModel.get().then((objectionList) => {
        commit('setObjectionList', { objectionList: objectionList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van bezwaren!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentObjection ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        ObjectionModel.$find(id).then((currentObjection) => {
          commit('setCurrentObjection', { currentObjection: currentObjection })
          resolve(currentObjection)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit bezwaar!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentObjection ({ commit }) {
      commit('clearCurrentObjection')
    },
    saveObjection ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { has_new_message, comments, ...payload } = data
      const objectionModel = new ObjectionModel(payload)

      // Save objection.
      objectionModel.save().then((currentObjection) => {
        commit('setErrors', {})
        commit('setCurrentObjection', { currentObjection: currentObjection })
        if (data.id) {
          notification.success({ message: 'Bezwaar opgeslagen.', description: 'Het bezwaar is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Bezwaar toegevoegd.', description: 'Het bezwaar is succesvol toegevoegd.', duration: 3 })
          if (currentObjection.id) {
            router.push({ name: 'objectionEdit', params: { objectionId: currentObjection.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit bezwaar!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit bezwaar!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    getObjectionManagers ({ commit, state }, projectId) {
      if (projectId === undefined) return
      commit('setErrors', {})
      axios.get(`${process.env.VUE_APP_API_URL}/members/${projectId}/mentions`)
        .then((res) => {
          commit('setObjectionManagers', res.data)
          if (state.currentObjection.manager_id === 0 && res.data.length > 0) {
            state.currentObjection.manager_id = res.data[0].id
          }
        })
    },
    setInactiveObjectionColumns ({ commit }, columns) {
      storage.set(storeName + '-columns', columns)
      commit('setInactiveObjectionColumns', columns)
    },
    enabledObjectionOptionsChanged ({ commit }, enabledOptions) {
      commit('enabledObjectionOptionsChanged', { enabledOptions })
    },
    setObjectionEstimate ({ commit }, date) {
      commit('setObjectionEstimate', date)
    },
    setObjectionProject ({ commit }, id) {
      commit('setObjectionProject', id)
    },
    setObjectionBuildingId ({ commit }, id) {
      commit('setObjectionBuildingId', id)
    },
    setObjectionBuilding ({ commit }, building) {
      commit('setObjectionBuilding', building)
    },
    setObjectionStatus ({ commit }, statusId) {
      commit('setObjectionStatus', statusId)
    },
    setObjectionManager ({ commit }, id) {
      commit('setObjectionManager', id)
    }
  },
  getters: {
    objectionList: state => state.objectionList,
    currentObjection: state => state.currentObjection,
    loadingObjection: state => state.loading,
    objectionErrors: state => state.errors,
    objectionManagers: state => state.objectionManagers,
    InactiveObjectionColumns: state => state.InactiveObjectionColumns
  }
}
