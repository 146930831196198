import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import FeedbackModel from '@/models/FeedbackModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
import SecureLS from 'secure-ls'
import Feedback, { FeedbackStates } from '@/types/feedback'
moment.locale('nl')

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: false })
const storeName = 'feedbacks'
export const feedbackStore: Module<any, any> = {
  state: {
    feedbackList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentFeedback: new Feedback(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {},
    inactiveFeedbackColumns: ls.get(storeName + '-columns') || []
  },
  mutations: {
    setCurrentFeedback (state, { currentFeedback }) {
      currentFeedback.when = currentFeedback.when ? new Date(currentFeedback.when).toLocaleString('nl-NL') : null
      currentFeedback.created_at = currentFeedback.created_at ? new Date(currentFeedback.created_at).toLocaleString('nl-NL') : null
      state.currentFeedback = currentFeedback
    },
    clearCurrentFeedback (state) {
      state.currentFeedback = new Feedback()
    },
    setFeedbackList (state, { feedbackList }) {
      state.feedbackList = feedbackList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    setInactiveFeedbackColumns (state, columns) {
      state.inactiveFeedbackColumns = columns
    },
    clearParent (state) {
      state.currentFeedback.parent = null
    }
  },
  actions: {
    getFeedbackList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let feedbackModel = new FeedbackModel()
      Object.keys(data.archiveConditions).forEach(key => {
        if (data.archiveConditions[key].length) {
          feedbackModel = feedbackModel.whereIn(key,
            Object.values(FeedbackStates).filter(value => data.archived
              ? data.archiveConditions[key].includes(value)
              : !data.archiveConditions[key].includes(value)))
        }
      })

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            feedbackModel = feedbackModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        feedbackModel = feedbackModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        feedbackModel = feedbackModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        feedbackModel = feedbackModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      feedbackModel.get().then((feedbackList) => {
        commit('setFeedbackList', { feedbackList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van feedbacks!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentFeedback ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        FeedbackModel.$find(id).then((currentFeedback) => {
          commit('setCurrentFeedback', { currentFeedback })
          resolve(currentFeedback)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze feedback!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentFeedback ({ commit }) {
      commit('clearCurrentFeedback')
    },
    disconnectFeedback ({ commit }) {
      commit('clearParent')
    },
    saveFeedback ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const feedbackModel = new FeedbackModel(data)

      // Save feedback.
      feedbackModel.save().then((currentFeedback) => {
        commit('setErrors', {})
        commit('setCurrentFeedback', { currentFeedback })
        if (data.id) {
          notification.success({ message: 'Feedback opgeslagen.', description: 'Deze feedback is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Feedback toegevoegd.', description: 'De feedback is succesvol toegevoegd.', duration: 3 })
          if (currentFeedback.id) {
            router.push({ name: 'feedbackShow', params: { feedbackId: currentFeedback.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze feedback!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze feedback!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    setInactiveFeedbackColumns ({ commit }, columns) {
      new SecureLS({ isCompression: false }).set(storeName + '-columns', columns)
      commit('setInactiveFeedbackColumns', columns)
    }
  },
  getters: {
    feedbackList: state => state.feedbackList,
    currentFeedback: state => state.currentFeedback,
    loadingFeedback: state => state.loading,
    feedbackErrors: state => state.errors,
    InactiveFeedbackColumns: state => state.inactiveFeedbackColumns
  }
}
