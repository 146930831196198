














































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'

@Component({
  components: {}
})

export default class TenantUserShowDetailsTab extends Vue {
  settings = {
    routeNames: { show: 'tenantUserShow', edit: 'tenantUserEdit' },
    routeParamKey: 'tenantUserId',
    functionNames: { setCurrent: 'setCurrentTenantUser', clearCurrent: 'clearCurrentTenantUser', save: 'saveTenantUser' },
    getterNames: { current: 'currentTenantUser', loading: 'loadingTenantUser', errors: 'tenantUserErrors' }
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
