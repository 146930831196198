



















































































































































import { Vue, Component } from 'vue-property-decorator'
import Order from '@/types/order'
import axios from 'axios'

@Component({
  components: {}
})

export default class OrderShowConfigurationCard extends Vue {
  settings = {
    routeNames: { list: 'orders', show: 'orderShow', edit: 'orderEdit' },
    routeParamKey: 'orderId',
    functionNames: { setCurrent: 'setCurrentOrder', save: 'saveOrder' },
    getterNames: { current: 'currentOrder', loading: 'loadingOrder' }
  }

  configItems:{ [key: string]: { columns:[], items:[], subtotal:number }} = {
    modular: {
      columns: [],
      items: [],
      subtotal: 0
    },
    carcass: {
      columns: [],
      items: [],
      subtotal: 0
    },
    completion1: {
      columns: [],
      items: [],
      subtotal: 0
    },
    completion2: {
      columns: [],
      items: [],
      subtotal: 0
    },
    completion3: {
      columns: [],
      items: [],
      subtotal: 0
    },
    extra: {
      columns: [],
      items: [],
      subtotal: 0
    }
  }

  optionDetails:{ reference: string, name: string, description: string, warranty: string, img: string }|null = null
  showOptionDetailsModal = false

  layoutUrl:string|null = null
  showLayoutModal = false

  subelementDetails = {
    columns: [],
    options: []
  }

  showSubElementModal = false
  loading = false
  loadingSubelements = false
  activeKeys = [
    'modular',
    'carcass',
    'completion1',
    'completion2',
    'completion3',
    'extra'
  ]

  mounted () : void {
    this.getOrderItems()
  }

  getOrderItems () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/orders/' + this.currentItem.id + '/items').then((res) => {
      this.configItems = res.data
      this.addFilters('completion1')
      this.addFilters('completion2')
    }).finally(() => {
      this.loading = false
    })
  }

  addFilters (key:string) : void {
    this.configItems[key].columns.forEach((column:{ key:string, filters: { text:string, value:string|number|null }[], onFilter:(value: string|number|null, record: { [key: string]: string|number|null }) => boolean }) => {
      if (column.filters) {
        column.onFilter = (value:any, record:any) => record[column.key].indexOf(value) === 0
      }
    })
  }

  showOptionDetails (details:{ reference: string, name: string, description: string, warranty: string, img: string }|null) : void {
    this.optionDetails = details
    this.showOptionDetailsModal = true
  }

  // TODO: Write function to get layout image.
  // showLayoutImage (roomId:number, groupId:number, layoutId:number) : void {
  //   const data = {
  //     roomId: roomId,
  //     groupId: groupId,
  //     layoutId: layoutId
  //   }
  // }

  showOptionsInSubelement (subelement:{ id:number }) : void {
    this.showSubElementModal = true
    this.loadingSubelements = true
    axios.get(process.env.VUE_APP_API_URL + '/orders/' + this.currentItem.id + '/subelements/' + subelement.id + '/options').then((res) => {
      this.subelementDetails = res.data
    }).finally(() => {
      this.loadingSubelements = false
    })
  }

  toggleLayoutModal () : void {
    this.showLayoutModal = !this.showLayoutModal
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Order {
    return this.$store.getters[this.settings.getterNames.current]
  }
}
