







































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Delivery from '@/types/delivery'
import DeliveryShowResultMedia from '@/views/delivery/components/DeliveryShowResultMedia.vue'
import axios from 'axios'
import DeliveryDetailsCard from '@/views/delivery/cards/DeliveryDetailsCard.vue'
import DeliveryRoomsCard from '@/views/delivery/cards/DeliveryRoomsCard.vue'
import DeliveryGeneralChecksCard from '@/views/delivery/cards/DeliveryGeneralChecksCard.vue'
import DeliveryMetersCard from '@/views/delivery/cards/DeliveryMetersCard.vue'
import DeliverySignaturesCard from '@/views/delivery/cards/DeliverySignaturesCard.vue'
import { notification } from 'ant-design-vue'

@Component({
  components: { DeliverySignaturesCard, DeliveryMetersCard, DeliveryGeneralChecksCard, DeliveryRoomsCard, DeliveryDetailsCard, DeliveryShowResultMedia }
})

export default class DeliveryShow extends Vue {
  settings = {
    routeNames: { list: 'deliveries', show: 'deliveryShow', edit: 'deliveryEdit' },
    routeParamKey: 'deliveryId',
    functionNames: { setCurrent: 'setCurrentDelivery', clearCurrent: 'clearCurrentDelivery', save: 'saveDelivery' },
    getterNames: { current: 'currentDelivery', loading: 'loadingDelivery' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('deliveryShowActiveTab') || 'details'
  downloading = false
  regenerating = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  downloadDeliveryPdf () : void {
    this.downloading = true
    axios.get(process.env.VUE_APP_API_URL + '/deliveries/' + this.currentItem.id + '/pdf', { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Resultaten_Keuring_' + this.currentItem.id + '.pdf'
        link.click()
      }).finally(() => {
        this.downloading = false
      })
  }

  regeneratePdf () : void {
    this.regenerating = true
    axios.get(process.env.VUE_APP_API_URL + '/deliveries/' + this.currentItem.id + '/pdf/regenerate').then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
      notification.success({ message: 'PvO + bijlages opnieuw gegenereerd.', description: 'Het PvO incl. bijlages is opnieuw gegenereerd en gekoppeld aan het opleverdossier van de koper.', duration: 3 })
    }).catch(() => {
      notification.success({ message: 'Fout tijdens genereren van PvO + bijlages!', description: 'Er is iets mis gegaan. Probeer het later nog eens.' })
    }).finally(() => {
      this.regenerating = false
    })
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Delivery {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
