






































import { Vue, Component, Prop } from 'vue-property-decorator'
import Feedback, { FeedbackClasses, SortedFeedbackStates } from '@/types/feedback'
import Column from '@/types/column'
import { Status } from '@/types/serviceItem'
import FeedbackModel from '@/models/FeedbackModel'
import axios from 'axios'

@Component({
  components: { }
})

export default class FeedbackTasks extends Vue {
  @Prop() feedback!: Feedback
  @Prop({ default: false, type: Boolean, required: false }) canEdit!: boolean
  @Prop({ default: false, type: Boolean, required: false }) canView!: boolean

  selectedFeedbackId = 0
  sortedStates = SortedFeedbackStates

  loading = false

  columns:Column[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 80
    },
    {
      title: 'Onderwerp',
      key: 'title',
      dataIndex: 'title',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Toegewezen aan',
      key: 'assigned_to_user.id',
      dataIndex: 'assigned_to_user.full_name',
      filters: [],
      sorter: false,
      width: 200,
      sortDirections: ['ascend', 'descend'],
      scopedSlots: { customRender: 'assignee' }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' },
      width: 120,
      filters: this.sortedStates.map((state:Status) => { return { text: state.name, value: state.id } }),
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      filterMultiple: true,
      onFilter: (value: any, record: any) => record.status === value
    },
    {
      title: 'Actie\'s',
      key: 'action',
      width: 80,
      align: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]

  getStatus (id: number):Status {
    return FeedbackClasses.filter(s => s.id === id)[0]
  }

  get feedbacksThatCanBeTasks (): { id:number, title:string }[] {
    return this.taskables.filter(task => task.id !== this.feedback.id)
  }

  taskables: { id: number, title: string }[] = []
  async getFeedbacks (open: boolean): Promise<void> {
    if (!open || this.taskables.length > 0) return

    this.loading = true
    await FeedbackModel.custom('feedbacks/task-ables').get()
      .then(res => {
        this.taskables = res as unknown as { id: number, title: string }[]
      })
      .finally(() => { this.loading = false })
  }

  addFeedbackAsTask (): void {
    if (this.selectedFeedbackId === 0) return
    axios.post(`${process.env.VUE_APP_API_URL}/feedbacks/${this.selectedFeedbackId}/task`, { parent_id: this.feedback.id })
    this.selectedFeedbackId = 0
    this.$emit('updated')
  }

  onTaskClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          if (!this.canEdit && !this.canView) return

          const keyboard = window.event as KeyboardEvent
          const route = this.$router.resolve({ name: 'feedbackShow', params: { feedbackId: rowData.id.toString() } })
          window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
        }
      }
    }
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  disconnectFeedback (feedbackId : number): void {
    axios.post(`${process.env.VUE_APP_API_URL}/feedbacks/${feedbackId}/task`, { parent_id: null })
    this.$emit('updated')
  }

  getRowClassName (): string {
    return 'row-class'
  }
}
