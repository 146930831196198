












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})

export default class DeliveryGeneralCheckRow extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) currentValue!: boolean
}
