














































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Message from '@/types/message'
import axios from 'axios'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import UploadQueue from '@/components/media/UploadQueue.vue'
import Project from '@/types/project'
import Building from '@/types/building'
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

@Component({
  components: { UploadQueue, Uploader, TextEditor }
})

export default class MessageCreate extends Vue {
  settings = {
    routeNames: { list: 'messages', show: 'messageShow', edit: 'messageEdit' },
    routeParamKey: 'messageId',
    functionNames: { setCurrent: 'setCurrentMessage', clearCurrent: 'clearCurrentMessage', save: 'saveMessage' },
    getterNames: { current: 'currentMessage', loading: 'loadingMessage', errors: 'messageErrors' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('messageEditActiveTab') || 'details'

  projects:Project[] = []
  buildings:Building[] = []

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
    this.getProjects()
  }

  getProjects () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projects?sort=name&limit=1000').then((r) => {
      this.projects = r.data.data
    })
  }

  getBuildings () : void {
    this.currentItem.building_ids = []
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.project_id + '&sort=number&limit=1000').then((r) => {
      this.buildings = r.data.data
    })
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  newFileUploaded (file:File) : void {
    this.currentItem.files.push(file)
  }

  fileDeleted (index:number) : void {
    this.currentItem.files.splice(index, 1)
  }

  selectAllBuildings (): void {
    this.currentItem.building_ids = this.buildings.map(building => building.id)
  }

  clearSelectedBuildings (): void {
    this.currentItem.building_ids = []
  }

  selectBuildingsOfType (type:number) : void {
    const buildingIds:number[] = []
    this.buildings.forEach((b:Building) => {
      if (b.object_type === type) {
        buildingIds.push(b.id)
      }
    })
    this.currentItem.building_ids = buildingIds
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('messageEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : Message {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
