










































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ProjectWebsite from '@/types/projectWebsite'
import axios from 'axios'
import moment from 'moment'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import { notification } from 'ant-design-vue'

@Component({
  components: { Filelist, Uploader, TextEditor }
})

export default class ProjectWebsiteEditNewsItemsTab extends Vue {
  settings = {
    routeNames: { list: 'projectWebsites', show: 'projectWebsiteShow', edit: 'projectWebsiteEdit' },
    routeParamKey: 'projectWebsiteId',
    functionNames: { setCurrent: 'setCurrentProjectWebsite', clearCurrent: 'clearCurrentProjectWebsite', save: 'saveProjectWebsite' },
    getterNames: { current: 'currentProjectWebsite', loading: 'loadingProjectWebsite', errors: 'projectWebsiteErrors' }
  }

  loading = false

  columns = [
    {
      title: 'Titel',
      key: 'title',
      scopedSlots: { customRender: 'title' }
    },
    {
      title: 'Actief',
      key: 'active',
      scopedSlots: { customRender: 'active' }
    },
    {
      title: 'Actief vanaf',
      key: 'active_from',
      scopedSlots: { customRender: 'active_from' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  newsItems:{ id:number|null, title:string|null, active:boolean, active_from:moment.Moment|null, texts:{ textNewsItem:{ id:number|null, text_position:string, text:string|null }, videoCode:{ id:number|null, text_position:string, text:string|null } }, images:{ newsImages:[], newsDocs:[] } }[] = []
  pages:{ id:number, title:string, page_type:string, slug:string }[]|null = null

  showAddModal = false
  showEditModal = false

  newsItem:{ [key:string] : any }|null = null

  mounted () : void {
    this.getNewsItems()
    this.getPages()
  }

  getNewsItems () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/news').then((res) => {
      this.newsItems = res.data

      if (this.newsItem && this.newsItem.id) {
        this.newsItems.forEach((newsItem:{ id:number|null, title:string|null, active:boolean, active_from:moment.Moment|null, texts:{ textNewsItem:{ id:number|null, text_position:string, text:string|null }, videoCode:{ id:number|null, text_position:string, text:string|null } }, images:{ newsImages:[], newsDocs:[] } }) => {
          if (this.newsItem && newsItem.id === this.newsItem.id) {
            this.newsItem.images = newsItem.images
          }
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  getPages () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/pages').then((res) => {
      this.pages = res.data
    })
  }

  toggleAddModal () : void {
    this.showAddModal = !this.showAddModal
    this.newsItem = {
      id: null,
      title: null,
      active: false,
      active_from: null,
      newsOverviewPageIds: [],
      texts: {
        textNewsItem: {
          id: null,
          text_position: 'textNewsItem',
          text: null
        },
        videoCode: {
          id: null,
          text_position: 'videoCode',
          text: null
        }
      },
      images: {
        newsImages: [],
        newsDocs: []
      }
    }
  }

  toggleEditModal (newsItem:{ id:number|null, title:string|null, active:boolean, active_from:moment.Moment|null, newsOverviewPageIds:number[], texts:{ textNewsItem:{ id:number|null, text_position:string, text:string|null }, videoCode:{ id:number|null, text_position:string, text:string|null } }, images:{ newsImages:[], newsDocs:[] } }|null) : void {
    if (newsItem) {
      this.showEditModal = true
      this.newsItem = newsItem
      this.newsItem.active_from = moment(newsItem.active_from)
    } else {
      this.showEditModal = false
      this.newsItem = {
        id: null,
        title: null,
        active: false,
        active_from: null,
        newsOverviewPageIds: [],
        texts: {
          textNewsItem: {
            id: null,
            text_position: 'textNewsItem',
            text: null
          },
          videoCode: {
            id: null,
            text_position: 'videoCode',
            text: null
          }
        },
        images: {
          newsImages: [],
          newsDocs: []
        }
      }
    }
  }

  contentChanged (key:string, value:string) : void {
    if (this.newsItem) {
      if (key === 'textNewsItem') {
        this.newsItem.texts.textNewsItem.text = value
      } else {
        this.newsItem[key] = value
      }
    }
  }

  storeNewsItem () : void {
    axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/news', this.newsItem).then(() => {
      this.getNewsItems()
      this.toggleAddModal()
    })
  }

  updateNewsItem () : void {
    if (this.newsItem) {
      axios.put(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/news/' + this.newsItem.id, this.newsItem).then(() => {
        this.getNewsItems()
        this.toggleEditModal(null)
        notification.success({ message: 'NewsItem opgeslagen.', description: 'De newsItem is succesvol opgeslagen.', duration: 3 })
      }).catch(() => {
        notification.error({ message: 'NewsItem NIET opgeslagen.', description: 'De newsItem is NIET opgeslagen. Probeer het later nogmaals.', duration: 0 })
      })
    }
  }

  newFileUploaded (file:File, key:string) : void {
    if (this.newsItem) {
      const formData = new FormData()
      formData.append('position', key)
      formData.append('file', file)

      axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/news/' + this.newsItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
        this.getNewsItems()
      })
    }
  }

  deleteNewsItem (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/news/' + id).then(() => {
      this.getNewsItems()
      notification.success({ message: 'NewsItem verwijderd.', description: 'De newsItem is succesvol verwijderd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'NewsItem NIET verwijderd.', description: 'De newsItem is NIET verwijderd. Probeer het later nogmaals.', duration: 0 })
    })
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : ProjectWebsite {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get newsOverviewPages () : { value:number, label:string }[] {
    const newsOverviewPages:{ value:number, label:string }[] = []
    if (this.pages) {
      this.pages.forEach((page) => {
        if (page.slug === '/') {
          newsOverviewPages.push({
            value: page.id,
            label: page.title
          })
        } else if (page.page_type === 'news_overview_page') {
          newsOverviewPages.push({
            value: page.id,
            label: page.title
          })
        }
      })
    }

    return newsOverviewPages
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
