




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'

@Component({
  components: { ckeditor: CKEditor.component }
})

export default class TextEditor extends Vue {
  @Prop({ default: null }) content!:string|null
  @Prop({ default: false, type: Boolean }) allowTable!:boolean

  editor = ClassicEditor
  editorData:string|null = null
  editorConfig = {
    plugins: [
      EssentialsPlugin,
      HeadingPlugin,
      BoldPlugin,
      ItalicPlugin,
      UnderlinePlugin,
      SubscriptPlugin,
      SuperscriptPlugin,
      LinkPlugin,
      ParagraphPlugin,
      ListPlugin,
      Table,
      TableToolbar
    ],
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', '|',
      'bulletedList', 'numberedList', '|',
      'subscript', 'superscript', '|',
      'link', (this.allowTable ? 'insertTable' : '|')
    ],
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    }
  }

  @Watch('content')
  onContentChange () : void {
    if (this.content) {
      this.editorData = this.content
    } else {
      this.editorData = ''
    }
  }

  editorReady () : void {
    this.editorData = this.content
  }

  textChanged (text:string) : void {
    this.$emit('contentChange', text)
  }
}
