








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})

export default class ServiceTypeByStatus extends Vue {
  @Prop({ type: Function }) method?: () => void
  @Prop({ type: Array, default: () => [] }) values!: {x:string, y:number}[]

  @Watch('values')
  onValuesChanged (): void {
    console.log(this.values)
  }

  options = {
    chart: {
      type: 'bar'
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    chartOptions: {
      tooltip: {
        shared: true,
        intersect: false
      }
    }
  }

  get series () {
    return [{
      name: 'aantal',
      data: this.values
    }]
  }
}
