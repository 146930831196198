export const units = [
  { name: '%', value: '%' },
  { name: 'Appartement', value: 'APP' },
  { name: 'Dag', value: 'DAG' },
  { name: 'Duizend', value: 'DUI' },
  { name: '€', value: 'EURO' },
  { name: 'Liter', value: 'LTR' },
  { name: 'Kilo', value: 'KG' },
  { name: 'Kilometer', value: 'KM' },
  { name: 'Meter', value: 'M1' },
  { name: 'Vierkante meter (m2)', value: 'M2' },
  { name: 'Kubieke meter (m3)', value: 'M3' },
  { name: 'Miljoen', value: 'MILJ' },
  { name: 'Manuur', value: 'MU' },
  { name: 'Post', value: 'POST' },
  { name: 'PST', value: 'PST' },
  { name: 'Stuk', value: 'STUK' },
  { name: 'Stuks', value: 'ST' },
  { name: 'Uur', value: 'UUR' },
  { name: 'Verdieping', value: 'VERD' },
  { name: 'Week', value: 'WK' },
  { name: 'Woning', value: 'WON' }
]

export const DeliveryStates = {
  Scheduled: 1,
  Concept: 2,
  Overdue: 3,
  Completed: 4
}

export const DeliveryStateLabels = {
  1: 'Ingepland',
  2: 'Concept',
  3: 'Verlopen',
  4: 'Voltooid'
}

export const DeliveryStateLabelColors = {
  1: '#2ea3f2',
  2: '#ffc107',
  3: '#ff0000',
  4: 'green'
}

export const OpenDeliveryStates = [
  DeliveryStates.Scheduled,
  DeliveryStates.Concept
]

export const ClosedDeliveryStates = [
  DeliveryStates.Overdue,
  DeliveryStates.Completed
]
