













































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import axios from 'axios'
import Role from '@/types/role'
import PermissionMatrix from '@/types/permissionMatrix'

@Component({
  components: {}
})

export default class CustomerUserCreateDetailsTab extends Vue {
  settings = {
    routeNames: { show: 'customerUserShow', edit: 'customerUserEdit' },
    routeParamKey: 'customerUserId',
    functionNames: { setCurrent: 'setCurrentCustomerUser', clearCurrent: 'clearCurrentCustomerUser', save: 'saveCustomerUser' },
    getterNames: { current: 'currentCustomerUser', loading: 'loadingCustomerUser', errors: 'customerUserErrors' }
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
