











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})

export default class DeliveryContractorsRow extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) contractors!: { id:number, full_name:string, address:string, zipcode:string, city:string }[]
}
