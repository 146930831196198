import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import SurveyModel from '@/models/SurveyModel'
import { notification } from 'ant-design-vue'
import router from '@/router'
import moment from 'moment'

Vue.use(Vuex)

export const surveyStore: Module<any, any> = {
  state: {
    surveyList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentSurvey: {
      floor: null,
      scheduled_user_id: null,
      scheduled_at: null,
      survey_form: {
        reference: null,
        name: null
      },
      project: {
        name: null,
        reference: null
      },
      building_block: {
        name: null
      },
      building: {
        number: null
      },
      scheduled_user: {
        full_name: null
      },
      completed_by_user: {
        full_name: null
      },
      auditor1_user: {
        full_name: null
      },
      auditor2_user: {
        full_name: null
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentSurvey (state, { currentSurvey }) {
      currentSurvey.scheduled_at = currentSurvey.scheduled_at ? moment(currentSurvey.scheduled_at) : null
      state.currentSurvey = currentSurvey
    },
    clearCurrentSurvey (state) {
      state.currentSurvey = {
        floor: null,
        scheduled_user_id: null,
        scheduled_at: null,
        survey_form: {
          reference: null,
          name: null
        },
        project: {
          name: null,
          reference: null
        },
        building_block: {
          name: null
        },
        building: {
          number: null
        },
        scheduled_user: {
          full_name: null
        },
        completed_by_user: {
          full_name: null
        },
        auditor1_user: {
          full_name: null
        },
        auditor2_user: {
          full_name: null
        }
      }
    },
    setSurveyList (state, { surveyList }) {
      state.surveyList = surveyList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getSurveyList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let surveyModel = new SurveyModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            surveyModel = surveyModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        surveyModel = surveyModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        surveyModel = surveyModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        surveyModel = surveyModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      surveyModel.get().then((surveyList) => {
        commit('setSurveyList', { surveyList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van keuringen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentSurvey ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        SurveyModel.$find(id).then((currentSurvey) => {
          commit('setCurrentSurvey', { currentSurvey })
          resolve(currentSurvey)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze keuring!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentSurvey ({ commit }) {
      commit('clearCurrentSurvey')
    },
    saveSurvey ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const surveyModel = new SurveyModel(data)

      // Save element.
      surveyModel.save().then((currentSurvey) => {
        commit('setErrors', {})
        commit('setCurrentSurvey', { currentSurvey })
        if (data.id) {
          notification.success({ message: 'Keuring opgeslagen.', description: 'De keuring is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Keuring toegevoegd.', description: 'De keuring is succesvol toegevoegd.', duration: 3 })
          if (currentSurvey.id) {
            router.push({ name: 'surveyEdit', params: { surveyId: currentSurvey.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze keuring!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze keuring!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    surveyList: state => state.surveyList,
    currentSurvey: state => state.currentSurvey,
    loadingSurvey: state => state.loading,
    surveyErrors: state => state.errors
  }
}
