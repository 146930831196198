








import { Vue, Component, Watch } from 'vue-property-decorator'
import SurveyForm from '@/types/surveyForm'
import ErrorCard from '@/components/base/ErrorCard.vue'
import LoadingCard from '@/components/base/LoadingCard.vue'
import FormDetails from '@/components/forms/FormDetails.vue'
import axios from 'axios'

@Component({
  components: { ErrorCard, LoadingCard, FormDetails }
})

export default class SurveyFormEdit extends Vue {
  loading = false
  error = false

  @Watch('surveyFormId')
  onFormIdChange () : void {
    this.getForm()
  }

  mounted () : void {
    this.getForm()
    this.$store.dispatch('getQuestionGroups')
    this.$store.dispatch('getQuestionCategories')
  }

  getForm () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/surveys/forms/' + this.$route.params.surveyFormId).then(res => {
      this.$store.dispatch('setCurrentForm', res.data)
    }).catch(() => {
      this.error = true
    }).finally(() => {
      this.loading = false
    })
  }

  get form () : SurveyForm {
    return this.$store.getters.form
  }

  get surveyFormId () : string {
    return this.$route.params.surveyFormId
  }
}
