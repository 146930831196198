











import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'

@Component({
  components: {}
})

export default class CompanyUserEditRolesTab extends Vue {
  settings = {
    routeNames: { show: 'companyUserShow', edit: 'companyUserEdit' },
    routeParamKey: 'companyUserId',
    functionNames: { setCurrent: 'setCurrentCompanyUser', clearCurrent: 'clearCurrentCompanyUser', save: 'saveCompanyUser' },
    getterNames: { current: 'currentCompanyUser', loading: 'loadingCompanyUser', errors: 'companyUserErrors' }
  }

  roles:{ label:string, value:number, disabled?:boolean }[] = [
    {
      label: 'Externe gebruiker',
      value: 22,
      disabled: true
    },
    {
      label: 'Bedrijfseigenaar (extern)',
      value: 35
    },
    {
      label: 'Voorman (extern)',
      value: 38
    },
    {
      label: 'Leverancier',
      value: 16
    },
    {
      label: 'Leverancier Manager',
      value: 37
    }
  ]

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
