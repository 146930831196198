import { HTTPRequestConfig, Model as BaseModel } from 'vue-api-query'

export default class Model extends BaseModel {
  baseURL () :string {
    return process.env.VUE_APP_API_URL
  }

  request (config:HTTPRequestConfig) : any {
    return this.$http.request(config)
  }
}
