import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import RoleModel from '@/models/RoleModel'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import router from '@/router'

Vue.use(Vuex)

export const roleStore: Module<any, any> = {
  state: {
    roleList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentRole: {
      id: null,
      permissions: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    }
  },
  mutations: {
    setRoleList (state, { roleList }) {
      state.roleList = roleList
    },
    clearCurrentRole (state) {
      state.currentRole = {
        id: null,
        permissions: []
      }
    },
    setCurrentRole (state, { currentRole }) {
      state.currentRole = currentRole
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    }
  },
  actions: {
    getRoleList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let roleModel = new RoleModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            roleModel = roleModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        roleModel = roleModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        roleModel = roleModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        roleModel = roleModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      // Get roles.
      roleModel.get().then((roleList) => {
        commit('setRoleList', { roleList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van rollen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentRole ({ commit }, id) {
      commit('setLoading', { type: 'item', state: true })

      // Get role.
      RoleModel.$find(id).then((currentRole) => { // IMPORTANT: Have to use $find() instead of find() because of data wrapping.
        commit('setCurrentRole', { currentRole })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van deze rol!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'item', state: false })
      })
    },
    clearCurrentRole ({ commit }) {
      commit('clearCurrentRole')
    },
    saveRole ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const roleModel = new RoleModel(data)

      // Save role.
      roleModel.save().then((currentRole) => {
        commit('setCurrentRole', { currentRole })
        if (data.id) {
          notification.success({ message: 'Rol opgeslagen.', description: 'De rol is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Rol toegevoegd.', description: 'Het project is succesvol toegevoegd.', duration: 3 })
          if (currentRole.id) {
            router.push({ name: 'roleEdit', params: { roleId: currentRole.id.toString() } })
          }
        }
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het opslaan van deze rol!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    roleList: state => state.roleList,
    currentRole: state => state.currentRole,
    loadingRole: state => state.loading
  }
}
