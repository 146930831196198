































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import moment from 'moment/moment'
import PageHeader from '@/components/base/PageHeader.vue'
import Issue from '@/types/issue'
import Objection from '@/types/objection'
import Question from '@/types/question'
import axios from 'axios'
import ServiceStatusChart from '@/components/charts/ServiceStatusChart.vue'
import { ServiceClasses } from '@/types/serviceItem'
import ServiceTypeByStatus from '@/components/charts/ServiceTypeByStatus.vue'
import _ from 'lodash'

interface issueData {
  status: {
    id: number,
    name: string
  }
  created_at: string,
  project_name: string,
  project_reference: string,
  solver_name: string,
  type: {
    id: number,
    name: string
  }
}

@Component({
  components: {
    ServiceTypeByStatus,
    ServiceStatusChart,
    PageHeader
  }
})

export default class ServiceReport extends Vue {
  moment = moment
  ls = new SecureLS({ isCompression: false })
  serviceClasses = ServiceClasses

  filterYears: { active: boolean, name: string, value: number }[] = [
    { active: true, name: 'Alles', value: 0 },
    { active: false, name: '2022', value: 2022 },
    { active: false, name: '2021', value: 2021 },
    { active: false, name: '2020', value: 2020 },
    { active: false, name: '2019', value: 2019 },
    { active: false, name: '2018', value: 2018 },
    { active: false, name: '2017', value: 2017 },
    { active: false, name: '2016', value: 2016 },
    { active: false, name: '2015', value: 2015 }
  ]

  showUnassigned = true

  loading = {
    issueData: false,
    issues: false,
    objections: false,
    questions: false
  }

  companyChartOptions = {
    chart: {
      type: 'bar',
      height: 200
    },
    colors: ['#e36414', '#0f4c5c', '#ffbf69'],
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    chartOptions: {
      tooltip: {
        shared: true,
        intersect: false
      }
    }
  }

  companyChartData = []

  issues: Issue[] = []
  objections: Objection[] = []
  questions: Question[] = []
  serviceCount: [] = []

  issueData: issueData[] = []

  mounted (): void {
    this.fetchIssueData()
  }

  // Year filter functions
  get showAllYears (): boolean {
    return this.filterYears[0].active || this.filterYears.filter(y => y.active).length === 0
  }

  get activeYears (): number[] {
    if (this.showAllYears) {
      return this.filterYears.filter(y => y.value !== 0).map(y => y.value)
    } else {
      return this.filterYears.filter(y => y.active).map(y => y.value)
    }
  }

  onYearSelected (year: { active: boolean, name: string, value: number }):void {
    if (year.value === 0) {
      this.filterYears.forEach(y => {
        if (y.value !== 0) y.active = false
      })
    } else {
      this.filterYears[0].active = false
    }

    if (this.activeYears.length === this.filterYears.length - 1) {
      this.filterYears.forEach(y => { y.active = y.value === 0 })
    }
  }

  getYearCount (year: { active: boolean, name: string, value: number }): number {
    return year.value === 0 ? this.issueData.length : this.issueData.filter(i => this.moment(i.created_at).year() === year.value).length
  }

  fetchIssueData () : void {
    this.loading.issues = true
    axios.get(process.env.VUE_APP_API_URL + '/reports/service/issues').then((res) => {
      this.issueData = res.data
    }).finally(() => {
      this.loading.issueData = false
    })
  }

  get activeIssues (): issueData[] {
    let issues = this.issueData.filter(i => this.activeYears.includes(this.moment(i.created_at).year()) && i.status.id !== 4)
    if (!this.showUnassigned) {
      issues = issues.filter(i => i.solver_name !== 'Niet toegewezen')
    }
    return issues
  }

  get solverData (): {name: string, data: { x:string, y:number }[]}[] {
    const values = _.groupBy(this.activeIssues, i => i.solver_name)
    const orderedValues: issueData[][] = _.orderBy(values, v => v.length, 'desc')

    return [{
      name: 'aantal',
      data: orderedValues.map(v => [{ x: v[0].solver_name, y: v.length }]).flat().slice(0, 10)
    }]
  }

  getIssues () : void {
    this.loading.issues = true
    axios.get(process.env.VUE_APP_API_URL + '/issues?sort=-id&limit=50').then((res) => {
      this.issues = res.data.data
    }).finally(() => {
      this.loading.issues = false
    })
  }

  getObjections () : void {
    this.loading.objections = true
    axios.get(process.env.VUE_APP_API_URL + '/objections?sort=-id').then((res) => {
      this.objections = res.data.data
    }).finally(() => {
      this.loading.objections = false
    })
  }

  getQuestions () : void {
    this.loading.questions = true
    axios.get(process.env.VUE_APP_API_URL + '/questions?sort=-id').then((res) => {
      this.questions = res.data.data
    }).finally(() => {
      this.loading.questions = false
    })
  }

  get series () {
    return this.statusData
  }

  get statusData (): any[] {
    const data: any[] = [
      {
        name: 'totaal',
        data: [
          this.issues.length,
          this.objections.length,
          this.questions.length
        ]
      }
    ]
    Object.values(this.serviceClasses).forEach(state => {
      const entry = {
        name: state.name,
        data: [
          this.issues.filter(i => i.status === state.id).length,
          this.objections.filter(i => i.status === state.id).length,
          this.questions.filter(i => i.status === state.id).length
        ]
      }
      data.push(entry)
    })
    return data
  }

  get issueInfo (): { name: string, data: number[] } {
    const statusData: any[] = [this.issues.length]
    Object.values(this.serviceClasses).forEach(state => {
      statusData.push(this.issues.filter(i => i.status === state.id).length)
    })

    return {
      name: 'Meldingen',
      data: statusData
    }
  }

  get objectionData (): { name: string, data: number[] } {
    const statusData: any[] = [this.objections.length]
    Object.values(this.serviceClasses).forEach(state => {
      statusData.push(this.objections.filter(i => i.status === state.id).length)
    })

    return {
      name: 'Bezwaren',
      data: statusData
    }
  }

  get questionData (): { name: string, data: number[] } {
    const statusData: any[] = [this.questions.length]
    Object.values(this.serviceClasses).forEach(state => {
      statusData.push(this.questions.filter(i => i.status === state.id).length)
    })

    return {
      name: 'vragen',
      data: statusData
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
