
































import { Component, Vue } from 'vue-property-decorator'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import UserSetting from '@/types/userSetting'

@Component({
  components: {}
})

export default class Login extends Vue {
  username = ''
  password = ''
  loading = false

  mounted () : void {
    if (this.$store.getters.isLoggedIn) {
      this.redirect()
    }
  }

  login () : void {
    const username = this.username
    const password = this.password
    this.loading = true

    this.$store.dispatch('login', { username, password }).then(() => {
      this.getUserSettings()
      this.redirect()
    }).catch((e) => {
      if (e.response.status === 401) {
        notification.error({ message: 'Onjuiste gebruikersnaam en/of wachtwoord!', description: 'De door jou ingevulde combinatie van gebruikersnaam en wachtwoord is niet bij ons bekend. Probeer het nog een keer.' })
      } else if (e.response.status === 403 && e.response.data === 'Account inactive.') {
        notification.error({ message: 'Account inactief!', description: 'De door jou ingevulde combinatie van gebruikersnaam en wachtwoord is niet meer actief.' })
      } else {
        notification.error({ message: 'Fout tijdens inloggen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.' })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  loginWithO365 () : void {
    window.location.href = process.env.VUE_APP_API_URL + '/loginWithO365?appDomain=' + process.env.VUE_APP_MS_CALLBACK_DOMAIN
  }

  getUserSettings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users/admin/settings').then((res) => {
      if (res.data.length) {
        res.data.forEach((setting:UserSetting) => {
          new SecureLS({ isCompression: false }).set(setting.setting_name, setting.setting_value)
        })
      }
    })
  }

  redirect () : void {
    this.$router.push({ name: 'dashboard' })
  }

  get blockNormalLogin () : boolean {
    return this.username.includes('@thunnissen.nl')
  }

  get screenWidth () : number {
    return screen.width
  }

  get appVersion () : string {
    return process.env.VUE_APP_VERSION
  }
}
