















































import { Vue, Component } from 'vue-property-decorator'
import PageHeader from '@/components/base/PageHeader.vue'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import TableSettings from '@/types/tableSettings'
import SecureLS from 'secure-ls'
import axios from 'axios'
import MediaItem from '@/types/mediaItem'
import { sortBy, split } from 'lodash'
import Column from '@/types/column'
import EventMessageOverview, { EventMessage } from '@/views/communication/events/EventMessageOverview.vue'
import EventTriggerModel from '@/models/EventTriggerModel'
import EditableCell from '@/components/utility/EditableCell.vue'
import EventTriggerModal from '@/views/communication/events/EventTriggerModal.vue'
import EventMessageModel from '@/models/EventMessageModel'

export class EventTrigger {
  id: number | null = null
  name = ''
  description = ''
}

@Component({
  methods: { split },
  components: { EventTriggerModal, EditableCell, EventMessageOverview, ActiveFilters, PageHeader }
})
export default class EventTriggerOverview extends Vue {
  ls = new SecureLS({ isCompression: false })
  loading = {
    triggers: false,
    messages: false,
    preview: false
  }

  trigger = new EventTrigger()
  triggerModel = new EventTriggerModel()
  messageModel = new EventMessageModel()
  triggerModalVisible = false

  editMode = false
  preview: MediaItem = new MediaItem()

  localStorageName = 'eventMessagesTableSettings'
  sortOrder = { column: 'name', order: 'ascend' }
  conditions = {}
  tableSettings:TableSettings = this.ls.get(this.localStorageName) || new TableSettings(this.conditions, this.sortOrder)

  eventTriggers: EventTrigger[] = []

  filters: {
    categories: string[],
    types: string[],
    targets: string[]
  } = {
    categories: [],
    types: [],
    targets: []
  }

  types: string[] = []
  categories: string[] = []
  targets: string[] = []

  timer?:number
  timeout = 600

  get columns (): Column[] {
    return [
      {
        title: 'Trigger',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        scopedSlots: { customRender: 'name' }
      },
      {
        title: 'Acties',
        dataIndex: 'id',
        key: 'actions',
        width: 100,
        scopedSlots: { customRender: 'actions' },
        align: 'right'
      }
    ]
  }

  mounted () : void {
    this.getEventTriggers()
    this.getConstants()
  }

  getConstants (): void {
    axios.get(process.env.VUE_APP_API_URL + '/event-triggers/constants')
      .then((response) => {
        this.types = sortBy(response.data.types, (type) => type)
        this.categories = sortBy(response.data.categories, (category) => category)
        this.targets = sortBy(response.data.targets, (target) => target)
      })
  }

  getEventTriggers (): void {
    this.loading.triggers = true
    if (this.tableSettings.sort && this.tableSettings.sort.columnKey !== undefined) {
      this.triggerModel.orderBy(this.tableSettings.sort.order === 'ascend' ? this.tableSettings.sort.columnKey : '-' + this.tableSettings.sort.columnKey)
    }

    Object.keys(this.tableSettings.activeFilters).forEach(key => {
      if (this.tableSettings.activeFilters[key].length) {
        this.triggerModel.whereIn(key, this.tableSettings.activeFilters[key])
      }
    })

    if (this.tableSettings.search !== '') {
      this.triggerModel.where('search', this.tableSettings.search)
    }
    this.triggerModel.get().then((response: any) => {
      if ('data' in response) {
        this.eventTriggers = response.data
      } else {
        this.eventTriggers = response
      }
      this.loading.triggers = false
    }).catch((error) => {
      console.log(error)
      this.loading.triggers = false
    })
  }

  handleSearchChange () : void {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      this.getEventTriggers()
    }, this.timeout)
  }

  capitalize (s: string) : string {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  get typesFilter () : { text: string, value: number }[] {
    return this.types.map((type, index) => ({ text: this.capitalize(type), value: index }))
  }

  get categoriesFilter () : { text: string, value: number }[] {
    return this.categories.map((category, index) => ({ text: this.capitalize(category), value: index }))
  }

  get targetsFilter () : { text: string, value: number }[] {
    return this.targets.map((target, index) => ({ text: this.capitalize(target), value: index }))
  }

  get activeFilters () : Record<string, (string|number|boolean)[]> {
    return this.tableSettings.activeFilters
  }

  // Required for ActiveFilters component
  updateFilters (filters: {[key: string]: number[]|string[]|boolean[]}) : void {
    const { columns } = this

    columns.forEach((column:Column) => {
      column.filteredValue = column.key in filters ? filters[column.key] : []
    })

    this.tableSettings.activeFilters = Object.assign({}, filters)
    this.getEventTriggers()
  }

  onTableChanged (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    this.tableSettings.activeFilters = filters
    this.tableSettings.pagination = pagination
    this.tableSettings.sort = sort

    this.getEventTriggers()
  }

  deleteTrigger (id: number) : void {
    this.loading.triggers = true
    axios.delete(process.env.VUE_APP_API_URL + '/event-triggers/' + id)
      .then(() => {
        this.getEventTriggers()
      }).catch((error) => {
        console.log(error)
        this.loading.triggers = false
      })
  }

  OnRowExpanded (expanded: boolean, record: EventTrigger) : void {
    if (expanded) {
      this.trigger = record
      this.getEventMessages()
    }
  }

  get canEdit () : boolean {
    return this.$store.getters.can('event_messages.edit')
  }

  get canView () : boolean {
    return this.$store.getters.can('event_messages.view')
  }

  onTriggerModalClosed (changed: boolean) : void {
    this.triggerModalVisible = false
    this.trigger = new EventTrigger()
    this.loading.triggers = false

    if (changed) {
      this.getEventTriggers()
      this.getEventMessages()
    }
  }

  getEventMessages () :void {
    this.$nextTick(() => {
      const messageOverview = this.$refs.messageOverview as EventMessageOverview
      messageOverview.getEventMessages()
    })
  }

  openTriggerModal (trigger: EventTrigger|null) : void {
    this.trigger = trigger || new EventTrigger()
    this.triggerModalVisible = true
  }
}
