

























import { Component, Prop, Vue } from 'vue-property-decorator'
import fileTypeChecker from 'file-type-checker'

@Component({
  components: {}
})

export default class SimpleUploader extends Vue {
  @Prop({ default: 32 }) maxFileSize!:number
  @Prop({ type: Boolean, default: true }) multiple!:boolean
  @Prop({ type: Boolean }) base64!:boolean
  @Prop({ default: 0 }) currentCount!:number
  @Prop({ default: () => ['jpg', 'jpeg', 'png', 'pdf', 'mp4', 'm4v'] }) allowedTypes!:string[]
  @Prop({ default: () => ['m4v'] }) hiddenTypes!:string[]
  @Prop({ type: Boolean }) buttonOnly!:boolean

  async handleUpload (file: File) : Promise<boolean> {
    const isValid = await this.isValidFile(file)
    if (isValid) {
      this.$emit('fileAdded', file)
    }
    return false
  }

  async isValidFile (file: File): Promise<boolean> {
    const buffer = await file.arrayBuffer()
    const detectedFile = fileTypeChecker.detectFile(buffer)

    if (!this.hasValidFileType(detectedFile?.extension, this.allowedTypes)) {
      this.$message.error(`Het type ${detectedFile?.extension} wordt niet ondersteund!`)
      return false
    }

    if (!this.hasValidFileSize(file.size)) {
      this.$message.error('Bestand mag niet groter zijn dan ' + this.maxFileSize + 'MB!')
      return false
    }

    return true
  }

  get acceptedTypesInfo (): string {
    // return allowedTypes except hiddenTypes
    return this.allowedTypes.filter(type => !this.hiddenTypes.includes(type)).join(', ')
  }

  handleChange (info: any): void {
    this.handleUpload(info.file)
  }

  hasValidFileSize (size: number): boolean {
    return size / 1024 / 1024 < this.maxFileSize
  }

  hasValidFileType (type: string|undefined, allowedTypes: string[]): boolean {
    if (!type) return false
    return allowedTypes.includes(type)
  }
}
