















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import User from '@/types/user'
import axios from 'axios'

@Component({})
export default class UserSelector extends Vue {
  @Prop({ required: true }) userType!: string
  @Prop({ type: Number, default: 0 }) current!: number
  @Prop({ type: Array, default: () => [] }) roles!: string[]
  @Prop({ type: Boolean, default: false }) dontLoadOnMount!: boolean
  @Prop({ type: Boolean, default: false }) withDevelopers!: boolean
  @Prop({ type: Boolean, default: false }) withManagement!: boolean
  @Prop({ type: Boolean, default: false }) editable!: boolean

  @Watch('current')
  onCurrentChanged (value: number): void {
    this.selected = value ?? 0
  }

  selected = 0
  users: User[] = []

  mounted (): void {
    if (!this.dontLoadOnMount) {
      this.loadUsers()
    }
  }

  loadUsers (): void {
    const rolesToLoad = this.roles
    if (this.withDevelopers) {
      rolesToLoad.push('developer')
    }
    if (this.withManagement) {
      rolesToLoad.push('management')
      rolesToLoad.push('admin')
    }
    axios.get(`${process.env.VUE_APP_API_URL}/users?filter[roles.name]=${this.roles.join(',')}&limit=2500`).then((response) => {
      this.users = response.data.data
      this.users.sort((a, b) => a.full_name.localeCompare(b.full_name))
    })
  }

  onUserChanged (value: number): void {
    this.$emit('change', value)
  }

  get selectedUser (): User | undefined {
    return this.users.find(user => user.id === this.selected)
  }
}
