












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})

export default class DeliveryMediaRow extends Vue {
  @Prop({ required: true }) media!: { pvo: { id:number, url:string }|null, attachment: { id:number, url:string }|null }

  openFile (url:string) : void {
    window.open(url, '_blank')
  }
}
