












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ServiceItem from '@/types/serviceItem'
import axios from 'axios'

@Component({
  components: { }
})

export default class ServiceContactInfo extends Vue {
  @Prop({ required: true }) loading!: boolean
  @Prop({ required: true, default: () => new ServiceItem() }) current!: ServiceItem

  columnWidth = { labels: 10, values: 14 }
  contacts: { name: string, phone: string, email: string }[] = []
  mainContact: { name: string, phone: string, email: string } = { name: '', phone: '', email: '' }
  selectedIndex = 0
  loadingContacts = false

  @Watch('current.building_id', { deep: true })
  onCustomerChange (): void {
    if (this.current.building_id) {
      this.getContacts(this.current.building_id)
    }
  }

  mounted () : void {
    if (this.current.building_id) {
      this.getContacts(this.current.building_id)
    }
  }

  getContacts (id: number): void {
    this.loadingContacts = true
    axios.get(`${process.env.VUE_APP_API_URL}/buildings/${id}/contacts`)
      .then(response => {
        this.contacts = response.data.others
        this.mainContact = response.data.main
        this.updateContactDetails()
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.loadingContacts = false
      })
  }

  updateContactDetails (): void {
    if (this.current.id) return
    this.setContactDetails()
  }

  setContactDetails (): void {
    if (this.selectedIndex === this.contacts.length + 1) {
      this.current.contact_name = ''
      this.current.contact_phone = ''
      this.current.contact_email = ''
    } else if (this.selectedIndex === 0) {
      this.current.contact_name = this.mainContact.name
      this.current.contact_phone = this.mainContact.phone
      this.current.contact_email = this.mainContact.email
    } else {
      this.current.contact_name = this.contacts[this.selectedIndex - 1].name
      this.current.contact_phone = this.contacts[this.selectedIndex - 1].phone
      this.current.contact_email = this.contacts[this.selectedIndex - 1].email
    }
  }

  get isRental (): boolean {
    const rentalStates:number[] = [5, 6, 7]
    return rentalStates.includes(this.current.building?.state)
  }
}
