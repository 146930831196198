



















































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import BuildingConfig from '@/types/buildingConfig'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingConfigShowConfigurationCard extends Vue {
  settings = {
    routeNames: { list: 'buildingConfigs', show: 'buildingConfigShow', edit: 'buildingConfigEdit' },
    routeParamKey: 'buildingConfigId',
    functionNames: { setCurrent: 'setCurrentBuildingConfig', save: 'saveBuildingConfig' },
    getterNames: { current: 'currentBuildingConfig', loading: 'loadingBuildingConfig' }
  }

  configItems:{ [key: string]: { columns:[], items:[], subtotal:number }} = {
    modular: {
      columns: [],
      items: [],
      subtotal: 0
    },
    carcass: {
      columns: [],
      items: [],
      subtotal: 0
    },
    completion1: {
      columns: [],
      items: [],
      subtotal: 0
    },
    completion2: {
      columns: [],
      items: [],
      subtotal: 0
    },
    completion3: {
      columns: [],
      items: [],
      subtotal: 0
    },
    extra: {
      columns: [],
      items: [],
      subtotal: 0
    }
  }

  optionDetails:{ reference: string, name: string, description: string, warranty: string, img: string }|null = null
  showOptionDetailsModal = false

  layoutUrl:string|null = null
  showLayoutModal = false

  subelementDetails = {
    columns: [],
    options: []
  }

  showSubElementModal = false
  loading = false
  loadingSubelements = false

  mounted () : void {
    this.getBuildingConfigItems()
  }

  getBuildingConfigItems () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildingConfigs/' + this.currentItem.id + '/items').then((res) => {
      this.configItems = res.data
      this.addFilters('completion1')
      this.addFilters('completion2')
    }).finally(() => {
      this.loading = false
    })
  }

  addFilters (key:string) : void {
    this.configItems[key].columns.forEach((column:{ key:string, filters: { text:string, value:string|number|null }[], onFilter:(value: string|number|null, record: { [key: string]: string|number|null }) => boolean }) => {
      if (column.filters) {
        column.onFilter = (value:any, record:any) => record[column.key].indexOf(value) === 0
      }
    })
  }

  showOptionDetails (details:{ reference: string, name: string, description: string, warranty: string, img: string }|null) : void {
    this.optionDetails = details
    this.showOptionDetailsModal = true
  }

  showLayoutImage (item:{ roomId:number, groupId:number, layoutId:number }) : void {
    axios.post(process.env.VUE_APP_API_URL + '/buildingConfigs/media/layout', item).then((r) => {
      if (r.data) {
        this.showLayoutModal = true
        this.layoutUrl = r.data
      } else {
        notification.error({ message: 'Geen layout afbeelding gevonden.', description: 'Er is geen layout afbeelding gevonden. Probeer het later nog eens.' })
      }
    }).catch(() => {
      notification.error({ message: 'Geen layout afbeelding gevonden.', description: 'Er is geen layout afbeelding gevonden. Probeer het later nog eens.' })
    })
  }

  showOptionsInSubelement (subelement:{ id:number }) : void {
    this.showSubElementModal = true
    this.loadingSubelements = true
    axios.get(process.env.VUE_APP_API_URL + '/buildingConfigs/' + this.currentItem.id + '/subelements/' + subelement.id + '/options').then((res) => {
      this.subelementDetails = res.data
    }).finally(() => {
      this.loadingSubelements = false
    })
  }

  toggleLayoutModal () : void {
    this.showLayoutModal = !this.showLayoutModal
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : BuildingConfig {
    return this.$store.getters[this.settings.getterNames.current]
  }
}
