

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})

export default class ErrorCard extends Vue {
  @Prop() title!:string
  @Prop() message!:string
}
