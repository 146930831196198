

























import { Component, Vue } from 'vue-property-decorator'
import nl_NL from 'ant-design-vue/lib/locale-provider/nl_NL'
import moment from 'moment'
import 'moment/locale/nl'
import Navigation from '@/components/base/Navigation.vue'
import HeaderBar from '@/components/base/HeaderBar.vue'
moment.locale('nl')

@Component({
  components: { Navigation, HeaderBar }
})

export default class App extends Vue {
  locale = nl_NL

  get isLoggedIn () : boolean {
    return this.$store.getters.isLoggedIn
  }

  get currentYear () : number {
    return moment().year()
  }

  get appVersion () : string {
    return process.env.VUE_APP_VERSION
  }
}
