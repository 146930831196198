import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import ElementModel from '@/models/ElementModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import router from '@/router'
import Subelement from '@/types/subelement'

Vue.use(Vuex)

export const elementStore: Module<any, any> = {
  state: {
    elementList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentElement: {
      name: '',
      name_sales: '',
      text_sales: null,
      text_technical: null,
      phase: 0,
      group: 10,
      height: null,
      selector_type: 'default',
      subelement_ids: [],
      subelements: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentElement (state, { currentElement }) {
      state.currentElement = currentElement
    },
    clearCurrentElement (state) {
      state.currentElement = {
        name: '',
        name_sales: '',
        text_sales: null,
        text_technical: null,
        phase: 0,
        group: 10,
        height: null,
        selector_type: 'default',
        subelement_ids: [],
        subelements: []
      }
    },
    setElementList (state, { elementList }) {
      state.elementList = elementList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    subelementAddedToElement (state, { subelement }) {
      if (!state.currentElement.subelement_ids.includes(subelement.id)) {
        state.currentElement.subelement_ids.push(subelement.id)
        state.currentElement.subelements.push(subelement)
      }
    },
    subelementDeletedFromElement (state, { id }) {
      state.currentElement.subelement_ids.splice(state.currentElement.subelement_ids.indexOf(id), 1)

      let subelementIndex = null
      state.currentElement.subelements.forEach((subelement:Subelement, index:number) => {
        if (subelement.id === id) {
          subelementIndex = index
        }
      })

      if (subelementIndex !== null) {
        state.currentElement.subelements.splice(subelementIndex, 1)
      }
    }
  },
  actions: {
    getElementList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let elementModel = new ElementModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            elementModel = elementModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        elementModel = elementModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        elementModel = elementModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        elementModel = elementModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      elementModel.get().then((elementList) => {
        commit('setElementList', { elementList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de elementen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentElement ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        ElementModel.$find(id).then((currentElement) => {
          commit('setCurrentElement', { currentElement })
          resolve(currentElement)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit element!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentElement ({ commit }) {
      commit('clearCurrentElement')
    },
    saveElement ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const elementModel = new ElementModel(data)

      // Save element.
      elementModel.save().then((currentElement) => {
        commit('setErrors', {})
        commit('setCurrentElement', { currentElement })
        if (data.id) {
          notification.success({ message: 'Element opgeslagen.', description: 'Het element is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Element toegevoegd.', description: 'Het element is succesvol toegevoegd.', duration: 3 })
          if (currentElement.id) {
            router.push({ name: 'elementEdit', params: { elementId: currentElement.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit element!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit element!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    subelementAddedToElement ({ commit }, subelement) {
      commit('subelementAddedToElement', { subelement })
    },
    subelementDeletedFromElement ({ commit }, id) {
      commit('subelementDeletedFromElement', { id })
    }
  },
  getters: {
    elementList: state => state.elementList,
    currentElement: state => state.currentElement,
    loadingElement: state => state.loading,
    elementErrors: state => state.errors
  }
}
