import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import LayoutModel from '@/models/LayoutModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import router from '@/router'

Vue.use(Vuex)

export const layoutStore: Module<any, any> = {
  state: {
    layoutList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentLayout: {
      external_ref: '',
      name: '',
      text_sales: null,
      text_technical: null,
      purchase_price: null,
      price: null,
      default: false
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentLayout (state, { currentLayout }) {
      state.currentLayout = currentLayout
    },
    clearCurrentLayout (state) {
      state.currentLayout = {
        external_ref: '',
        name: '',
        text_sales: null,
        text_technical: null,
        purchase_price: null,
        price: null,
        default: false
      }
    },
    setLayoutList (state, { layoutList }) {
      state.layoutList = layoutList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getLayoutList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let layoutModel = new LayoutModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            layoutModel = layoutModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        layoutModel = layoutModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        layoutModel = layoutModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        layoutModel = layoutModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      layoutModel.get().then((layoutList) => {
        commit('setLayoutList', { layoutList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de layouts!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentLayout ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        LayoutModel.$find(id).then((currentLayout) => {
          commit('setCurrentLayout', { currentLayout })
          resolve(currentLayout)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze layout!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentLayout ({ commit }) {
      commit('clearCurrentLayout')
    },
    saveLayout ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const layoutModel = new LayoutModel(data)

      // Save layout.
      layoutModel.save().then((currentLayout) => {
        commit('setErrors', {})
        commit('setCurrentLayout', { currentLayout })
        if (data.id) {
          notification.success({ message: 'Layout opgeslagen.', description: 'De layout is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Layout toegevoegd.', description: 'De layout is succesvol toegevoegd.', duration: 3 })
          if (currentLayout.id) {
            router.push({ name: 'layoutEdit', params: { layoutId: currentLayout.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze layout!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze layout!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    layoutList: state => state.layoutList,
    currentLayout: state => state.currentLayout,
    loadingLayout: state => state.loading,
    layoutErrors: state => state.errors
  }
}
