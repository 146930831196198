import serviceItem from './serviceItem'
import MediaItem from '@/types/mediaItem'

export default class Question extends serviceItem {
  public media:MediaItem[] = []
  public files:MediaItem[] = []

  constructor () {
    super()
    this.files = []
    this.title = ''
    this.description = ''
    this.status = 0
    this.comments = []
  }
}
