import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import EntityModel from '@/models/EntityModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
moment.locale('nl')

Vue.use(Vuex)

export const entityStore: Module<any, any> = {
  state: {
    entityList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentEntity: {
      reference: null,
      name: null,
      project_leader_id: 0,
      executor_id: 0,
      service_manager_id: 0,
      objection_manager_id: 0,
      question_manager_id: 0,
      accountant_id: 0,
      five_percent_manager_id: 0,
      address: null,
      zipcode: null,
      city: null,
      po_box: null,
      po_box_zipcode: null,
      po_box_city: null,
      phone: null,
      email: null,
      website: null,
      bank_name: null,
      bank_iban: null,
      coc: null,
      vat: null,
      main_color: '#f38929',
      media: {
        logo: []
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentEntity (state, { currentEntity }) {
      state.currentEntity = currentEntity
    },
    clearCurrentEntity (state) {
      state.currentEntity = {
        reference: null,
        name: null,
        project_leader_id: 0,
        executor_id: 0,
        service_manager_id: 0,
        objection_manager_id: 0,
        question_manager_id: 0,
        accountant_id: 0,
        five_percent_manager_id: 0,
        address: null,
        zipcode: null,
        city: null,
        po_box: null,
        po_box_zipcode: null,
        po_box_city: null,
        phone: null,
        email: null,
        website: null,
        bank_name: null,
        bank_iban: null,
        coc: null,
        vat: null,
        main_color: '#f38929',
        media: {
          logo: []
        }
      }
    },
    setEntityList (state, { entityList }) {
      state.entityList = entityList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getEntityList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let entityModel = new EntityModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            entityModel = entityModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        entityModel = entityModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        entityModel = entityModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        entityModel = entityModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      entityModel.get().then((entityList) => {
        commit('setEntityList', { entityList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van entiteiten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentEntity ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        EntityModel.$find(id).then((currentEntity) => {
          commit('setCurrentEntity', { currentEntity })
          resolve(currentEntity)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze entiteit!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentEntity ({ commit }) {
      commit('clearCurrentEntity')
    },
    saveEntity ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const entityModel = new EntityModel(data)

      // Save entity.
      entityModel.save().then((currentEntity) => {
        commit('setErrors', {})
        commit('setCurrentEntity', { currentEntity })
        if (data.id) {
          notification.success({ message: 'Entiteit opgeslagen.', description: 'De entiteit is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Entiteit toegevoegd.', description: 'De entiteit is succesvol toegevoegd.', duration: 3 })
          if (currentEntity.id) {
            router.push({ name: 'entityEdit', params: { entityId: currentEntity.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze entiteit!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze entiteit!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    entityList: state => state.entityList,
    currentEntity: state => state.currentEntity,
    loadingEntity: state => state.loading,
    entityErrors: state => state.errors
  }
}
