























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import OldWoononOrder from '@/types/oldWoonon/oldWoononOrder'
import OldWoononOrderShowDetailsCard from '@/views/oldWoonon/order/cards/OldWoononOrderShowDetailsCard.vue'
import OldWoononOrderShowItemsCard from '@/views/oldWoonon/order/cards/OldWoononOrderShowItemsCard.vue'

@Component({
  components: { OldWoononOrderShowItemsCard, OldWoononOrderShowDetailsCard }
})

export default class OldWoononOrderShow extends Vue {
  settings = {
    routeNames: { list: 'oldWoononOrders', show: 'oldWoononOrderShow', edit: 'oldWoononOrderEdit' },
    routeParamKey: 'oldWoononOrderId',
    functionNames: { setCurrent: 'setCurrentOldWoononOrder', clearCurrent: 'clearCurrentOldWoononOrder', save: 'saveOldWoononOrder' },
    getterNames: { current: 'currentOldWoononOrder', loading: 'loadingOldWoononOrder' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('oldWoononOrderEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('customerEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined = undefined, id:number|undefined = undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : OldWoononOrder {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
