




















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Delivery from '@/types/delivery'
import moment from 'moment'
import 'moment/locale/nl'
import Uploader from '@/components/media/Uploader.vue'
import axios from 'axios'
import { notification } from 'ant-design-vue'
moment.locale('nl')

@Component({
  components: { Uploader }
})

export default class DeliveryShowGeneralChecksCard extends Vue {
  settings = {
    routeNames: { list: 'deliveries', show: 'deliveryShow', edit: 'deliveryEdit' },
    routeParamKey: 'deliveryId',
    functionNames: { setCurrent: 'setCurrentDelivery', save: 'saveDelivery' },
    getterNames: { current: 'currentDelivery', loading: 'loadingDelivery', errors: 'deliveryErrors' }
  }

  newFileUploaded (file:File, key:string, filename:string, user:{ id: number, full_name: string, email: string }) : void {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('position', key)
    formData.append('filename', filename)
    formData.append('properties', JSON.stringify({ user: { id: user.id, full_name: user.full_name, email: user.email } }))

    axios.post(process.env.VUE_APP_API_URL + '/deliveries/' + this.currentItem.id + '/signature', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  removeSignature (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/media/' + id).then(() => {
      notification.success({ message: 'Handtekening verwijderd.', description: 'De handtekening is succesvol verwijderd.', duration: 3 })
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  get currentItem () : Delivery {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
