






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import moment from 'moment/moment'
import ColumnOptions from '@/components/listview/ColumnOptions.vue'
import IssueBulkUpdater from '@/components/listview/IssueBulkUpdater.vue'
import SubscribeButton from '@/components/base/SubscribeButton.vue'
import _ from 'lodash'
import axios from 'axios'
import { ContentBlock } from '@/views/manual/ManualPage.vue'
import { Base64MediaFile } from '@/types/media'
import PageHeader from '@/components/base/PageHeader.vue'

export class Modal {
  visible = false
  title: string
  loading = false
  position = -1
  show (): void {
    this.visible = true
  }

  close (): void {
    this.visible = false
  }

  constructor (title:string) {
    this.title = title
  }
}

export class Page {
  id!: number
  title = ''
  slug!: string
  collection!: string
  position!: number
  published!: string|null
  blocks: ContentBlock[] = []
}

class PageCollection {
  id!: number|null
  title!: string
  slug!: string
  description!: string
  position!: number
  pages: Page[] = []
}

class Slugify extends Vue {
  string!: string
  constructor (string:string) {
    super()
    this.string = string
  }

  result () {
    return this.string.toLowerCase().trim()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
  }
}

@Component({
  components: { PageHeader, SubscribeButton, IssueBulkUpdater, ColumnOptions, ActiveFilters }
})

export default class ManualIndex extends Vue {
  @Prop({ default: false }) rental!:boolean
  moment = moment
  ls = new SecureLS({ isCompression: false })

  createPage (collectionId: number): void {
    this.$router.push({ name: 'manualNew', params: { collectionId: collectionId.toString() } })
  }

  collectionModal = new Modal('Nieuwe Collectie')
  collections: PageCollection[] = []

  defaultName = 'Nieuwe Collectie'
  oldCollection!: PageCollection
  newCollection: PageCollection = {
    id: null,
    description: '',
    title: this.defaultName,
    slug: '',
    position: 0,
    pages: []
  }

  editTitleId:number|null = null
  editPageTitleId:number|null = null
  oldPage: Page = new Page()

  create = false
  saving = false
  checking = false
  isInvalidCollection = false

  searchString = ''

  timer?:number
  timeout = 600

  mounted (): void {
    this.fetchPageCollections()
  }

  get allowedToEdit (): boolean {
    return this.authUser.permissions.includes('manual.edit')
  }

  highlightFilter (content:string): string {
    if (this.searchString === '') return content
    return content.replace(new RegExp(this.searchString, 'gi'), match => {
      return '<span class="highlight">' + match + '</span>'
    })
  }

  orderedPages (pages: Page[]): Page[] {
    return _.orderBy(pages, ['position', 'title'])
  }

  get orderedCollections (): PageCollection[] {
    const query = this.searchString.toLowerCase()
    const collections = [...new Set([...this.collections.filter(collection => collection.pages.some(p => p.title.toLowerCase().includes(query))), ...this.collections.filter(collection => collection.title.toLowerCase().includes(query))])]

    return _.orderBy(collections, ['position', 'title'])
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  fetchPageCollections (): void {
    axios.get(process.env.VUE_APP_API_URL + '/pages')
      .then(res => {
        if (res.data.length === 0) return
        this.collections = res.data
      })
  }

  select (e: any): void {
    e.target.setSelectionRange(0, this.newCollection.title.length)
  }

  createCollection (): void {
    this.create = true
    this.newCollection.title = this.defaultName
  }

  editCollection (collection: PageCollection): void {
    this.oldCollection = { ...collection }
    this.create = false
    this.editTitleId = collection.id
    this.newCollection = collection
  }

  deleteCollection (collection: PageCollection): void {
    axios.delete(`${process.env.VUE_APP_API_URL}/pages/collection/${collection.id}`)
      .then(res => {
        this.collections = res.data
      })
  }

  updateCollectionName (): void {
    this.editTitleId = null
    if (this.oldCollection.title === this.newCollection.title) return

    this.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/pages/collection/' + this.newCollection.id, this.newCollection)
      .then(res => {
        this.collections = res.data
      })
      .finally(() => {
        this.saving = false
      })
  }

  checkCollectionName (): void {
    this.isInvalidCollection = true
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = undefined
    }

    this.timer = setTimeout(() => {
      this.checking = true
      axios.get(process.env.VUE_APP_API_URL + '/pages/collection/' + new Slugify(this.newCollection.title).result())
        .then(res => {
          this.isInvalidCollection = res.data.length !== 0
        }).finally(() => {
          this.checking = false
        })
    }, this.timeout)
  }

  updatePageName (page: Page): void {
    this.editPageTitleId = null
    if (page.title === this.oldPage.title) return
    axios.put(`${process.env.VUE_APP_API_URL}/pages/${page.id}`, page)
  }

  deletePage (page: Page): void {
    axios.delete(`${process.env.VUE_APP_API_URL}/pages/${page.id}`)
      .then(res => { this.collections = res.data })
  }

  editPageTitle (page: Page): void {
    this.oldPage = { ...page }
    this.editPageTitleId = page.id
  }

  cancel (): void {
    this.newCollection.title = this.defaultName
    this.create = false
  }

  save (): void {
    if (this.saving) return
    this.saving = true

    axios.post(process.env.VUE_APP_API_URL + '/pages/collection', this.newCollection)
      .then(res => {
        this.collections = res.data
      })
      .finally(() => {
        this.saving = false
        this.create = false
        this.newCollection.title = this.defaultName
      })
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
