import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import TenantUserModel from '@/models/TenantUserModel'
import router from '@/router'

Vue.use(Vuex)

export const tenantUserStore: Module<any, any> = {
  state: {
    tenantUserList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentTenantUser: {
      firstname: null,
      lastname: null,
      full_name: null,
      username: null,
      password: null,
      password_confirmation: null,
      role_ids: [],
      permission_ids: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentTenantUser (state, { currentTenantUser }) {
      currentTenantUser.password = null
      currentTenantUser.password_confirmation = null
      state.currentTenantUser = currentTenantUser
    },
    clearCurrentTenantUser (state) {
      state.currentTenantUser = {
        firstname: null,
        lastname: null,
        full_name: null,
        username: null,
        password: null,
        password_confirmation: null,
        role_ids: [],
        permission_ids: []
      }
    },
    setTenantUserList (state, { tenantUserList }) {
      state.tenantUserList = tenantUserList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getTenantUserList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let tenantUserModel = new TenantUserModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            tenantUserModel = tenantUserModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        tenantUserModel = tenantUserModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        tenantUserModel = tenantUserModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        tenantUserModel = tenantUserModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      tenantUserModel.get().then((tenantUserList) => {
        commit('setTenantUserList', { tenantUserList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van huurders!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentTenantUser ({ commit }, id) {
      commit('setErrors', {})
      commit('setLoading', { type: 'item', state: true })
      TenantUserModel.$find(id).then((currentTenantUser) => {
        commit('setCurrentTenantUser', { currentTenantUser })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van deze huurder!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'item', state: false })
      })
    },
    clearCurrentTenantUser ({ commit }) {
      commit('clearCurrentTenantUser')
    },
    saveTenantUser ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const tenantUserModel = new TenantUserModel(data)

      // Save user.
      tenantUserModel.save().then((currentTenantUser) => {
        commit('setErrors', {})
        commit('setCurrentTenantUser', { currentTenantUser })
        if (data.id) {
          notification.success({ message: 'Huurder opgeslagen.', description: 'De huurder is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Huurder toegevoegd.', description: 'De huurder is succesvol toegevoegd.', duration: 3 })
          if (currentTenantUser.id) {
            router.push({ name: 'tenantUserEdit', params: { tenantUserId: currentTenantUser.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze huurder!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze huurder!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    tenantUserList: state => state.tenantUserList,
    currentTenantUser: state => state.currentTenantUser,
    loadingTenantUser: state => state.loading,
    tenantUserErrors: state => state.errors
  }
}
