


















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import User from '@/types/user'
import Company from '@/types/company'

@Component({
  components: {}
})

export default class IssueBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]

  @Watch('bulkUpdate.solver_company_id')
  onSolverCompanyChanged () : void {
    this.getSolvers()
  }

  saving = false
  downloading = {
    single: false,
    collection: false
  }

  downloadOptionsModalVisible = false
  downloadTypes: string[] = []

  employees:User[] = []
  companies:Company[] = []
  solvers:User[] = []
  defaultValues:Record<string, (string|number|null)> = {
    manager_id: null,
    solver_company_id: null,
    solver_id: null,
    status: null
  }

  bulkUpdate:Record<string, (string|number|null)> = this.defaultValues

  mounted () : void {
    this.getEmployees()
    this.getCompanies()
  }

  getEmployees () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.display_name]=Medewerker&limit=2500').then((res) => {
      this.employees = res.data.data
    })
  }

  getCompanies () : void {
    axios.get(process.env.VUE_APP_API_URL + '/companies?sort=name&limit=2500').then((res) => {
      this.companies = res.data.data
    })
  }

  getSolvers () : void {
    axios.get(`${process.env.VUE_APP_API_URL}/companies/${this.bulkUpdate.solver_company_id}/solvers`).then((res) => {
      this.solvers = res.data.data
    })
  }

  update () : void {
    this.saving = true
    const data:Record<string, (string|number|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null) {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/issues/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        notification.success({ message: 'Servicemeldingen bijgewerkt.', description: 'De servicemeldingen zijn succesvol opgeslagen.', duration: 3 })
        this.bulkUpdate = this.defaultValues
      }).finally(() => {
        this.saving = false
      })
    }
  }

  downloadIssuePdfs () : void {
    this.downloading.single = true
    const payload = {
      types: this.downloadTypes
    }
    this.selectedIds.forEach((id:number) => {
      axios.post(process.env.VUE_APP_API_URL + '/issues/' + id + '/export', payload, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Export_Servicemelding_' + id + '.pdf'
          link.click()
        }).finally(() => {
          this.downloading.single = false
        })
    })
  }

  downloadIssueCollectionPdf () : void {
    this.downloading.collection = true
    axios.post(process.env.VUE_APP_API_URL + '/issues/collection/export', { selectedIds: this.selectedIds }, { responseType: 'arraybuffer' }).then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Servicemeldingen_Overzicht.pdf'
      link.click()
    }).finally(() => {
      this.downloading.collection = false
    })
  }

  get canSelectSolver (): boolean {
    return this.bulkUpdate.solver_company_id !== null && this.solvers.length > 0
  }

  get canUpdate (): boolean {
    return (this.bulkUpdate.solver_company_id !== null && this.bulkUpdate.solver_id !== null) ||
    this.bulkUpdate.manager_id !== null ||
    this.bulkUpdate.status !== null
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
