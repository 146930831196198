













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import { Base64MediaFile, getBase64Async } from '@/types/media'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import { parseInt } from 'lodash'
import { EventMessage } from '@/views/communication/events/EventMessageOverview.vue'

@Component({
  components: { PreviewContainer, SimpleUploader }
})
export default class EventMessageModal extends Vue {
  @Prop({ required: true }) triggerId!: number
  @Prop({ required: false }) messageId!: number|null
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: () => [] }) categories!: string[]
  @Prop({ default: () => [] }) types!: string[]
  @Prop({ default: () => [] }) targets!: string[]

  formData: EventMessage = new EventMessage()
  previewFiles:Base64MediaFile[] = []
  selectedTargets: string[] = []

  @Watch('visible')
  onVisibleChange (val: boolean): void {
    this.modalVisible = val
    if (val) {
      if (this.messageId) {
        this.getEventMessage()
      }
    }
    this.formData = new EventMessage()
  }

  modalVisible = false
  get isVisible (): boolean {
    return this.visible && this.modalVisible
  }

  getEventMessage (): void {
    axios.get(process.env.VUE_APP_API_URL + '/event-messages/' + this.messageId)
      .then((response) => {
        this.formData = response.data
        this.previewFiles = response.data.preview
        this.selectedTargets = this.formData.targets.split(',').map((target:string) => this.targets[parseInt(target)])
      })
  }

  resetForm (updated: boolean): void {
    this.formData = new EventMessage()
    this.previewFiles = []
    this.selectedTargets = []
    this.modalVisible = false
    this.$emit('closed', updated)
  }

  capitalize (s: string) : string {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  async handleMedia (file: File): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await getBase64Async(file)
    previewFile.name = file.name.replace(/\.[^/.]+$/, '')
    this.previewFiles = [previewFile]
    this.formData.preview = file
  }

  deleteFile (): void {
    this.formData.preview = null
    this.previewFiles = []
  }

  get canSave (): boolean {
    return this.formData.name !== '' &&
    this.formData.type >= 0 &&
    this.selectedTargets.length > 0 &&
    this.formData.preview !== null
  }

  get title (): string {
    return this.messageId ? 'Bewerk bericht' : 'Nieuw bericht'
  }

  get loaded (): boolean {
    return this.messageId ? this.formData.id !== null : true
  }

  getFormData (): FormData {
    const formData = new FormData()
    formData.append('trigger_id', this.triggerId.toString())
    formData.append('type', this.formData.type.toString())
    formData.append('name', this.formData.name)
    formData.append('targets', this.selectedTargets.map((target:string) => {
      return isNaN(Number(target)) ? this.targets.indexOf(target).toString() : target
    }).join(','))

    if (this.formData.preview) {
      formData.append('files[]', this.formData.preview)
    }
    return formData
  }

  save (): void {
    const formData = this.getFormData()
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    axios.post(process.env.VUE_APP_API_URL + '/event-messages', formData, { headers: headers })
      .then(() => {
        this.resetForm(true)
      })
  }

  update (): void {
    const formData = this.getFormData()
    formData.append('_method', 'PUT')

    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    let wasUpdated = false
    axios.post(process.env.VUE_APP_API_URL + '/event-messages/' + this.messageId, formData, { headers: headers })
      .then(() => {
        wasUpdated = true
      }).catch(() => {
        wasUpdated = false
      })
      .finally(() => {
        this.resetForm(wasUpdated)
      })
  }
}
