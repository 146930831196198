











import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Tenant from '@/types/tenant'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class TenantShowDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'tenants', show: 'tenantShow', edit: 'tenantEdit' },
    routeParamKey: 'tenantId',
    functionNames: { setCurrent: 'setCurrentTenant', save: 'saveTenant' },
    getterNames: { current: 'currentTenant', loading: 'loadingTenant', errors: 'tenantErrors' }
  }

  columns:Column[] = [
    {
      title: 'Naam',
      key: 'full_name',
      dataIndex: 'full_name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'E-mailadres',
      key: 'email',
      dataIndex: 'email',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Telefoon',
      key: 'phone',
      dataIndex: 'phone',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    }
  ]

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('tenants.users.view')) {
            const route = this.$router.resolve({ name: 'tenantUserShow', params: { tenantUserId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Tenant {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
