













































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import UnityWebGL from 'unity-webgl'
import VueUnity from 'unity-webgl/vue'

@Component({
  components: {
    UnityWebGL,
    VueUnity
  }
})

export default class UnityViewer extends Vue {
  @Prop({ required: true }) buildingId!: number
  $refs!: {
    unityViewer: any
  }

  viewer: any
  webgl: any
  floating = false
  buttonSize = 'small'
  showDirectionControls = false
  showOnOffControls = false
  cameraControlsActive = false
  optionControlsActive = false
  floorControlsActive = false

  unityContext = new UnityWebGL({
    loaderUrl: '/viewer/Build/UnityWeb2022.loader.js',
    dataUrl: '/viewer/Build/UnityWeb2022.data',
    frameworkUrl: '/viewer/Build/UnityWeb2022.framework.js',
    codeUrl: '/viewer/Build/UnityWeb2022.wasm'
  })

  mounted (): void {
    this.catchViewer()
    this.storeBuildingId()
    this.storeEnvironment()
  }

  storeBuildingId (): void {
    if (this.buildingId === undefined) return
    localStorage.setItem('viewer-building-id', this.buildingId.toString())
  }

  storeEnvironment () : void {
    if (process.env.VUE_APP_API_URL === 'https://api.woonon.nl') {
      localStorage.setItem('viewer-mode', 'l')
    } else {
      localStorage.setItem('viewer-mode', 's')
    }
  }

  catchViewer (): void {
    this.viewer = this.$refs.unityViewer
  }

  fullscreen (): void {
    this.unityContext.setFullscreen(true)
  }

  FrontOrBack (front: string): void {
    this.sendCommand('FrontOrBack', front)
  }

  InOrOutside (InOrOutside: string): void {
    this.sendCommand('InOrOutside', InOrOutside)
  }

  FloorUpOrDown (upOrDown: string): void {
    this.sendCommand('FloorUpOrDown', upOrDown)
  }

  MoveCameraUpOrDown (upOrDown: string): void {
    this.sendCommand('MoveCameraUpOrDown', upOrDown)
  }

  CycleModifiedDict (nextOrPrevious: number): void {
    this.sendCommand('CycleModifiedDict', nextOrPrevious)
  }

  Zoom (range: number): void {
    this.sendCommand('Zoom', range)
  }

  ToggleTopDown (): void {
    this.sendCommand('ToggleTopDown', null)
  }

  ToggleIsometric (): void {
    this.sendCommand('ToggleIsometric', null)
  }

  ToggleWalls (): void {
    this.sendCommand('ToggleWalls', 1)
  }

  ToggleFreeLook (): void {
    this.sendCommand('ToggleFreeLook', null)
  }

  ToggleFurniture (): void {
    this.sendCommand('ToggleFurniture', null)
  }

  SetCameraCardinalOfRoom (Cardinal: string): void {
    this.sendCommand('SetCameraCardinalOfRoom', Cardinal)
  }

  ResetView (): void {
    this.sendCommand('ResetView', null)
  }

  OpenOrCloseDoors (): void {
    this.sendCommand('OpenOrCloseDoors', null)
  }

  ToggleFakeHouses (): void {
    this.sendCommand('ToggleFakeHouses', null)
  }
  // #endregion

  sendCommand (method: string, params: string | number | null): void {
    if (params === null) {
      this.$refs.unityViewer.message('VueHookLight', method)
    } else {
      this.$refs.unityViewer.message('VueHookLight', method, params)
    }
  }
}
