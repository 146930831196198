
















































































































































































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Buildingblock from '@/types/buildingblock'
import axios from 'axios'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import AfasField from '@/components/afas/AfasField.vue'

@Component({
  components: { AfasField, Filelist, Uploader }
})

export default class BuildingEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding', errors: 'projectErrors' }
  }

  buildingBlocks:Buildingblock[] = []

  mounted () : void {
    if (this.currentItem.project_id) {
      this.getBuildingBlocks()
    }
  }

  getBuildingBlocks () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildingblocks?filter[project_id]=' + this.currentItem.project_id).then((res) => {
      this.buildingBlocks = res.data.data
    })
  }

  demoAvailableChanged (state:boolean) : void {
    this.$store.dispatch('demoAvailableChanged', state)
  }

  externalChanged (state:boolean) : void {
    this.$store.dispatch('externalChanged', state)
  }

  unityEnabledChanged (state:boolean) : void {
    this.$store.dispatch('unityEnabledChanged', state)
  }

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  reloadBuilding () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get isAfasBuilding () : boolean {
    return this.currentItem.in_afas
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
