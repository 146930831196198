























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Option from '@/types/option'
import User from '@/types/user'
import Column from '@/types/column'
import { units } from '@/globalValues'

@Component({
  components: {}
})

export default class OptionEditBudgetsTab extends Vue {
  settings = {
    routeNames: { list: 'options', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    functionNames: { setCurrent: 'setCurrentOption', save: 'saveOption' },
    getterNames: { current: 'currentOption', loading: 'loadingOption', errors: 'optionErrors' }
  }

  columns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Aantal',
      key: 'qty',
      dataIndex: 'qty',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Eenheid',
      key: 'unit',
      scopedSlots: { customRender: 'unit' }
    },
    {
      title: 'Eenheidsprijs',
      key: 'unit_price',
      scopedSlots: { customRender: 'unit_price' }
    },
    {
      title: 'Totaalprijs',
      key: 'total_price',
      scopedSlots: { customRender: 'total_price' }
    }
  ]

  get currentItem () : Option {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get units () : { name:string, value:string }[] {
    return units
  }
}
