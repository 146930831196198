
























import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class DevToolAfasBuildings extends Vue {
  loading = { projects: false, buildings: false }
  saving = false

  projects = []
  projectId = null

  buildings = []
  buildingId = null

  mounted () : void {
    this.getProjects()
  }

  getProjects () : void {
    this.loading.projects = true

    axios.get(process.env.VUE_APP_API_URL + '/projects?filter[in_afas]=1&sort=name&limit=1000').then((r) => {
      this.projects = r.data.data
    }).finally(() => {
      this.loading.projects = false
    })
  }

  getBuildings () : void {
    this.loading.buildings = true
    this.resetBuilding()

    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project.id]=' + this.projectId + '&filter[in_afas]=0&sort=number&limit=1000').then((r) => {
      this.buildings = r.data.data
    }).finally(() => {
      this.loading.buildings = false
    })
  }

  resetBuilding () : void {
    this.buildings = []
    this.buildingId = null
  }

  pushBuildingToAfas () : void {
    if (this.requiredFilled) {
      this.saving = true
      axios.post(process.env.VUE_APP_API_URL + '/devtools/afas/buildings/' + this.buildingId + '/push').then(() => {
        this.getBuildings()
        notification.success({
          message: 'Bouwnummer gepushed.',
          description: 'Het bouwnummer is succesvol naar AFAS gepushed.',
          duration: 3
        })
      }).catch(() => {
        notification.error({
          message: 'Bouwnummer NIET gepushed.',
          description: 'Er is iets mis gegaan. Probeer het later nog eens.'
        })
      }).finally(() => {
        this.saving = false
      })
    }
  }

  filterItems (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get requiredFilled () : boolean {
    return !!(this.projectId && this.buildingId)
  }
}
