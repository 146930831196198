
























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import GuardCode from '@/types/guardCode'
import { units } from '@/globalValues'

@Component({
  components: {}
})

export default class GuardCodeShowDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'guardCodes', show: 'guardCodeShow', edit: 'guardCodeEdit' },
    routeParamKey: 'guardCodeId',
    functionNames: { setCurrent: 'setCurrentGuardCode', save: 'saveGuardCode' },
    getterNames: { current: 'currentGuardCode', loading: 'loadingGuardCode', errors: 'guardCodeErrors' }
  }

  get currentItem () : GuardCode {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get units () : { name:string, value:string }[] {
    return units
  }
}
