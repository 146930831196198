



























































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Survey from '@/types/survey'
import SurveyEditResultMedia from '@/views/survey/components/SurveyEditResultMedia.vue'
import axios from 'axios'
import SurveyShowDetailsTab from '@/views/survey/tabs/show/SurveyShowDetailsTab.vue'

@Component({
  components: { SurveyShowDetailsTab, SurveyEditResultMedia }
})

export default class SurveyShow extends Vue {
  settings = {
    routeNames: { list: 'surveys', show: 'surveyShow', edit: 'surveyEdit' },
    routeParamKey: 'surveyId',
    functionNames: { setCurrent: 'setCurrentSurvey', clearCurrent: 'clearCurrentSurvey', save: 'saveSurvey' },
    getterNames: { current: 'currentSurvey', loading: 'loadingSurvey' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('surveyShowActiveTab') || 'details'
  downloading = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  downloadSurveyPdf () : void {
    this.downloading = true
    axios.get(process.env.VUE_APP_API_URL + '/surveys/' + this.currentItem.id + '/pdf', { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Resultaten_Keuring_' + this.currentItem.id + '.pdf'
        link.click()
      }).finally(() => {
        this.downloading = false
      })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('surveyShowActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Survey {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
