











import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Element from '@/types/element'
import Column from '@/types/column'
import User from '@/types/user'

@Component({
  components: {}
})

export default class ElementShowSubelementsTab extends Vue {
  settings = {
    routeNames: { list: 'elements', show: 'elementShow', edit: 'elementEdit' },
    routeParamKey: 'elementId',
    functionNames: { setCurrent: 'setCurrentElement', save: 'saveElement' },
    getterNames: { current: 'currentElement', loading: 'loadingElement', errors: 'elementErrors' }
  }

  columns:Column[] = [
    {
      title: 'Referentie',
      key: 'reference',
      dataIndex: 'reference',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    }
  ]

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('subelements.view')) {
            const route = this.$router.resolve({ name: 'subelementShow', params: { subelementId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Element {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
