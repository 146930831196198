






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'

@Component({
  components: {}
})

export default class SearchWidget extends Vue {
  @Prop({ type: Boolean }) fullWidth!:boolean

  searchTerm = ''
  results:Record<string, (string|number)[]> = {}
  showResults = false
  searchTimer = 0
  loading = false

  @Watch('searchTerm')
  onSearchTermChange () : void {
    if (this.searchTerm.length <= 3) {
      this.results = {}
      this.showResults = false
    }
  }

  handleSearch () : void {
    if (this.searchTerm.length >= 3) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.loading = true
        axios.post(process.env.VUE_APP_API_URL + '/search', { searchTerm: this.searchTerm }).then((res) => {
          this.results = res.data
          this.showResults = true
        }).finally(() => {
          this.loading = false
        })
      }, 500)
    }
  }

  getModelName (model:string) : string {
    switch (model) {
      case 'projects':
        return 'Projecten'
      case 'buildings':
        return 'Bouwnummers'
      case 'customers':
        return 'Klanten'
      case 'options':
        return 'Opties'
      case 'orders':
        return 'Orders'
      case 'issues':
        return 'Servicemeldingen'
      default:
        return 'Overig'
    }
  }

  getResultText (model:string, result:any) : string {
    switch (model) {
      case 'projects':
        return `${result.name} (${result.reference})`
      case 'buildings':
        return `Bouwnummer ${result.number} (${result.reference})`
      case 'customers':
        return `${result.name}`
      case 'options':
        return `${result.external_ref} - ${result.name}`
      case 'orders':
        return `Order #${result.id}`
      case 'issues':
        return `Servicemelding #${result.id} - ${result.title}`
      default:
        return `${result.id}`
    }
  }

  goToItem (model:string, id:number) : void {
    this.showResults = false
    switch (model) {
      case 'projects':
        window.open(this.$router.resolve({ name: 'projectShow', params: { projectId: id.toString() } }).href, '_blank')
        break
      case 'buildings':
        window.open(this.$router.resolve({ name: 'buildingShow', params: { buildingId: id.toString() } }).href, '_blank')
        break
      case 'customers':
        window.open(this.$router.resolve({ name: 'customerShow', params: { customerId: id.toString() } }).href, '_blank')
        break
      case 'options':
        window.open(this.$router.resolve({ name: 'optionShow', params: { optionId: id.toString() } }).href, '_blank')
        break
      case 'orders':
        window.open(this.$router.resolve({ name: 'orderShow', params: { orderId: id.toString() } }).href, '_blank')
        break
      case 'issues':
        window.open(this.$router.resolve({ name: 'issueEdit', params: { issueId: id.toString() } }).href, '_blank')
        break
      default:
        break
    }
  }
}
