















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import axios from 'axios'
import Column from '@/types/column'
import Activity from '@/types/activity'

@Component({
  components: {}
})

export default class UserEditActivityTab extends Vue {
  columns:Column[] = [
    {
      title: 'Op',
      key: 'created_at',
      dataIndex: 'created_at',
      scopedSlots: { customRender: 'createdAt' }
    },
    {
      title: 'Actie',
      key: 'event',
      dataIndex: 'event'
    },
    {
      title: 'Type',
      key: 'model',
      dataIndex: 'model'
    },
    {
      title: 'Oude waardes',
      key: 'properties.old',
      dataIndex: 'properties.old',
      scopedSlots: { customRender: 'oldProperties' }
    },
    {
      title: 'Nieuwe waardes',
      key: 'properties.attributes',
      dataIndex: 'properties.attributes',
      scopedSlots: { customRender: 'newProperties' }
    }
  ]

  activities:Activity[] = []

  mounted () : void {
    this.getUserActivity()
  }

  getUserActivity () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users/' + this.$route.params.userId + '/activity').then((res) => {
      this.activities = res.data.data
    })
  }
}
