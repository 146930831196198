







































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Objection from '@/types/objection'
import Customer from '@/types/customer'
import Building from '@/types/building'
import Project from '@/types/project'
import ServiceComments from '@/views/service/comments/ServiceComments.vue'
import ServiceCommentBlock from '@/views/service/comments/ServiceCommentBlock.vue'
import ServiceComment from '@/types/serviceComment'
import RelatedServiceItems from '@/views/service/RelatedServiceItems.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import Filelist from '@/components/media/Filelist.vue'
import ServiceObjectInfo from '@/views/service/ServiceObjectInfo.vue'
import ServiceBuildingSelector from '@/views/service/ServiceBuildingSelector.vue'
import storeSettings from '@/types/storeSettings'
import axios from 'axios'
import ServiceDetails from '@/views/service/ServiceDetails.vue'
import ServiceContactInfo from '@/views/service/ServiceContactInfo.vue'
import BuildingModel from '@/models/BuildingModel'
import PrioritySelector from '@/views/service/components/PrioritySelector.vue'

@Component({
  components: {
    PrioritySelector,
    ServiceContactInfo,
    ServiceDetails,
    ServiceBuildingSelector,
    ServiceObjectInfo,
    Filelist,
    ActivityLogTable,
    RelatedServiceItems,
    ServiceCommentBlock,
    ServiceComments,
    ServiceCreateComment: ServiceComments
  }
})

export default class ObjectionCreate extends Vue {
  ls = new SecureLS({ isCompression: false })
  config = {}

  settings: storeSettings = {
    routeNames: { list: 'objections', show: 'objectionShow', edit: 'objectionEdit' },
    routeParamKey: 'objectionId',
    permissionNames: { show: 'objections.view', edit: 'objections.edit' },
    functionNames: { getFilters: 'getObjectionFilters', getList: 'getObjectionList', setCurrent: 'setCurrentObjection', clearCurrent: 'clearCurrentObjection', save: 'saveObjection' },
    getterNames: { current: 'currentObjection', loading: 'loadingObjection', errors: 'objectionErrors', list: 'list', filters: 'objectionFilters' }
  }

  tenantInfoEnabled = false
  selectedStatus = 0
  documentsGroups = []

  mounted ():void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  get customer () : Customer {
    return this.currentItem.building?.customer || new Customer()
  }

  get building () : Building {
    return this.currentItem.building || new Building()
  }

  get project () : Project {
    return this.currentItem.project || new Project()
  }

  get comments () : ServiceComment[] {
    return this.currentItem.comments || [new ServiceComment()]
  }

  isRental (state: number): boolean {
    const rentalStates:number[] = [5, 6, 7]
    return rentalStates.includes(state)
  }

  onBuildingSelected (id: number): void {
    this.getDocuments()
    this.$store.dispatch('setObjectionBuildingId', id)

    BuildingModel.where('id', id).first().then(building => {
      this.$store.dispatch('setObjectionBuilding', building)
    }).finally(() => {
      this.tenantInfoEnabled = this.isRental(this.currentItem.building.state)
    })
  }

  onProjectSelected (id: number): void {
    this.$store.dispatch('setObjectionProject', id)
  }

  onManagerSelected (id: number): void {
    this.$store.dispatch('setObjectionManager', id)
  }

  save () : void {
    this.currentItem.user_id = this.authUser.id
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  getDocuments ():void {
    axios.get(`${process.env.VUE_APP_API_URL}/objections/documents/${this.currentItem.building_id}`)
      .then(res => {
        this.documentsGroups = res.data
      })
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Objection {
    const current = this.$store.getters[this.settings.getterNames.current]
    this.selectedStatus = current.status.id
    return current
  }

  get authUser () : User {
    return this.ls.get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
