































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { EventTrigger } from '@/views/communication/events/EventTriggerOverview.vue'
import EventMessageOverview, { EventMessage } from '@/views/communication/events/EventMessageOverview.vue'
import axios from 'axios'

@Component({
  components: { EventMessageOverview }
})
export default class EventTriggerModal extends Vue {
  @Prop({ default: false, required: true }) visible!: boolean
  @Prop({ default: null, required: false, type: EventTrigger }) trigger!: EventTrigger
  @Prop({ default: Array }) messages!: EventMessage[]
  @Prop({ default: Array }) types!: string[]
  @Prop({ default: Array }) targets!: string[]

  @Watch('visible')
  onVisibleChange (visible: boolean): void {
    this.modalVisible = visible
    if (visible) {
      this.eventData = this.trigger || new EventTrigger()
    }
  }

  eventData: EventTrigger = new EventTrigger()
  modalVisible = false

  get isVisible (): boolean {
    return this.visible && this.modalVisible
  }

  get isUpdate (): boolean {
    return this.trigger.id !== null
  }

  get title (): string {
    return `Trigger ${this.isUpdate ? 'bijwerken' : 'toevoegen'}`
  }

  get canEdit (): boolean {
    return true
  }

  get canSave (): boolean {
    return this.eventData.description !== '' &&
        this.eventData.name !== ''
  }

  save (): void {
    const payload = this.getPayload()
    axios.post(process.env.VUE_APP_API_URL + '/event-triggers', payload)
      .then(() => {
        this.resetModal(true)
      })
  }

  update (): void {
    const payload = this.getPayload()
    axios.put(process.env.VUE_APP_API_URL + '/event-triggers/' + this.trigger.id, payload)
      .then(() => {
        this.resetModal(true)
      })
  }

  getPayload (): { name: string, description: string } {
    return {
      name: this.eventData.name,
      description: this.eventData.description
    }
  }

  resetModal (changed: boolean): void {
    this.eventData = new EventTrigger()
    this.$emit('closed', changed)
  }
}
