






























































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Feedback, { FeedbackClasses, FeedbackStates, SortedFeedbackStates, SortedLabels } from '@/types/feedback'
import User from '@/types/user'
import Column from '@/types/column'
import TableSettings from '@/types/tableSettings'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Pagination from '@/types/pagination'
import axios from 'axios'
import FeedbackBulkUpdater from '@/components/listview/FeedbackBulkUpdater.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import { Status } from '@/types/serviceItem'
import ColumnOptions from '@/components/listview/ColumnOptions.vue'
import FeedbackTasks from '@/views/feedback/FeedbackTasks.vue'

@Component({
  components: { FeedbackTasks, ColumnOptions, PageHeader, FeedbackBulkUpdater, ActiveFilters }
})

export default class Feedbacks extends Vue {
  ls = new SecureLS({ isCompression: false })
  settings = {
    routeNames: { create: 'feedbackCreate', show: 'feedbackShow', edit: 'feedbackEdit' },
    routeParamKey: 'feedbackId',
    permissionNames: { show: 'feedbacks.view', edit: 'feedbacks.edit', delete: 'feedbacks.delete' },
    functionNames: { getList: 'getFeedbackList' },
    getterNames: { list: 'feedbackList', loading: 'loadingFeedback' },
    localStorageName: 'feedbackTableSettings',
    inactiveColumns: 'InactiveFeedbackColumns'
  }

  sortedLabels = SortedLabels
  sortedStates = SortedFeedbackStates
  sortOrder = { column: 'id', order: 'descend' }
  conditions = {
    status: [FeedbackStates.Afgerond, FeedbackStates.Afgewezen]
  }

  tableSettings:TableSettings = this.ls.get(this.settings.localStorageName) || new TableSettings(this.conditions, this.sortOrder)

  columns:Column[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Prio',
      key: 'rank',
      dataIndex: 'rank',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Onderwerp',
      key: 'title',
      dataIndex: 'title',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Gemeld door',
      key: 'reported_by_user.id',
      dataIndex: 'reported_by_user.full_name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Gemeld op',
      key: 'created_at',
      scopedSlots: { customRender: 'created_at' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Toegewezen aan',
      key: 'assigned_to_user_id',
      dataIndex: 'assigned_to_user',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      scopedSlots: { customRender: 'assignee' }
    },
    {
      title: 'Categorie',
      key: 'category_id',
      dataIndex: 'category.name',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Labels',
      key: 'labels',
      dataIndex: 'labels',
      filters: this.sortedLabels.map(label => { return { text: label.text, value: label.id } }),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      scopedSlots: { customRender: 'labels' }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' },
      filters: this.sortedStates.map((state:Status) => { return { text: state.name, value: state.id } }),
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  selectedRowKeys:number[] = []
  filtersLoaded = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    this.getFilters()
    this.onTableSettingsChange()
  }

  getRowClassName (record: Record<string, unknown>): string {
    const classStringArray = []

    if (!('tasks' in record && (this.canEdit || this.canView))) {
      classStringArray.push('no-expand-icon')
    }
    if ('read' in record) {
      classStringArray.push(record.read ? 'read' : 'unread')
    }
    return classStringArray.join(' ')
  }

  reloadList () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  async getFilters () : Promise<void> {
    await this.getReportedByUserFilter()
    await this.getAssignedToUserFilter()
    await this.getCategories()
    this.filtersLoaded = true
  }

  getReportedByUserFilter () : void {
    axios.post(process.env.VUE_APP_API_URL + '/filters/user/reported_feedbacks', { textColumn: 'full_name', valueColumn: 'id' }).then((res) => {
      this.columns[3].filters = res.data
    })
  }

  getAssignedToUserFilter () : void {
    // Add not assigned to filter
    axios.post(process.env.VUE_APP_API_URL + '/filters/user/assigned_feedbacks', { textColumn: 'full_name', valueColumn: 'id' }).then((res) => {
      this.columns[5].filters = this.columns[5].filters = [{ text: 'Niet toegewezen', value: '0' }, ...res.data]
    })
  }

  getCategories () : void {
    axios.get(process.env.VUE_APP_API_URL + '/feedbacks/categories').then((res) => {
      this.columns[6].filters = res.data.map((cat: { id:number, name:string, slug: string }) => {
        return { text: cat.name, value: cat.id }
      }).sort((a: { text: string }, b: { text: string }) => { return a.text.localeCompare(b.text) })
    })
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  // Required for ActiveFilters component
  updateFilters (filters: {[key: string]: number[]|string[]|boolean[]}) : void {
    const { columns } = this

    columns.forEach((column:Column) => {
      column.filteredValue = column.key in filters ? filters[column.key] : []
    })

    this.tableSettings.activeFilters = Object.assign({}, filters)
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          if (!this.selectedRowKeys.length) {
            const keyboard = window.event as KeyboardEvent
            if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
              const route = this.$router.resolve({ name: this.settings.routeNames.show, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
              window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
            }
          } else {
            this.selectedRowKeys.includes(rowData.id) ? this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(rowData.id), 1) : this.selectedRowKeys.push(rowData.id)
          }
        }
      }
    }
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes('feedbacks.edit')
  }

  get canView (): boolean {
    return this.authUser.permissions.includes('feedbacks.view')
  }

  // ColumnOptions functions START
  get activeColumns (): Column[] {
    return this.columns.filter(col => !this.$store.getters[this.settings.inactiveColumns].includes(col.key))
  }

  onSelectChange (selectedRowKeys:number[]) : void {
    this.selectedRowKeys = selectedRowKeys
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  toggleInactiveItems (checked:boolean): void {
    this.tableSettings.archived = checked
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  getStatus (id: number):Status {
    return FeedbackClasses.filter(s => s.id === id)[0]
  }

  getLabel (id: number): {id:number, text:string, class: string} {
    return SortedLabels.filter(s => s.id === id)[0]
  }

  get itemList () : { data: Feedback[], meta: { per_page:string, total:number } } {
    return this.$store.getters[this.settings.getterNames.list]
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: parseInt(this.itemList.meta.per_page),
      total: this.itemList.meta.total,
      showTotal: (total, range) => `${this.getNoun(total)} ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  getNoun (total: string): string {
    return total === '1' ? 'Feedback' : 'Feedbacks'
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].list
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set(this.settings.localStorageName, this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: this.settings.localStorageName,
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
