

























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import GuardCode from '@/types/guardCode'
import GuardCodeShowDetailsTab from '@/views/guardCode/tabs/show/GuardCodeShowDetailsTab.vue'

@Component({
  components: { GuardCodeShowDetailsTab }
})

export default class GuardCodeShow extends Vue {
  settings = {
    routeNames: { list: 'guardCodes', show: 'guardCodeShow', edit: 'guardCodeEdit' },
    routeParamKey: 'guardCodeId',
    functionNames: { setCurrent: 'setCurrentGuardCode', clearCurrent: 'clearCurrentGuardCode', save: 'saveGuardCode' },
    getterNames: { current: 'currentGuardCode', loading: 'loadingGuardCode' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('guardCodeEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('guardCodeEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : GuardCode {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
