




































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Feedback, { FeedbackClasses, FeedbackStates } from '@/types/feedback'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import UploadQueue from '@/components/media/UploadQueue.vue'
import Release, { PageModes } from '@/types/release'
import { Status } from '@/types/serviceItem'
import { notification } from 'ant-design-vue'
import ReleaseModel from '@/models/ReleaseModel'
import FeedbackModel from '@/models/FeedbackModel'
import { isEmpty } from 'lodash'
import axios from 'axios'

@Component({
  components: { UploadQueue, Uploader, TextEditor }
})

export default class ReleaseDetails extends Vue {
  @Prop({ default: PageModes.create }) mode!: string
  @Prop({ default: false, type: Boolean }) latest!: boolean

  currentMode = PageModes.create
  release = new Release()
  type = 'release'
  selectedFeedbackId = 0
  loadingFeedbacks = false
  saving = false
  publishing = false
  loading = false

  feedbacks: Feedback[] = []
  logSections = {
    general: 'Algemeen',
    api: 'API',
    admin: 'Admin',
    portal: 'Kopers Portaal',
    service: 'Service App',
    survey: 'Keurings App',
    construction_site: 'Bouwplaats App'
  }

  orderedSections : { key: string, header: string }[] = [
    { key: 'general', header: 'Algemeen' },
    { key: 'api', header: 'API' },
    { key: 'admin', header: 'Admin' },
    { key: 'portal', header: 'Kopers Portaal' },
    { key: 'service', header: 'Service App' },
    { key: 'survey', header: 'Keurings App' },
    { key: 'construction_site', header: 'Bouwplaats App' }
  ]

  activeLogSections: string[] = ['general']

  mounted () : void {
    this.currentMode = this.mode
    this.getFeedbacks()

    if (this.currentMode !== PageModes.create) {
      this.getRelease()
    }
  }

  get page () : { title: string, subtitle: string } {
    let title = 'Release ' + this.release.id
    let subtitle = 'Bekijk en bewerk de details van de release'

    switch (this.currentMode) {
      case PageModes.create:
        title = 'Nieuwe release'
        subtitle = 'Maak een nieuwe release aan'
        break
      case PageModes.edit:
        subtitle = `Release ${this.release.id} bewerken`
        break
      case PageModes.view:
        subtitle = `Release ${this.release.id} bekijken`
        break
    }

    return { title, subtitle }
  }

  get availableFeedbacks (): Feedback[] {
    return this.feedbacks.filter((feedback: Feedback) => !this.release.feedbacks.map((f: Feedback) => f.id).includes(feedback.id))
  }

  get validFeedbacks (): Feedback[] {
    const validStatus = [FeedbackStates.Akkoord]
    const feedbacks = this.availableFeedbacks.filter(f => validStatus.includes(f.status))
    if (this.release.feedbacks) {
      return feedbacks.filter(f => !this.release.feedbacks.map(f => f.id).includes(f.key))
    } else {
      return feedbacks
    }
  }

  get validFeedbacksCount (): number {
    return this.validFeedbacks.length
  }

  get canSave (): boolean {
    return !isEmpty(this.release.version) && this.canEdit
  }

  get isPublished (): boolean {
    return !isEmpty(this.release.released_at)
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes('releases.edit')
  }

  get editable (): boolean {
    return (this.currentMode === PageModes.edit || this.currentMode === PageModes.create)
  }

  get sortedFeedbacks (): Feedback[] {
    // return sorted release feedbacks by id
    return this.release.feedbacks.sort((a: Feedback, b: Feedback) => a.id - b.id)
  }

  getAllLogSectionKeysWithContent () : string[] {
    // return all log section keys that have content, else return the first one
    return Object.keys(this.logSections).filter((key:string) => this.release.logs[key] && this.release.logs[key].length > 0) || Object.keys(this.logSections)[0]
  }

  enableEditing () : void {
    this.$router.push({ name: 'releaseEdit', params: { releaseId: this.release.id.toString() } })
    this.currentMode = PageModes.edit
  }

  addValidFeedbacks () : void {
    this.validFeedbacks.forEach(feedback => this.addFeedback(feedback))
  }

  addFeedback (feedback : Feedback) : void {
    this.release.feedbacks.push(feedback)
    this.selectedFeedbackId = 0
  }

  removeFeedback (id: number) : void {
    this.release.feedbacks = this.release.feedbacks.filter(fid => fid.id !== id)
  }

  getStatus (id: number):Status {
    return FeedbackClasses.filter(s => s.id === id)[0] || FeedbackClasses[0]
  }

  filterOptions (input: string, option: { componentOptions: { children: any[] } }): boolean {
    const searchValue = input.toLowerCase()

    if (option.componentOptions.children.length > 0) {
      if (option.componentOptions.children.some(child => child.children[0].text.toLowerCase().indexOf(searchValue) >= 0)) {
        return true
      }
    }
    return false
  }

  feedbackList!: {
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  }

  // Get feedbacks from the api
  async getFeedbacks (): Promise<void> {
    this.loadingFeedbacks = true

    // filter all feedback states except for 'closed and rejected'
    const closedStates = [FeedbackStates.Afgerond, FeedbackStates.Afgewezen]
    const openFeedbackStates = Object.values(FeedbackStates).filter(s => !closedStates.includes(s))
    FeedbackModel.whereIn('status', openFeedbackStates).limit(999).$get().then((feedbacks) => {
      this.feedbacks = feedbacks as unknown as Feedback[]
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens het ophalen van feedbacks!',
        description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
        duration: 3
      })
    }).finally(() => {
      this.loadingFeedbacks = false
    })
  }

  save () : void {
    if (this.currentMode === PageModes.create) {
      this.store()
    } else {
      this.update()
    }
  }

  store () : void {
    this.saving = true
    const releaseModel = new ReleaseModel(this.release)
    releaseModel.save().then((release) => {
      this.release = release as unknown as Release
      notification.success({
        message: 'Release opgeslagen!',
        description: 'Release is succesvol opgeslagen.',
        duration: 3
      })
      this.$router.push({ name: 'releaseShow', params: { releaseId: this.release.id.toString() } })
      this.currentMode = PageModes.view
    }).catch((err) => {
      console.log(err)
      this.$message.error('Release kon niet worden opgeslagen. Check de console voor meer informatie')
    }).finally(() => {
      this.saving = false
    })
  }

  update () : void {
    this.saving = true
    const releaseModel = new ReleaseModel(this.release)
    releaseModel.patch().then(() => {
      notification.success({
        message: 'Release bijgewerkt! ',
        description: 'Release is succesvol opgeslagen.',
        duration: 3
      })
      this.$router.push({ name: 'releaseShow', params: { releaseId: this.release.id.toString() } })
      this.currentMode = PageModes.view
    }).catch((err) => {
      console.log(err)
      notification.error({
        message: 'Release niet opgeslagen!',
        description: 'Release kon niet worden opgeslagen. Check de console voor meer informatie',
        duration: 3
      })
    }).finally(() => {
      this.saving = false
    })
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  private getRelease () {
    this.loading = true
    if (this.latest) {
      this.getLatestRelease()
    } else {
      this.getReleaseById()
    }
    this.loading = false
  }

  private async getReleaseById () {
    await ReleaseModel.find(this.$route.params.releaseId).then((release) => {
      // Typecast to Release
      this.release = release as unknown as Release
      this.release = this.release.data
      this.activeLogSections = this.getAllLogSectionKeysWithContent()
    }).catch((err) => {
      console.log(err)
      notification.error({
        message: 'Fout tijdens het ophalen van de release!',
        description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
        duration: 3
      })
    })
  }

  private async getLatestRelease (): Promise<void> {
    await axios.get(`${process.env.VUE_APP_API_URL}/releases/latest`)
      .then((res) => {
        this.release = res.data.data
      }).catch((err) => {
        let description = 'Er is iets mis gegaan. Probeer het later nog een keer.'
        if (err.response.status === 404) {
          description = 'Release info niet gevonden.'
        }
        notification.error({
          message: 'Fout tijdens het ophalen van de release!',
          description: description,
          duration: 3
        })
      })
  }

  publish () : void {
    this.publishing = true
    axios.get(`${process.env.VUE_APP_API_URL}/releases/${this.release.id}/publish`)
      .then((res) => {
        this.release = res.data.data
        notification.success({
          message: 'Release gepubliceerd',
          description: 'Release is succesvol gepubliceerd.',
          duration: 3
        })
        this.$router.push({ name: 'releaseShow', params: { releaseId: this.release.id.toString() } })
        this.currentMode = PageModes.view
      }).catch((err) => {
        console.log(err)
        notification.error({
          message: 'Release niet gepubliceerd',
          description: 'Release kon niet worden gepubliceerd, check de console voor meer informatie.',
          duration: 3
        })
      }).finally(() => {
        this.publishing = false
      })
  }
}
