import User from '@/types/user'
import MediaItem from '@/types/mediaItem'
import { Status } from '@/types/serviceItem'

export interface FeedbackComment {
  id: number
  message: string
  user:string
  status: number
  created_at: string
  updated_at: string
}

export default class Feedback {
  [key: string]: any;
  public id!:number
  public parent_id!: number
  public parent: { id: number, title: string}|null = null
  public category_id!: number
  public category!: {
    id:number
    name:string
    slug:string
  }

  public labels: number[] = []
  public apps:string[] = []
  public tasks:Feedback[] = []
  public title!:string
  public description!:string
  public page_url!:string
  public when!:string|null
  public solution!:string
  public rank!:number
  public created_by_user_id!:number
  public created_by_user?:User
  public reported_by_user_id!:number
  public reported_by_user!:User
  public assigned_to_user_id = 0
  public assigned_to_user!:User|null
  public status!: number
  public media:MediaItem[] = []
  public files:File[] = []
  public comments:FeedbackComment[] =[]
  public created_at!:string
  public updated_at!:string
}

export const FeedbackStates = Object.freeze({
  Backlog: 0,
  Nieuw: 1,
  InBehandeling: 2,
  KlaarOmTeTesten: 3,
  MeerInformatieNodig: 4,
  Afgerond: 5,
  KlaarVoorStaging: 6,
  NietAkkoord: 7,
  Afgewezen: 8,
  Akkoord: 9
})

export const FeedbackClasses: Status[] = [
  {
    id: 0,
    name: 'Backlog',
    class: 'wo-grey-ol',
    description: 'Zeer lage prioriteit, wellicht meer info nodig'
  },
  {
    id: 1,
    name: 'Nieuw',
    class: 'wo-blue',
    description: 'Recent toegevoegd item'
  },
  {
    id: 2,
    name: 'In behandeling',
    class: 'wo-blue-ol',
    description: 'Er wordt momenteel actief gewerkt aan dit item'
  },
  {
    id: 3,
    name: 'Klaar om te testen',
    class: 'wo-light-green',
    description: 'Oplossing kan staat klaar om getest te worden'
  },
  {
    id: 4,
    name: 'Meer informatie nodig',
    class: 'wo-yellow',
    description: 'Item was in behandeling, maar er is meer informatie nodig.'
  },
  {
    id: 5,
    name: 'Afgerond',
    class: 'wo-dark-grey',
    description: 'Item is behandeld, getest en geïmplementeerd.'
  },
  {
    id: 6,
    name: 'Klaar voor staging',
    class: 'wo-light-green-ol',
    description: 'Item is behandeld en staat klaar om naar de test-omgeving te gaan'
  },
  {
    id: 7,
    name: 'Niet akkoord',
    class: 'wo-red',
    description: 'Oplossing is niet goed uit de test gekomen'
  },
  {
    id: 8,
    name: 'Afgewezen',
    class: 'wo-dark-grey',
    description: 'Het verzoek zal niet verder in behandeling worden genomen'
  },
  {
    id: 9,
    name: 'Akkoord',
    class: 'wo-green',
    description: 'Oplossing is geïmplementeerd, getest en goed bevonden'
  }
]

export const WoononApps : { label: string, value: string }[] = [
  {
    label: 'API (api.woonon.nl)',
    value: 'API'
  },
  {
    label: 'Adviseur (admin.woonon.nl)',
    value: 'BO'
  },
  {
    label: 'Kopersportaal (mijn.woonon.nl)',
    value: 'KP'
  },
  {
    label: 'Huurdersportaal (mijn.tuvast.nl)',
    value: 'HP'
  },
  {
    label: 'Bouwplaatsportaal (bouwplaats.woonon.nl)',
    value: 'BR'
  },
  {
    label: 'Projectwebsites',
    value: 'PW'
  },
  {
    label: 'Revit plugin',
    value: 'RP'
  }
]

export const SortedFeedbackStates = [...FeedbackClasses].sort((a, b) => a.name.localeCompare(b.name))

export const Labels: {id:number, text:string, class: string}[] = [
  { id: 1, text: 'Hotfix', class: 'wo-hotred' },
  { id: 2, text: 'Bug', class: 'wo-red' },
  { id: 3, text: 'Feature', class: 'wo-purple' },
  { id: 4, text: 'Project', class: 'wo-lime' },
  { id: 5, text: 'Small', class: 'wo-light-blue' },
  { id: 6, text: 'Medium', class: 'wo-blue' },
  { id: 7, text: 'Large', class: 'wo-dark-blue' },
  { id: 8, text: 'Task', class: 'wo-yellow' }
]
export const SortedLabels = [...Labels].sort((a, b) => a.text.localeCompare(b.text))
