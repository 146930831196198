









































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'
import Column from '@/types/column'
import GuardCode from '@/types/guardCode'
import { notification } from 'ant-design-vue'
import { units } from '@/globalValues'
import { LayoutBudget } from '@/types/layout'

@Component({
  components: {}
})

export default class ProjectEditLayoutBudgetsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Omschrijving',
      key: 'description',
      scopedSlots: { customRender: 'description' }
    },
    {
      title: 'Aantal',
      key: 'qty',
      dataIndex: 'qty',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Eenheid',
      key: 'unit',
      scopedSlots: { customRender: 'unit' }
    },
    {
      title: 'Eenheidsprijs',
      key: 'unit_price',
      scopedSlots: { customRender: 'unit_price' }
    },
    {
      title: 'Totaalprijs',
      key: 'total_price',
      scopedSlots: { customRender: 'total_price' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  searchTerm = ''
  showModal = false
  searchTimer = 0
  loading = { items: false, search: false, saving: false }

  projectLayouts = []
  guardCodes:GuardCode[] = []
  layoutBudget:{ id?:number, project_id:number, layout_id:number, guard_code_id:number|null, description:string|null, qty:number, unit:string, unit_price:number, total_price:number }|null = null

  mounted () : void {
    this.getProjectLayoutBudgets()
  }

  getProjectLayoutBudgets () : void {
    this.loading.items = true
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.$route.params.projectId + '/layoutBudgets').then((r) => {
      this.projectLayouts = r.data
    }).finally(() => {
      this.loading.items = false
    })
  }

  toggleModal (layoutBudget:LayoutBudget|null = null, layoutId:number|null = null) : void {
    this.showModal = !this.showModal

    if (this.showModal && layoutBudget) {
      this.searchGuardCodes(layoutBudget.guard_code.code)
      this.layoutBudget = {
        id: layoutBudget.id,
        project_id: parseInt(this.$route.params.projectId),
        layout_id: layoutBudget.layout_id,
        guard_code_id: layoutBudget.guard_code_id,
        description: layoutBudget.description,
        qty: layoutBudget.qty,
        unit: layoutBudget.unit,
        unit_price: layoutBudget.unit_price,
        total_price: layoutBudget.total_price
      }
    } else if (this.showModal && !layoutBudget && layoutId) {
      this.layoutBudget = {
        project_id: parseInt(this.$route.params.projectId),
        layout_id: layoutId,
        guard_code_id: null,
        description: null,
        qty: 1,
        unit: 'PST',
        unit_price: 0,
        total_price: 0
      }
    } else if (!this.showModal) {
      this.layoutBudget = null
      this.guardCodes = []
    }
  }

  searchGuardCodes (val:string) : void {
    if (val.length >= 3) {
      if (val.length >= 3) {
        clearTimeout(this.searchTimer)

        this.searchTimer = setTimeout(() => {
          this.loading.search = true
          axios.get(process.env.VUE_APP_API_URL + '/guardCodes?filter[search]=' + val).then((r) => {
            this.guardCodes = r.data.data
          }).finally(() => {
            this.loading.search = false
          })
        }, 500)
      }
    }
  }

  onGuardCodeChange (id:number) : void {
    if (this.layoutBudget) {
      this.layoutBudget.guard_code_id = id

      const defaultUnit = this.guardCodes.find((guardCode:GuardCode) => guardCode.id === id)
      this.layoutBudget.unit = defaultUnit && defaultUnit.default_unit ? defaultUnit.default_unit : 'PST'
    }
  }

  calculateTotal () : void {
    if (this.layoutBudget) {
      if (this.layoutBudget.unit === '%') {
        this.layoutBudget.total_price = (this.layoutBudget.qty / 100) * this.layoutBudget.unit_price
      } else {
        this.layoutBudget.total_price = this.layoutBudget.qty * this.layoutBudget.unit_price
      }
    }
  }

  addLayoutBudget () : void {
    this.loading.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/projectLayoutBudgets', this.layoutBudget).then(() => {
      this.toggleModal()
      this.$emit('budgetChange')
      notification.success({ message: 'Budgetregel toegevoegd.', description: 'De budgetregel is succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregel NIET toegevoegd.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    }).finally(() => {
      this.loading.saving = false
    })
  }

  updateLayoutBudget () : void {
    this.loading.saving = true
    if (this.layoutBudget && this.layoutBudget.id) {
      axios.patch(process.env.VUE_APP_API_URL + '/projectLayoutBudgets/' + this.layoutBudget.id, this.layoutBudget).then(() => {
        this.toggleModal()
        this.$emit('budgetChange')
        notification.success({ message: 'Budgetregel bijgewerkt.', description: 'De budgetregel is succesvol bijgewerkt.', duration: 3 })
      }).catch(() => {
        notification.error({ message: 'Budgetregel NIET bijgewerkt.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
      }).finally(() => {
        this.loading.saving = false
      })
    }
  }

  deleteBudgetFromLayout (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/projectLayoutBudgets/' + id).then(() => {
      this.$emit('budgetChange')
      notification.success({ message: 'Budgetregel verwijderd.', description: 'De budgetregel is succesvol verwijderd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregel NIET verwijderd.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    })
  }

  filterDropdown (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get layouts () : { id:number, external_ref:string, external_code:string, name:string }[] {
    if (this.searchTerm.length) {
      const st = this.searchTerm.toLowerCase()
      return this.projectLayouts.filter((pl:{ id:number, external_ref:string, external_code:string, name:string }) => {
        return pl.external_ref.toString().includes(st) ||
          pl.name.toString().toLowerCase().includes(st)
      })
    }
    return this.projectLayouts
  }

  get editMode () : boolean {
    return !!(this.layoutBudget && this.layoutBudget.id)
  }

  get units () : { name:string, value:string }[] {
    return units
  }
}
