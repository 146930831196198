





















































import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingBulkCreate extends Vue {
  loading = false
  saving = false
  projects = []

  showBulkModal = false
  bulkSettings:{ start:number, end:number|null, project_id:number|null } = { start: 1, end: null, project_id: null }

  buildings:{ reference:string, number:number, project_id:number }[] = []

  getProjects () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projects?sort=name&limit=500').then((r) => {
      this.projects = r.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  toggleBulkModal () : void {
    this.showBulkModal = !this.showBulkModal
    this.bulkSettings = { start: 1, end: null, project_id: null }
    this.buildings = []

    if (this.showBulkModal) {
      this.getProjects()
    }
  }

  generateBuildingArray () : void {
    if (this.bulkSettings.start && this.bulkSettings.end && this.bulkSettings.project_id) {
      this.buildings = []
      for (let i = this.bulkSettings.start; i <= this.bulkSettings.end; i++) {
        this.buildings.push({
          project_id: this.bulkSettings.project_id,
          reference: '',
          number: i
        })
      }
    }
  }

  addBuildings () : void {
    this.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/buildings/bulk/store', { buildings: this.buildings }).then(() => {
      this.$emit('reload')
      this.toggleBulkModal()
      notification.success({ message: 'Bouwnummers toegevoegd.', description: 'De bouwnummers zijn succesvol toegevoegd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Bouwnummers NIET toegevoegd.', description: 'Er is iets mis gegaan. Probeer het later nog eens.', duration: 3 })
    }).finally(() => {
      this.saving = false
    })
  }

  filterProjects (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get allBulkSettingsFilled () : boolean {
    return !!(this.bulkSettings.start && this.bulkSettings.end && this.bulkSettings.project_id)
  }

  get allRequiredFieldsFilled () : boolean {
    let missingValue = false
    this.buildings.forEach((building) => {
      if (!building.reference || !building.number || !building.project_id) {
        missingValue = true
      }
    })

    return this.allBulkSettingsFilled && this.buildings.length !== 0 && !missingValue
  }
}
