import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import ProjectModel from '@/models/ProjectModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
import SecureLS from 'secure-ls'
import axios from 'axios'
moment.locale('nl')

Vue.use(Vuex)
const storeName = 'projects'
const storage = new SecureLS({ isCompression: false })

export const projectStore: Module<any, any> = {
  state: {
    projectList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentProject: {
      name: null,
      reference: null,
      phase: 1,
      city: null,
      website: null,
      start_date: null,
      start_date_sale: null,
      start_date_construction: null,
      end_date: null,
      entity_id: null,
      entity: {
        name: null,
        reference: null
      },
      construction_type_ids: [],
      media: {
        logo: [],
        banner: [],
        verkoopdocumentatie: [],
        contract: [],
        gebruikershandleiding: [],
        revisiedocumenten: [],
        opleverdossier: [],
        kwaliteitdossier: [],
        servicemeldingen: [],
        overig: []
      }
    },
    InactiveProjectColumns: storage.get(storeName + '-columns') || [],
    loading: {
      list: false,
      item: false,
      save: false,
      syncing: false
    },
    errors: {}
  },
  mutations: {
    setCurrentProject (state, { currentProject }) {
      currentProject.start_date = currentProject.start_date ? moment(currentProject.start_date) : null
      currentProject.start_date_sale = currentProject.start_date_sale ? moment(currentProject.start_date_sale) : null
      currentProject.start_date_construction = currentProject.start_date_construction ? moment(currentProject.start_date_construction) : null
      currentProject.end_date = currentProject.end_date ? moment(currentProject.end_date) : null
      state.currentProject = currentProject
    },
    clearCurrentProject (state) {
      state.currentProject = {
        name: null,
        reference: null,
        phase: 1,
        city: null,
        website: null,
        start_date: null,
        start_date_sale: null,
        start_date_construction: null,
        end_date: null,
        entity_id: null,
        entity: {
          name: null,
          reference: null
        },
        construction_type_ids: [],
        media: {
          logo: [],
          banner: [],
          verkoopdocumentatie: [],
          contract: [],
          gebruikershandleiding: [],
          revisiedocumenten: [],
          opleverdossier: [],
          kwaliteitdossier: [],
          servicemeldingen: [],
          overig: []
        }
      }
    },
    setProjectList (state, { projectList }) {
      state.projectList = projectList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    enabledProjectOptionsChanged (state, { enabledOptions }) {
      state.currentProject.enabled_options = enabledOptions
    },
    setInactiveProjectColumns (state, columns) {
      state.InactiveProjectColumns = columns
    }
  },
  actions: {
    getProjectList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let projectModel = new ProjectModel()

      // Archived filter
      const phases = [1, 2, 3, 4, 5]
      projectModel = projectModel.whereIn('phase',
        Object.values(phases).filter(value => data.archived
          ? data.archiveConditions.phase.includes(value)
          : !data.archiveConditions.phase.includes(value)))

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            projectModel = projectModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        projectModel = projectModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        projectModel = projectModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        projectModel = projectModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      projectModel.get().then((projectList) => {
        commit('setProjectList', { projectList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van projecten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentProject ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        ProjectModel.$find(id).then((currentProject) => {
          commit('setCurrentProject', { currentProject })
          resolve(currentProject)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit project!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentProject ({ commit }) {
      commit('clearCurrentProject')
    },
    saveProject ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const projectModel = new ProjectModel(data)

      // Save project.
      projectModel.save().then((currentProject) => {
        commit('setErrors', {})
        commit('setCurrentProject', { currentProject })
        if (data.id) {
          notification.success({ message: 'Project opgeslagen.', description: 'Het project is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Project toegevoegd.', description: 'Het project is succesvol toegevoegd.', duration: 3 })
          if (currentProject.id) {
            router.push({ name: 'projectEdit', params: { projectId: currentProject.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit project!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit project!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    enabledProjectOptionsChanged ({ commit }, enabledOptions) {
      commit('enabledProjectOptionsChanged', { enabledOptions })
    },
    setInactiveProjectColumns ({ commit }, columns) {
      storage.set(storeName + '-columns', columns)
      commit('setInactiveProjectColumns', columns)
    },
    syncProject ({ commit }, projectId) {
      commit('setLoading', { type: 'syncing', state: true })
      axios.get(process.env.VUE_APP_API_URL + '/projects/' + projectId + '/sync').then((r) => {
        const currentProject = r.data.data
        commit('setCurrentProject', { currentProject })
        notification.success({
          message: 'Project gesynchroniseerd.',
          description: 'Het bouwnummer is succesvol gesynchroniseerd met AFAS.',
          duration: 3
        })
      }).catch(() => {
        notification.error({
          message: 'Fout tijdens het synchroniseren!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
          duration: 0
        })
      }).finally(() => {
        commit('setLoading', { type: 'syncing', state: false })
      })
    }
  },
  getters: {
    projectList: state => state.projectList,
    currentProject: state => state.currentProject,
    loadingProject: state => state.loading,
    projectErrors: state => state.errors,
    InactiveProjectColumns: state => state.InactiveProjectColumns
  }
}
