
































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import Customer from '@/types/customer'
import Building from '@/types/building'

@Component({
  components: {}
})

export default class CustomerEditConfigurationsTab extends Vue {
  settings = {
    routeNames: { list: 'customers', show: 'customerShow', edit: 'customerEdit' },
    routeParamKey: 'customerId',
    functionNames: { setCurrent: 'setCurrentCustomer', save: 'saveCustomer' },
    getterNames: { current: 'currentCustomer', loading: 'loadingCustomer' }
  }

  columns:Column[] = [
    {
      title: 'Project',
      key: 'project.name',
      dataIndex: 'project.name'
    },
    {
      title: 'Bouwnummer',
      key: 'building.number',
      dataIndex: 'building.number'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 140
    }
  ]

  loading = false
  buildingConfigs = []
  demoConfigs = []
  showDemoModal = false
  demo = { project_id: null, building_id: null }
  projectTimer = 0
  projects = []
  buildings = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getDemoConfigs()

      if (this.boughtBuildingIds.length) {
        this.getBuildingConfigs()
      }
    }
  }

  getBuildingConfigs () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildingConfigs?filter[building_id]=' + this.boughtBuildingIds.join(',')).then((res) => {
      this.buildingConfigs = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  getDemoConfigs () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/demoConfigs?filter[customer_id]=' + this.currentItem.id).then((res) => {
      this.demoConfigs = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  destroyDemoConfig (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/demoConfigs/' + id).then(() => {
      notification.success({ message: 'Configuratie verwijderd.', description: 'De configuratie is succesvol verwijderd.', duration: 3 })
      this.getDemoConfigs()
    }).catch(() => {
      notification.error({ message: 'Configuratie NIET verwijderd.', description: 'De configuratie is NIET verwijderd. Probeer het later nog eens.', duration: 3 })
    })
  }

  toggleDemoModal () : void {
    this.showDemoModal = !this.showDemoModal
    this.demo.project_id = null
    this.demo.building_id = null
    this.projects = []
    this.buildings = []
  }

  searchProject (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.projectTimer)

      this.projectTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/projects?filter[search]=' + val).then((res) => {
          this.projects = res.data.data
        })
      }, 500)
    }
  }

  getBuildings () : void {
    if (this.demo.project_id) {
      axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project.id]=' + this.demo.project_id + '&limit=9999').then((res) => {
        this.buildings = res.data.data
      })
    }
  }

  createDemo () : void {
    axios.post(process.env.VUE_APP_API_URL + '/customers/' + this.$route.params.customerId + '/demo', this.demo).then(() => {
      notification.success({ message: 'Demo aangemaakt.', description: 'De demo is succesvol aangemaakt.', duration: 3 })
      this.getDemoConfigs()
      this.toggleDemoModal()
    }).catch(() => {
      notification.error({ message: 'Demo NIET aangemaakt.', description: 'De demo is NIET aangemaakt. Probeer het later nog eens.', duration: 0 })
    })
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get currentItem () : Customer {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get boughtBuildingIds () : number[] {
    const boughtBuildingIds:number[] = []
    this.currentItem.buildings.forEach((b:Building) => {
      boughtBuildingIds.push(b.id)
    })
    return boughtBuildingIds
  }

  get demoBuildingIds () : number[] {
    const demoBuildingIds:number[] = []
    this.demoConfigs.forEach((bc:{ id:number, building_id:number, customer_id:number }) => {
      demoBuildingIds.push(bc.building_id)
    })
    return demoBuildingIds
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
