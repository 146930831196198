










































import { Vue, Component, Prop } from 'vue-property-decorator'
import TextEditor from '@/components/base/TextEditor.vue'
import ServiceItem from '@/types/serviceItem'

@Component({
  components: { TextEditor }
})

export default class ServiceDetails extends Vue {
  @Prop({ required: true }) currentItem!: ServiceItem
  @Prop({ required: false, type: Boolean }) editable!: boolean
  @Prop({ required: false, type: Boolean }) recommendedInfo!: boolean
  @Prop({ required: false, type: Boolean }) additionalInfo!: boolean

  config = {}
}
