export interface Tag {
  position: { x:number, y:number }
  survey_id?:number|null
  survey_answer_id?:number|null
  tagged_issue_id?:number|null
  tagged_media_id?:number|null
}

export default class MediaItem {
  public id!: number
  public name!: string
  public file_name!: string
  public size!: string
  public type!: string
  public url!: string
  public base64!: string|null
  public order!: number|null
  public tags!:Tag[]
  public created_at!: string
  public concept!: boolean
  public important_media!:{ id:number, media_id:number, customer_id:number, read_at:string|null, customer:{ id:number, name:string } }[]
  public important_media_details!:{ important:boolean, read_by:string[], unread_by:string[] }
}
