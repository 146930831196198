









































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import Filelist from '@/components/media/Filelist.vue'
import storeSettings from '@/types/storeSettings'
import NewsItem, { statusClasses } from '@/types/newsItem'
import TextEditor from '@/components/base/TextEditor.vue'
import BuildingSelector from '@/views/news/BuildingSelector.vue'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import SimpleFileList from '@/components/media/SimpleFileList.vue'
import { Base64MediaFile } from '@/types/media'
import moment from 'moment/moment'
import SimplePreview from '@/components/media/SimplePreview.vue'
import axios from 'axios'

@Component({
  components: {
    SimplePreview,
    BuildingSelector,
    Filelist,
    ActivityLogTable,
    TextEditor,
    SimpleUploader,
    SimpleFileList
  }
})

export default class NewsEdit extends Vue {
  @Prop({ default: true }) editable!:boolean
  @Prop({ default: false }) clear!:boolean

  moment = moment
  ls = new SecureLS({ isCompression: false })
  config = {}
  statusClasses = statusClasses

  settings: storeSettings = {
    routeNames: { list: 'news', show: 'newsShow', edit: 'newsEdit' },
    routeParamKey: 'newsItemId',
    permissionNames: { show: 'news.view', edit: 'news.edit' },
    functionNames: { getFilters: 'getNewsItemFilters', getList: 'getNewsItemList', setCurrent: 'setCurrentNewsItem', clearCurrent: 'clearCurrentNewsItem', save: 'saveNewsItem' },
    getterNames: { current: 'currentNewsItem', loading: 'newsItemLoading', errors: 'newsItemErrors', list: 'list', filters: 'newsItemFilters' }
  }

  allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
  maxFileSize = 16
  status = 0
  isPublished = false
  publishAt = moment('12:00:00', 'HH:mm:ss')

  mounted (): void {
    if (this.clear) {
      this.$store.dispatch(this.settings.functionNames.clearCurrent)
    }
    if (this.settings.routeParamKey in this.$route.params) {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    }
  }

  save () : void {
    this.currentItem.created_by = this.authUser.id
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get isEditable (): boolean {
    return !this.isPublished && this.editable
  }

  get pageTitle (): string {
    return this.settings.routeParamKey in this.$route.params ? `Bericht #${this.$route.params[this.settings.routeParamKey]}` : 'Nieuw bericht'
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  setBuildings (buildingsIds: number[]): void {
    this.$store.dispatch('setNewsBuildingIds', buildingsIds)
  }

  setProject (projectId: number): void {
    this.$store.dispatch('setNewsProjectId', projectId)
  }

  deleteMainImage (): void {
    if (this.currentItem.id !== undefined && this.currentItem.mainImage !== null) {
      axios.delete(process.env.VUE_APP_API_URL + '/media/' + this.currentItem.mainImage.id)
    }
    this.$store.dispatch('setNewsMainImage', null)
  }

  deleteExtraImage (index: number): void {
    if (this.currentItem.id !== undefined) {
      axios.delete(process.env.VUE_APP_API_URL + '/media/' + this.currentItem.extraImages[index].id)
    }
    this.$store.dispatch('removeExtraNewsImage', index)
  }

  deleteAllowed (): boolean {
    return this.authUser.permissions.includes('projects.view')
  }

  async handleMainImage (file: File, key: string): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await this.getBase64Async(file)
    await this.$store.dispatch('setNewsMainImage', previewFile)

    if (this.currentItem.id !== undefined) {
      const formData = new FormData()
      formData.append('position', key)
      formData.append('file', previewFile.url)
      formData.append('name', previewFile.name)

      await axios.post(process.env.VUE_APP_API_URL + '/news/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => this.$store.dispatch('setNewsMainImage', res.data))
    }
  }

  async handleExtraImages (file: File, key: string): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await this.getBase64Async(file)

    if (this.currentItem.id !== undefined) {
      const formData = new FormData()
      formData.append('position', key)
      formData.append('file', previewFile.url)
      formData.append('name', previewFile.name)

      await axios.post(process.env.VUE_APP_API_URL + '/news/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => this.$store.dispatch('setNewsExtraImages', [...this.currentItem.extraImages, ...[res.data]]))
    } else {
      await this.$store.dispatch('setNewsExtraImages', [...this.currentItem.extraImages, ...[previewFile]])
    }
  }

  getBase64Async (file: File):Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  disabledDate (current: any): boolean {
    return current < moment().startOf('day')
  }

  get isValid (): boolean {
    return this.currentItem.project_id !== 0 &&
      this.currentItem.buildings.length > 0 &&
      this.currentItem.title.length > 0 &&
      this.currentItem.body.length > 0
  }

  get currentItem () : NewsItem {
    const currentItem = this.$store.getters[this.settings.getterNames.current]
    this.isPublished = moment().diff(moment(currentItem.publish_at)) >= 0 && currentItem.status === 1
    return currentItem
  }

  get authUser () : User {
    return this.ls.get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
