


























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'
import Column from '@/types/column'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class ProjectEditDiariesTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  loading = false
  columns:Column[] = [
    {
      title: 'Week',
      key: 'period',
      scopedSlots: { customRender: 'period' }
    },
    {
      title: 'Status',
      key: 'status',
      scopedSlots: { customRender: 'status' },
      width: 120
    },
    {
      title: 'Actie\'s',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
      width: 160
    }
  ]

  diaries = []

  mounted () : void {
    this.getDiaries()
  }

  getDiaries () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/diaries?filter[project.id]=' + this.$route.params.projectId + '?limit=520').then((r) => {
      this.diaries = r.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  openPdf (url:string) : void {
    window.open(url, '_blank')
  }

  onRowClick (rowData:{ id:number }) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (keyboard.ctrlKey) {
            const route = this.$router.resolve({ name: 'diaryShow', params: { projectId: this.$route.params.projectId, diaryId: rowData.id.toString() } })
            window.open(route.href, '_blank')
          } else {
            this.$router.push({ name: 'diaryShow', params: { projectId: this.$route.params.projectId, diaryId: rowData.id.toString() } })
          }
        }
      }
    }
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
