



















































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Message from '@/types/message'
import axios from 'axios'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import UploadQueue from '@/components/media/UploadQueue.vue'
import Project from '@/types/project'
import Building from '@/types/building'
import moment from 'moment'
import 'moment/locale/nl'
import Filelist from '@/components/media/Filelist.vue'
import { notification } from 'ant-design-vue'
moment.locale('nl')

@Component({
  components: { Filelist, UploadQueue, Uploader, TextEditor }
})

export default class MessageShow extends Vue {
  settings = {
    routeNames: { list: 'messages', show: 'messageShow', edit: 'messageEdit' },
    routeParamKey: 'messageId',
    functionNames: { setCurrent: 'setCurrentMessage', clearCurrent: 'clearCurrentMessage', save: 'saveMessage' },
    getterNames: { current: 'currentMessage', loading: 'loadingMessage' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('messageEditActiveTab') || 'details'

  projects:Project[] = []
  buildings:Building[] = []

  newComment:{ body:string, files:File[] } = {
    body: '',
    files: []
  }

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  reloadMessage () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  updateCanRespond () : void {
    this.currentItem.can_respond = !this.currentItem.can_respond

    axios.patch(process.env.VUE_APP_API_URL + '/messages/' + this.currentItem.id, { can_respond: this.currentItem.can_respond }).then(() => {
      notification.success({ message: 'Mogelijkheid tot wel/niet reageren bijgewerkt.', description: 'De mogelijkheid tot wel/niet reageren is succesvol opgeslagen.', duration: 3 })
    })
  }

  newMessageFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/messages/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.reloadMessage()
    })
  }

  addComment () : void {
    const formData = new FormData()
    formData.append('body', this.newComment.body)
    this.newComment.files.forEach((file:File) => {
      formData.append('files[]', file)
    })

    axios.post(process.env.VUE_APP_API_URL + '/messages/' + this.$route.params[this.settings.routeParamKey] + '/comments', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.newComment = { body: '', files: [] }
      notification.success({ message: 'Reactie opgeslagen.', description: 'De reactie is succesvol opgeslagen.', duration: 3 })
      this.reloadMessage()
    })
  }

  newCommentFileUploaded (file:File) : void {
    this.newComment.files.push(file)
  }

  commentFileDeleted (index:number) : void {
    this.newComment.files.splice(index, 1)
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : Message {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
