import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import OldWoononOrderModel from '@/models/OldWoononOrderModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'

Vue.use(Vuex)

export const oldWoononOrderStore: Module<any, any> = {
  state: {
    oldWoononOrderList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentOldWoononOrder: {
      user: {
        full_name: null
      },
      project: {
        reference: null,
        name: null
      },
      building: {
        reference: null,
        number: null
      },
      prices: {
        building: 0,
        total: 0
      }
    },
    oldWoononOrderFilters: [],
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentOldWoononOrder (state, { currentOldWoononOrder }) {
      state.currentOldWoononOrder = currentOldWoononOrder
    },
    clearCurrentOldWoononOrder (state) {
      state.currentOldWoononOrder = {
        user: {
          full_name: null
        },
        project: {
          reference: null,
          name: null
        },
        building: {
          reference: null,
          number: null
        },
        prices: {
          building: 0,
          total: 0
        }
      }
    },
    setOldWoononOrderList (state, { oldWoononOrderList }) {
      state.oldWoononOrderList = oldWoononOrderList
    },
    setOldWoononOrderFilters (state, { filters }) {
      state.oldWoononOrderFilters = filters.data
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    }
  },
  actions: {
    getOldWoononOrderList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let oldWoononOrderModel = new OldWoononOrderModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            oldWoononOrderModel = oldWoononOrderModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        oldWoononOrderModel = oldWoononOrderModel.where('search', data.search)
      }

      // Add oldWoononOrderBy if sort is set.
      if (data.sort) {
        oldWoononOrderModel = oldWoononOrderModel.orderBy(data.sort.oldWoononOrder === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        oldWoononOrderModel = oldWoononOrderModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      oldWoononOrderModel.get().then((oldWoononOrderList) => {
        commit('setOldWoononOrderList', { oldWoononOrderList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de kopers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentOldWoononOrder ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        OldWoononOrderModel.$find(id).then((currentOldWoononOrder) => {
          commit('setCurrentOldWoononOrder', { currentOldWoononOrder })
          resolve(currentOldWoononOrder)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze order!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 0
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentOldWoononOrder ({ commit }) {
      commit('clearCurrentOldWoononOrder')
    },
    getOldWoononOrderFilters ({ commit }, data) {
      axios.post(process.env.VUE_APP_API_URL + '/oldWoononOrders/filters', data).then((filters) => {
        commit('setOldWoononOrderFilters', { filters })
      })
    }
  },
  getters: {
    oldWoononOrderList: state => state.oldWoononOrderList,
    currentOldWoononOrder: state => state.currentOldWoononOrder,
    loadingOldWoononOrder: state => state.loading,
    oldWoononOrderErrors: state => state.errors
  }
}
