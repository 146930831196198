











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})

export default class DeliveryDetailsRow extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) content!: string
}
