












































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import moment from 'moment'
import 'moment/locale/nl'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import BuildingEditDeliveryLetterTab from '@/views/building/tabs/edit/BuildingEditDeliveryLetterTab.vue'
moment.locale('nl')

@Component({
  components: { BuildingEditDeliveryLetterTab }
})

export default class BuildingEditDatesDeliveryTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  delivery:{ id:number, inspection_scheduled_at:moment.Moment|null, inspection_scheduled_user_id:number, inspection_completed_at:moment.Moment|null, inspection_status:number, delivery_scheduled_at:moment.Moment|null, delivery_scheduled_user_id:number, delivery_completed_at:moment.Moment|null, delivery_status:number, delivery_letter_sent_at:string|null }|null = null
  newDelivery:{ building_id:number, inspection_scheduled_at:moment.Moment|null, inspection_scheduled_user_id:number|null, delivery_scheduled_at:moment.Moment|null, delivery_scheduled_user_id:number|null } = {
    building_id: parseInt(this.$route.params[this.settings.routeParamKey]),
    inspection_scheduled_at: null,
    inspection_scheduled_user_id: null,
    delivery_scheduled_at: null,
    delivery_scheduled_user_id: null
  }

  employees:{ id:number, full_name:string }[] = []
  savingDelivery = false
  deletingDelivery = false

  mounted () : void {
    this.delivery = this.currentItem.delivery
    this.getEmployees()
  }

  getEmployees () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.display_name]=Medewerker&limit=2500').then((res) => {
      this.employees = res.data.data
    })
  }

  createDelivery () : void {
    this.savingDelivery = true
    axios.post(process.env.VUE_APP_API_URL + '/deliveries', this.newDelivery).then((res) => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
      notification.success({ message: 'Oplevering ingepland.', description: 'De oplevering is succesvol ingepland.', duration: 5 })
    }).catch(() => {
      notification.error({ message: 'Fout tijdens inplannen!', description: 'Er is iets mis gegaan. Probeer het later nog eens.', duration: 0 })
    }).finally(() => {
      this.savingDelivery = false
    })
  }

  updateDelivery () : void {
    if (this.delivery) {
      this.savingDelivery = true
      axios.patch(process.env.VUE_APP_API_URL + '/deliveries/' + this.delivery.id, this.delivery).then((res) => {
        this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
        notification.success({ message: 'Oplevering bijgewerkt.', description: 'De oplevering is succesvol bijgewerkt.', duration: 5 })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens bijwerken!', description: 'Er is iets mis gegaan. Probeer het later nog eens.', duration: 0 })
      }).finally(() => {
        this.savingDelivery = false
      })
    }
  }

  deleteDelivery () : void {
    if (this.delivery) {
      this.deletingDelivery = true
      axios.delete(process.env.VUE_APP_API_URL + '/deliveries/' + this.delivery.id).then((res) => {
        this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
        notification.success({ message: 'Oplevering verwijderd.', description: 'De oplevering is succesvol verwijderd.', duration: 5 })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens verwijderen!', description: 'Er is iets mis gegaan. Probeer het later nog eens.', duration: 0 })
      }).finally(() => {
        this.deletingDelivery = false
      })
    }
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  filterDropdown (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get inspectionCompleted () : boolean {
    if (this.delivery) {
      return this.delivery.inspection_completed_at !== null
    }

    return false
  }

  get deliveryCompleted () : boolean {
    if (this.delivery) {
      return this.delivery.delivery_completed_at !== null
    }

    return false
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
