











import { Component, Prop, Vue } from 'vue-property-decorator'
import { DeliveryStateLabelColors, DeliveryStateLabels } from '@/globalValues'

@Component({
})

export default class DeliveryStatusRow extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) status!: number

  get DeliveryStateLabelColors () : any {
    return DeliveryStateLabelColors
  }

  get DeliveryStateLabels () : any {
    return DeliveryStateLabels
  }
}
