import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import DeliveryModel from '@/models/DeliveryModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'

Vue.use(Vuex)

export const deliveryStore: Module<any, any> = {
  state: {
    deliveryList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentDelivery: {
      building: {
        number: null,
        project: {
          name: null
        },
        customer: {
          contractors: []
        }
      },
      media: { pvo: null, attachment: null }
    },
    loading: {
      list: false,
      item: false
    },
    errors: {}
  },
  mutations: {
    setCurrentDelivery (state, { currentDelivery }) {
      currentDelivery.inspection_scheduled_at = currentDelivery.inspection_scheduled_at ? moment(currentDelivery.inspection_scheduled_at) : null
      currentDelivery.delivery_scheduled_at = currentDelivery.delivery_scheduled_at ? moment(currentDelivery.delivery_scheduled_at) : null
      state.currentDelivery = currentDelivery
    },
    clearCurrentDelivery (state) {
      state.currentDelivery = {
        building: {
          number: null,
          project: {
            name: null
          },
          customer: {
            contractors: []
          }
        },
        media: { pvo: null, attachment: null }
      }
    },
    setDeliveryList (state, { deliveryList }) {
      state.deliveryList = deliveryList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getDeliveryList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let deliveryModel = new DeliveryModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            deliveryModel = deliveryModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        deliveryModel = deliveryModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        deliveryModel = deliveryModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        deliveryModel = deliveryModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      deliveryModel.get().then((deliveryList) => {
        commit('setDeliveryList', { deliveryList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van voorschouwen/opleveringen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentDelivery ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        DeliveryModel.$find(id).then((currentDelivery) => {
          commit('setCurrentDelivery', { currentDelivery })
          resolve(currentDelivery)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze voorschouw/oplevering!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentDelivery ({ commit }) {
      commit('clearCurrentDelivery')
    }
  },
  getters: {
    deliveryList: state => state.deliveryList,
    currentDelivery: state => state.currentDelivery,
    loadingDelivery: state => state.loading,
    deliveryErrors: state => state.errors
  }
}
