












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EditableCell extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: Boolean }) showEditIcon!: boolean
  @Prop({ default: Boolean }) continuesUpdates!: boolean
  @Prop({ default: false }) editable!: boolean
  @Prop({ default: false, type: Boolean }) editOnClick!: boolean
  newValue = ''

  get isDirty (): boolean {
    console.log(this.value, this.newValue)
    return this.value !== this.newValue
  }

  handleChange (e: any) : void {
    if (!this.continuesUpdates) return
    const value = e.target.value
    this.$emit('change', value)
  }

  check (e: any): void{
    this.editable = false
    this.newValue = e.target.value
    if (this.isDirty) {
      this.$emit('change', this.newValue)
    }
  }

  edit (): void {
    if (!this.editOnClick) return
    this.editable = true
    this.newValue = this.value
  }
}
