



































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Buildingblock from '@/types/buildingblock'
import Column from '@/types/column'
import axios from 'axios'
import ProjectBlockDrawingsTab from '@/views/project/tabs/ProjectBlockDrawingsTab.vue'

@Component({
  components: { ProjectBlockDrawingsTab }
})

export default class ProjectEditBlocksTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Bloknummer',
      key: 'number',
      dataIndex: 'number',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Bouwnummer(s)',
      key: 'buildings',
      scopedSlots: { customRender: 'buildings' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loadingBlocks = false
  buildingBlocks:Buildingblock[] = []
  showCreateModal = false
  showEditModal = false
  buildingBlock:{ id:number|null, name:string|null, number:number|null, project_id:number|null, building_ids:number[] } = {
    id: null,
    name: null,
    number: null,
    project_id: null,
    building_ids: []
  }

  buildings = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingBlocks()
    }
  }

  get numberTaken () : boolean {
    return this.buildingBlocks.filter(b => b.number === this.buildingBlock.number).length > 0
  }

  get canSaveBlock () : boolean {
    return this.buildingBlock.name !== null && this.buildingBlock.number !== null && !this.numberTaken
  }

  get defaultBlockNumber () : number {
    return this.buildingBlocks.length + 1
  }

  getBuildingBlocks () : void {
    this.loadingBlocks = true

    axios.get(process.env.VUE_APP_API_URL + '/buildingblocks?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.buildingBlocks = res.data.data
      this.getBuildings()
    }).finally(() => {
      this.loadingBlocks = false
    })
  }

  createBlock () : void {
    this.showCreateModal = true
    this.buildingBlock = {
      id: null,
      name: null,
      number: this.defaultBlockNumber,
      project_id: this.currentItem.id,
      building_ids: []
    }
  }

  storeBlock () : void {
    axios.post(process.env.VUE_APP_API_URL + '/buildingblocks', this.buildingBlock).then(() => {
      this.getBuildingBlocks()
      this.hideModal()
    })
  }

  editBlock (item:Buildingblock) : void {
    this.showEditModal = true
    this.buildingBlock = {
      id: item.id,
      name: item.name,
      number: item.number,
      project_id: item.project_id,
      building_ids: item.building_ids
    }
  }

  updateBlock () : void {
    axios.patch(process.env.VUE_APP_API_URL + '/buildingblocks/' + this.buildingBlock.id, this.buildingBlock).then(() => {
      this.getBuildingBlocks()
      this.hideModal()
    })
  }

  deleteBlock (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/buildingblocks/' + id).then(() => {
      this.getBuildingBlocks()
    })
  }

  hideModal () : void {
    this.showCreateModal = false
    this.showEditModal = false
    this.buildingBlock = {
      id: null,
      name: null,
      number: null,
      project_id: null,
      building_ids: []
    }
  }

  getBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project.id]=' + this.$route.params.projectId + '&limit=1000').then((r) => {
      this.buildings = r.data.data
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
