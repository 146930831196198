


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FilterOption } from '@/types/column'
import _ from 'lodash'
import TableSettings from '@/types/tableSettings'

@Component({
  components: {}
})

export default class SearchableColumnFilter extends Vue {
  @Prop() tableSettings!: TableSettings
  @Prop() values!: FilterOption[]
  @Prop() columnKey!: string
  @Prop() filteredValue!: string|number|null
  @Prop() callback!: () => void

  @Watch('filteredValue')
  ClearValues (): void {
    if (this.filteredValue === null) {
      this.clearSelected()
    }
  }

  searchValue = ''
  selectedValues: number[] = []
  checkedValues: number[] = []

  get entries ():FilterOption[] {
    return this.values.filter(v => v.text.toString().toLowerCase().includes(this.searchValue)).map(f => { return { text: f.text, value: f.value } })
  }

  itemValueAsNumber (value: string|number|boolean): number {
    return Number(value)
  }

  checkedValue (id: number | boolean | string): void {
    if (this.checkedValues.includes(id as number)) {
      this.checkedValues.splice(this.checkedValues.indexOf(id as number), 1)
    } else {
      this.checkedValues.push(id as number)
    }

    this.selectedValues = _.cloneDeep(this.checkedValues)
  }

  parseSearchValue (value: string): void {
    this.searchValue = value.toLowerCase()
  }

  clearSearch (): void {
    this.searchValue = ''
  }

  clearSelected (): void {
    this.selectedValues = this.checkedValues = []
  }

  updateCustomFilter (column: string, values: number[]|string[], closeDropdownCallback: () => void): void {
    const filters = _.cloneDeep(this.tableSettings.activeFilters)
    filters[column] = values
    this.tableSettings.activeFilters = filters
    this.clearSearch()
    this.$emit('refresh')
    closeDropdownCallback()
  }

  resetFilters (closeDropdownCallback: () => void): void {
    this.clearSelected()
    this.$emit('refresh')
    closeDropdownCallback()
  }
}
