



























































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import Column from '@/types/column'
import axios from 'axios'

@Component({
  components: {}
})

export default class CompanyEditEmployeesTab extends Vue {
  settings = {
    routeNames: { list: 'companies', show: 'companyShow', edit: 'companyEdit' },
    routeParamKey: 'companyId',
    functionNames: { setCurrent: 'setCurrentCompany', save: 'saveCompany' },
    getterNames: { current: 'currentCompany', loading: 'loadingCompany', errors: 'projectErrors' }
  }

  columns:Column[] = [
    {
      title: 'Naam',
      key: 'full_name',
      scopedSlots: { customRender: 'full_name' }
    },
    {
      title: 'Geb. datum',
      key: 'date_of_birth',
      scopedSlots: { customRender: 'date_of_birth' }
    },
    {
      title: 'Adres',
      key: 'address',
      scopedSlots: { customRender: 'address' }
    },
    {
      title: 'Actief',
      key: 'active',
      scopedSlots: { customRender: 'active' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { constructionWorkerId: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('construction_workers.edit')) {
            const route = this.$router.resolve({ name: 'constructionWorkerEdit', params: { constructionWorkerId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('construction_workers.view')) {
            const route = this.$router.resolve({ name: 'constructionWorkerEdit', params: { constructionWorkerId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  allowConstructionWorker (id:number) : void {
    axios.post(process.env.VUE_APP_API_URL + '/constructionWorkers/' + id + '/allow').then(() => {
      this.$store.dispatch('setCurrentCompany', this.$route.params.companyId)
    })
  }

  get currentItem () : Company {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
