




















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import BuildingEditDetailsTab from '@/views/building/tabs/edit/BuildingEditDetailsTab.vue'
import Project from '@/types/project'
import axios from 'axios'
import PageHeader from '@/components/base/PageHeader.vue'

@Component({
  components: { PageHeader, BuildingEditDetailsTab }
})

export default class BuildingCreate extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', clearCurrent: 'clearCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding', errors: 'buildingErrors' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('buildingCreateActiveTab') || 'details'
  projects:Project[] = []

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
    this.getProjects()
  }

  getProjects () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projects?filter[phase]=1,2,3,4&sort=name&limit=500').then((res) => {
      this.projects = res.data.data
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('buildingCreateActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  buildingNumberChanged () : void {
    if (this.project && this.project.in_afas) {
      const leadingZeros = this.currentItem.number < 10 ? '00' : (this.currentItem.number < 100 ? '0' : null)

      if (leadingZeros !== null) {
        this.currentItem.reference = this.project.reference + '-' + leadingZeros + this.currentItem.number
      } else {
        this.currentItem.reference = this.project.reference + '-' + this.currentItem.number
      }
    }
  }

  get project () : Project|null {
    let project = null
    if (this.projects.length && this.currentItem.project_id) {
      this.projects.forEach((p:Project) => {
        if (p.id === this.currentItem.project_id) {
          project = p
        }
      })
    }

    return project
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
