



































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import Company from '@/types/company'

@Component({
  components: {}
})

export default class IssueBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]

  saving = false

  bulkUpdate:Record<string, (string|number|null)> = {
    status: null
  }

  update () : void {
    this.saving = true
    const data:Record<string, (string|number|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null) {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/questions/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        notification.success({ message: 'Vragen bijgewerkt.', description: 'De vragen zijn succesvol opgeslagen.', duration: 3 })
        this.bulkUpdate = {
          status: null
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
