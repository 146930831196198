




import { Component, Vue } from 'vue-property-decorator'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import UserSetting from '@/types/userSetting'
import SecureLS from 'secure-ls'

@Component({
  components: {}
})

export default class O365LoginSucceeded extends Vue {
  loading = false

  created () : void {
    const token = this.$route.query.token as string
    this.loading = true

    this.$store.dispatch('getDetailsForO365User', { token }).then(() => {
      this.getUserSettings()
      this.$router.push({ name: 'dashboard' })
    }).catch(() => {
      notification.error({ message: 'Fout tijdens inloggen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.' })
      this.$router.push({ name: 'login' })
    }).finally(() => {
      this.loading = false
    })
  }

  getUserSettings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users/admin/settings').then((res) => {
      if (res.data.length) {
        res.data.forEach((setting:UserSetting) => {
          new SecureLS({ isCompression: false }).set(setting.setting_name, setting.setting_value)
        })
      }
    })
  }
}
